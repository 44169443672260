import { gql, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DocumentMaster, DocumentMasterVersionEnum, DocumentTypeEnum, FinancialYears } from 'types';

import Section from 'components/Section';
import GroupedSection from 'components/Section/GroupedSection';

import DocumentForm from './Forms/DocumentForm';

const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocumentMaster($input: [CreateDocumentMasterInput!]!) {
    createDocumentMaster(input: $input) {
      _id
      referenceId
      type
      name
      version
      comments
      financialYear
      path
    }
  }
`;

type CreateDocumentMasterMutationResponse = {
  createDocumentMaster: DocumentMaster[];
};

type CreateDocumentMasterMutationVariables = {
  input: Omit<DocumentMasterFormProps, '_id'>[];
};

export type DocumentMasterFormProps = {
  _id: string;
  name: string;
  type: DocumentTypeEnum;
  file: File | DocumentMaster;
  version?: DocumentMasterVersionEnum;
  comments?: string;
  financialYear?: FinancialYears;
};

const CreateDocumentFormTemplate: React.FC<{
  cb: (arg: DocumentMaster[]) => void;
  initialData?: DocumentMasterFormProps;
}> = ({ cb, initialData }) => {
  const [showAddNewButton, toggleAddNewButton] = useState<boolean>(false);
  const [formState, setFormState] = useState<DocumentMasterFormProps[]>(
    !!initialData ? [initialData] : []
  );
  const [activeSection, setActiveSection] = useState<string | number>('');
  const [readOnlyView, setReadOnlyView] = useState<Record<string, boolean>>(
    !!initialData?._id ? { [initialData._id]: true } : {}
  );

  const [createDocuments, { loading: creatingDocuments }] = useMutation<
    CreateDocumentMasterMutationResponse,
    CreateDocumentMasterMutationVariables
  >(CREATE_DOCUMENT_MUTATION);

  const emptyFormData: DocumentMasterFormProps = {
    _id: 'create-form',
    name: '',
    // @ts-ignore
    type: '',
  };

  const shouldDisableSubmit = useMemo(
    () => Object.keys(readOnlyView).some(k => !!!readOnlyView[k]),
    [readOnlyView]
  );

  const handleChange = (data: DocumentMasterFormProps) => {
    setFormState(prev => prev.map(d => (d._id === data._id ? data : d)));
    toggleLineItemEdit(data._id);
  };

  const appendLineItem = (data: DocumentMasterFormProps) => {
    data._id = Date.now().toString();

    setFormState(prev => {
      prev.push(data);
      return prev;
    });
    setReadOnlyView(prev => ({
      ...prev,
      [data._id]: true,
    }));
    toggleAddNewButton(true);
    setActiveSection(data._id);
  };

  const toggleLineItemEdit = (id: string) => {
    setReadOnlyView(prev => ({
      ...prev,
      [id]: !!!prev[id],
    }));
  };

  const deleteLineItem = (id: string) => {
    setFormState(prev => prev.filter(({ _id }) => _id !== id));
    setReadOnlyView(prev => {
      const { [id]: _, ...newRes } = prev;
      return newRes;
    });
  };

  const handleCreateDocuments = () => {
    const refinedData = formState.map(item => {
      const { _id: _, ...rest } = item;
      return rest;
    });

    createDocuments({
      variables: {
        input: refinedData,
      },
      onCompleted: res => {
        cb(res.createDocumentMaster);
      },
    });
  };

  if (!!!formState.length && !!showAddNewButton) {
    toggleAddNewButton(false);
  }

  const sectionActions = (itemId: string) => {
    if (!!!readOnlyView[itemId])
      return [
        {
          title: 'Delete',
          disabled: false,
          onClick: () => deleteLineItem(itemId),
        },
      ];

    return [
      {
        title: 'Edit',
        disabled: false,
        onClick: () => toggleLineItemEdit(itemId),
      },
      {
        title: 'Delete',
        disabled: false,
        onClick: () => deleteLineItem(itemId),
      },
    ];
  };

  return (
    <Grid container direction="column" rowGap={2} item mb={2}>
      {formState.map(item => (
        <GroupedSection
          title={item.name + (!!item.version ? ` ${item.version}` : '')}
          activeKey={activeSection}
          currKey={item._id}
          setActiveKey={setActiveSection}
          key={item._id}
          collapsible
          action={sectionActions(item._id)}
        >
          <DocumentForm data={item} cb={handleChange} disabled={!!readOnlyView[item._id]} />
        </GroupedSection>
      ))}
      {showAddNewButton ? (
        <Button
          variant="outlined"
          onClick={() => toggleAddNewButton(false)}
          size="small"
          startIcon={<AddIcon fontSize="small" />}
        >
          Add New
        </Button>
      ) : (
        <Section
          title="New Document"
          action={
            <ClearIcon
              onClick={() => !!formState.length && toggleAddNewButton(true)}
              sx={{ cursor: 'pointer', color: !!!formState.length ? 'lightgray' : undefined }}
            />
          }
        >
          <DocumentForm data={emptyFormData} cb={appendLineItem} />
        </Section>
      )}
      <Grid container item xs={12}>
        <LoadingButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loading={creatingDocuments}
          disabled={!!!formState.length || shouldDisableSubmit}
          onClick={handleCreateDocuments}
        >
          Create
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default CreateDocumentFormTemplate;
