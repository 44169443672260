import { gql } from '@apollo/client';

export const PINCODES_QUERY = gql`
  query GetPincodes($filter: PincodeFilter) {
    getPincodes(filter: $filter) {
      stateName
      district
    }
  }
`;

export type PincodesQueryResponse = {
  getPincodes: {
    stateName: string;
    district: string;
  }[];
};

export type PincodesQueryVariables = {
  filter: {
    pincode: string;
  };
};
