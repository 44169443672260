import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import React, { useRef, useState } from 'react';

import debounce from 'utils/debounce';

import theme from './theme.module.scss';

type SearchBarProps = {
  name: string;
  label?: string;
  size?: 'small' | 'medium';
  placeholder?: string;
  onSearchInputChange: (arg: any) => any;
} & React.HTMLAttributes<HTMLDivElement>;

const debouncedOnSearchInputChange = debounce((newInput: string, callback: (arg: any) => any) => {
  callback(newInput);
}, 500);

/**
 * for refetching purposes use "onSearchInputChange".
 * it uses debounce function by itself so no need to apply it again.
 * provides validators as well
 */
const SearchBar: React.FC<SearchBarProps> = ({
  className,
  onSearchInputChange,
  name = 'searchId',
  label = 'Search',
  size = 'small',
  placeholder = 'Search..',
  ...props
}) => {
  const [inputVal, setInputVal] = useState('');
  const ref = useRef<any>(null);

  // trims white spaces and checks if min len is 3
  const searchStringRegex = /^\s*[a-zA-Z0-9-](?:\s*[a-zA-Z0-9-]){2,}\s*$/;
  const onQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newInput = e.target.value;
    setInputVal(newInput);

    if (searchStringRegex.test(newInput) || newInput === '') {
      debouncedOnSearchInputChange(newInput, onSearchInputChange);
    }
  };

  return (
    <Grid container position="relative" direction="column">
      <input
        className={theme.searchInput}
        placeholder={placeholder}
        value={inputVal}
        onChange={onQueryChange}
        ref={ref}
        {...props}
      />
      <SearchIcon
        fontSize="small"
        onClick={() => ref.current?.focus()}
        sx={{
          position: 'absolute',
          top: 14,
          left: 8,
          color: theme => theme.palette.secondary.main,
          cursor: 'pointer',
        }}
      />
    </Grid>
  );
};

export default SearchBar;
