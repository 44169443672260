import { NetworkStatus, useQuery } from '@apollo/client';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Grid, Typography } from '@mui/material';
import { GET_PEOPLES_QUERY, PeopleQueryResponse, PeopleQueryVariables } from 'graphql/query/people';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Branch, BranchStatusEnum, People } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import ClientEmployeeCard from './Card';
import PeopleForm from './PeopleForm';

type PeopleSectionProps = {
  branch: Branch;
};

const PeopleSection: React.FC<PeopleSectionProps> = ({ branch }) => {
  const [enableAddNewForm, toggleAddNewForm] = useState(false);
  const [enableEditForm, toggleEditForm] = useState(false);

  const [selectedContact, setSelectedContact] = useState<People | undefined>();

  const { companyId = '' } = useParams<{ branchId: string; companyId: string }>();

  const { data: peoples, networkStatus } = useQuery<PeopleQueryResponse, PeopleQueryVariables>(
    GET_PEOPLES_QUERY,
    {
      variables: {
        filter: {
          branch: branch._id,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const isBranchActive = branch.status === BranchStatusEnum.ACTIVE;

  const loadingPeoples =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!peoples;

  return (
    <>
      <Section
        title="Client Employee"
        collapsible
        action={
          <PersonAddIcon
            fontSize="small"
            sx={
              !isBranchActive
                ? {
                    pointerEvents: 'none',
                    color: 'gray',
                  }
                : { cursor: 'pointer' }
            }
            onClick={() => toggleAddNewForm(true)}
          />
        }
      >
        {loadingPeoples ? (
          <Grid>
            <LoadingIndicator size="1.6rem" />
          </Grid>
        ) : (
          <Grid
            container
            columns={2}
            rowSpacing={2}
            columnSpacing={3}
            alignItems="center"
            minHeight="10vh"
            pb={2}
            pr={1}
            overflow="auto"
            mt={-1}
          >
            {peoples.getPeople.map(pp => (
              <Grid item key={pp.referenceId} xs={2} md={1} lg={1}>
                <ClientEmployeeCard
                  people={pp}
                  action={
                    <EditNoteIcon
                      onClick={() => {
                        toggleEditForm(true);
                        setSelectedContact(pp);
                      }}
                      sx={{ cursor: 'pointer' }}
                    />
                  }
                />
              </Grid>
            ))}
            {peoples.getPeople.length === 0 && (
              <Grid item my="auto" mt={2} container justifyContent="center">
                <Typography variant="subtitle2">No Contacts has been added yet.</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Section>
      <SimplePopup
        onClose={() => toggleAddNewForm(false)}
        open={enableAddNewForm}
        title="Add Contact"
        fullWidth
      >
        <PeopleForm
          cb={() => toggleAddNewForm(false)}
          branchId={branch._id}
          companyId={companyId}
        />
      </SimplePopup>

      <SimplePopup
        onClose={() => {
          toggleEditForm(false);
          setSelectedContact(undefined);
        }}
        open={enableEditForm}
        title="Update Contact"
        fullWidth
      >
        <PeopleForm
          people={selectedContact as People}
          cb={() => toggleEditForm(false)}
          branchId={branch._id}
          companyId={companyId}
        />
      </SimplePopup>
    </>
  );
};

export default PeopleSection;
