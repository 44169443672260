import { Chip, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { PerformanceDashboardType } from 'types';

import KPICard from 'components/Dashboard/Cards/KpiCard';

import theme from './theme.module.scss';

const LeadWonAndLossPerformanceTemplate: React.FC<{ dashboard: PerformanceDashboardType }> = ({
  dashboard,
}) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <div className={theme.wonAndLossContainer}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowGap={2}
        columnSpacing={2}
        mx="auto"
      >
        <Grid item xs={12} mt={2}>
          <Divider variant="middle">
            <Typography variant="button" fontWeight={700}>
              <Chip label="WON / LOST / REGRET" size="small" variant="outlined" color="secondary" />
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={isMobileScreen ? 6 : 4}>
          <KPICard
            amount={dashboard.wonLeads.totalApproxValue}
            leadsCount={dashboard.wonLeads.leadCount}
            label="Won"
            onClick={() => {}}
          />
        </Grid>
        <Grid item xs={isMobileScreen ? 6 : 4}>
          <KPICard
            amount={dashboard.lostLeads.totalApproxValue}
            leadsCount={dashboard.lostLeads.leadCount}
            label="Lost"
            onClick={() => {}}
          />
        </Grid>
        <Grid item xs={isMobileScreen ? 7 : 4}>
          <KPICard
            amount={dashboard.regretLeads.totalApproxValue}
            leadsCount={dashboard.regretLeads.leadCount}
            label="REGRET"
            onClick={() => {}}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadWonAndLossPerformanceTemplate;
