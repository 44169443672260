import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React from 'react';
import { Company, CompanyStatus, CompanyType } from 'types';

import { FormInput, FormPanel, getUpdatedFields } from 'components/FormPanel';
import { SimplePopup } from 'components/Popup';

const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      _id
      referenceId
      status
      type
      name
    }
  }
`;

type UpdateCompanyMutationResponse = {
  updateCompany: Company;
};
type UpdateCompanyMutationVariables = {
  input: {
    _id: string;
    name?: string;
    status?: CompanyStatus;
    type?: CompanyType;
  };
};

type UpdateCompanyFormProps = {
  company: Company;
  openForm: boolean;
  toggleForm: (arg: boolean) => void;
};

const UpdateCompanyForm: React.FC<UpdateCompanyFormProps> = ({ company, openForm, toggleForm }) => {
  const [updateCompany, { loading: updatingCompany }] = useMutation<
    UpdateCompanyMutationResponse,
    UpdateCompanyMutationVariables
  >(UPDATE_COMPANY_MUTATION);

  const handleSubmit = (data: any) => {
    const refinedData = getUpdatedFields(company, data);

    updateCompany({
      variables: {
        input: {
          _id: company._id,
          ...(refinedData ?? {}),
        },
      },
      onCompleted: _ => toggleForm(false),
    });
  };

  return (
    <SimplePopup onClose={() => toggleForm(false)} open={openForm} title="Update Company" fullWidth>
      <Grid container direction="column" rowGap={2} px={2} py={2}>
        <FormPanel
          error={null}
          loading={updatingCompany}
          onSubmit={handleSubmit}
          submitButtonLabel="Update"
        >
          <FormInput
            fieldName="name"
            defaultValue={company.name}
            label="Company Name"
            type="string"
            validators={{
              required: true,
            }}
          />
          <FormInput
            type="select"
            fieldName="status"
            label="Status"
            options={Object.values(CompanyStatus).map(o => ({ label: o, value: o }))}
            defaultValue={company.status}
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      </Grid>
    </SimplePopup>
  );
};

export default UpdateCompanyForm;
