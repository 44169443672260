import PlaceIcon from '@mui/icons-material/Place';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { Branch } from 'types';

import Card from 'components/Card';

type BranchCardProps = {
  headers: [string, string];
  onClick: (arg: any) => void;
  branch: Branch;
  sx?: SxProps<Theme>;
};

const BranchCard: React.FC<BranchCardProps> = ({ headers, onClick, branch, sx = {} }) => {
  const address = branch.address.city + ', ' + branch.address.state + ', ' + branch.address.country;

  return (
    <Card sx={sx} headers={headers} onClick={onClick}>
      <Grid container direction="column" rowGap={0.5}>
        <Grid item>
          <PlaceIcon sx={{ fontSize: '16px', mr: 0.3, mb: -0.2 }} color="error" />
          <Typography variant="caption" fontWeight={500}>
            {branch.address.addressLocation}
          </Typography>
        </Grid>
        <Typography variant="caption" fontWeight={500}>
          {address}
        </Typography>
      </Grid>
    </Card>
  );
};

export default BranchCard;
