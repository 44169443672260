import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import {
  CREATE_STATIC_SUB_FOLDER_MUTATION,
  CreateStaticSubFolderMutationResponse,
  CreateStaticSubFolderMutationVariables,
} from 'graphql/mutation/staticsubfolder';
import {
  GET_STATIC_FOLDERS_QUERY_FOR_LV,
  GetStaticFoldersQueryResponse,
  GetStaticFoldersQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Folder, FolderTypeEnum } from 'types';

import { DocumentFoldersGrid } from 'components/DocumentMaster/DocumentFolders';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

import { updateStaticFolderCachedList } from '../StaticFolders';

const StaticSubFoldersPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [enableCreateNewForm, toggleCreateNewForm] = useState(false);

  const { folderId = '' } = useParams<{ folderId: string }>();

  const {
    data: folders,
    networkStatus,
    refetch: refetchFolders,
  } = useQuery<GetStaticFoldersQueryResponse, GetStaticFoldersQueryVariables>(
    GET_STATIC_FOLDERS_QUERY_FOR_LV,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        type: FolderTypeEnum.STATIC,
        parentFolder: folderId,
      },
    }
  );

  const folderActions = [
    <Button
      color="secondary"
      variant="contained"
      key="create-subfolder"
      size="small"
      onClick={() => toggleCreateNewForm(true)}
    >
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFolders(newFilters);
  };

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!!folders?.getStaticFolders?.title ? '' : folders?.getStaticFolders.title,
        hideSeparator: true,
      }}
      searchInputConfig={{
        enable: true,
        name: 'SubFolder search',
        placeholder: 'Folder Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={folderActions}
    >
      {loadingFolders ? (
        <LoadingIndicator />
      ) : (
        <DocumentFoldersGrid folders={folders?.getStaticFolders?.folders ?? []} />
      )}
      <SimplePopup
        title="Create a new folder"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={enableCreateNewForm}
      >
        <CreateFolder cb={() => toggleCreateNewForm(false)} />
      </SimplePopup>
    </Navbar>
  );
};

const CreateFolder: React.FC<{ cb: (arg: Folder) => void }> = ({ cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateStaticSubFolderMutationResponse,
    CreateStaticSubFolderMutationVariables
  >(CREATE_STATIC_SUB_FOLDER_MUTATION);

  const { folderId = '' } = useParams<{
    folderId: string;
  }>();

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    createFolder({
      variables: {
        input: {
          name: formState.name,
          parentFolder: folderId,
        },
      },
      onCompleted: res => {
        updateStaticFolderCachedList(res.createStaticFolder, {
          type: FolderTypeEnum.STATIC,
          parentFolder: folderId,
        });
        cb(res.createStaticFolder);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" gap={2} px={2} py={1}>
        <TextField
          label="Folder Name"
          value={formState.name ?? ''}
          onChange={e => handleChange('name', e.target.value)}
          required
        />
        <LoadingButton variant="contained" type="submit" loading={creatingFolder}>
          Create
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default StaticSubFoldersPage;
