import { Grid } from '@mui/material';
import React from 'react';
import { Followup, LeadLostReasonEnum, LeadStatusEnum } from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const FollowupSection: React.FC<{ followup: Followup }> = ({ followup }) => {
  const getConcatedContactNames = () => {
    return followup.followupContact.map(p => p.people.name + ` (${p.type})`).join(',\n');
  };

  const followupData: SectionDataProps[] = [
    {
      label: 'ID',
      value: followup.referenceId,
      type: 'STRING',
    },
    {
      label: 'Lead',
      value: followup.lead.name,
      type: 'LINK',
      navigateTo: `/leads/${followup.lead._id}`,
    },
    {
      label: 'Event Type',
      value: followup.followupType,
      type: 'STRING',
    },
    {
      label: 'Lead Status',
      value: getEnumKeyFromValue(LeadStatusEnum, followup.leadStatus),
      type: 'STRING',
    },
    followup.lostReason === LeadStatusEnum.LOST
      ? {
          label: 'Lost Reason',
          value: getEnumKeyFromValue(LeadLostReasonEnum, followup.lostReason),
          type: 'STRING',
        }
      : {
          label: 'Regret Reason',
          value: getEnumKeyFromValue(LeadLostReasonEnum, followup.lostReason),
          type: 'STRING',
        },
    {
      label: 'Event Date',
      value: followup.followupDate,
      type: 'DATE',
    },
    {
      label: 'Followup Contact',
      value: !!followup.followupContact.length ? getConcatedContactNames() : undefined,
      type: 'STRING',
    },
    {
      label: 'Created By',
      value: followup.createdBy.firstName + ' ' + followup.createdBy.lastName ?? '',
      type: 'STRING',
    },
    {
      label: 'Comments',
      value: followup.comments,
      type: 'STRING',
    },
  ];

  return (
    <Grid container direction="column" rowGap={1.5}>
      <Section collapsible data={followupData} title={followup.lead.name} />
    </Grid>
  );
};

export default FollowupSection;
