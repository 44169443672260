import { gql } from '@apollo/client';
import { Branch, BranchStatusEnum, Company, CompanyStatus, CompanyType } from 'types';

export const GET_COMPANIES_QUERY = gql`
  query GetCompanies($filter: CompanyFilter) {
    getCompanies(filter: $filter) {
      _id
      referenceId
      name
    }
  }
`;

export type CompaniesQueryResponse = {
  getCompanies: Company[];
};

export type CompaniesQueryVariables = {
  filter: {
    status?: CompanyStatus[];
    type?: CompanyType[];
  };
};

export const GET_BRANCHES_QUERY = gql`
  query GetBranches($filter: BranchFilter) {
    getBranches(filter: $filter) {
      _id
      referenceId
      name
    }
  }
`;

export type BranchesQueryResponse = {
  getBranches: Branch[];
};

export type BranchesQueryVariables = {
  filter: {
    status?: BranchStatusEnum[];
    company?: string;
  };
};
