import { gql } from '@apollo/client';
import { People, PeopleStatusEnum } from 'types';

export const GET_PEOPLES_QUERY = gql`
  query GetPeople($filter: PeopleFilter) {
    getPeople(filter: $filter) {
      _id
      referenceId
      name
      status
      designation
      email
      mobile
    }
  }
`;

export type PeopleQueryResponse = {
  getPeople: People[];
};
export type PeopleQueryVariables = {
  filter: {
    company?: string;
    branch?: string;
    status?: PeopleStatusEnum;
  };
};
