import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentFile } from 'types';

import MetadataForm from 'components/Inputs/Metadata';
import UploadFile from 'components/Inputs/UploadFile';

const UPLOAD_STATIC_FILE_MUTATION = gql`
  mutation UploadStaticFiles($input: UploadStaticFileInput!) {
    uploadStaticFiles(input: $input) {
      _id
      referenceId
      folder {
        _id
        referenceId
        name
      }
      name
      path
      metadata
      project {
        _id
        referenceId
        name
      }
      lead {
        _id
        referenceId
        name
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type UploadStaticFileMutationResponse = {
  uploadStaticFiles: DocumentFile[];
};

type UploadStaticFileMutationVariables = {
  input: {
    folder: string;
    fileDetails: {
      fileName: string;
      file: any;
      metadata: string;
    }[];
  };
};

const UPDATE_STATIC_FILE_MUTATION = gql`
  mutation UpdateStaticFile($id: ID!, $fileDetail: UpdateFileMetadataInput!) {
    updateStaticFile(_id: $id, fileDetail: $fileDetail) {
      _id
      referenceId
      folder {
        _id
        referenceId
        name
      }
      name
      path
      metadata
      project {
        _id
        referenceId
        name
      }
      lead {
        _id
        referenceId
        name
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

type UpdateStaticFileMutationResponse = {
  updateStaticFile: DocumentFile;
};

type UpdateStaticFileMutationVariables = {
  id: string;
  fileDetail: {
    metadata?: string;
    file?: File;
    fileName?: string;
  };
};

const StaticFileForm: React.FC<{
  initialData?: {
    _id: string;
    fileName: string;
    path: string;
    metadata: { key: string; value: any }[];
    file: DocumentFile;
  };
  folderId?: string;
  cb: (arg: DocumentFile) => void;
}> = ({ initialData, folderId = '', cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData ?? {});
  const [fileError, setFileError] = useState('');
  const [fileNameError, setFileNameError] = useState('');

  const { subFolderId = '' } = useParams<{
    subFolderId: string;
  }>();

  const [uploadFiles, { loading: uploadingFiles }] = useMutation<
    UploadStaticFileMutationResponse,
    UploadStaticFileMutationVariables
  >(UPLOAD_STATIC_FILE_MUTATION);

  const [updateFile, { loading: updatingFile }] = useMutation<
    UpdateStaticFileMutationResponse,
    UpdateStaticFileMutationVariables
  >(UPDATE_STATIC_FILE_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    if (!!fileError) setFileError('');
    if (fieldName === 'file' && value instanceof File) {
      if (value.name.length > 40) {
        setFileNameError('File name is too long. Please rename your file.');
      } else {
        setFileNameError('');
      }
    }

    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    if (!!!formState.file) {
      setFileError('Please upload a file');
      return;
    }

    const { _id, ...data } = formState;
    const metadata = {};
    (data.metadata ?? []).forEach(d => {
      if (!!d.key) {
        metadata[d.key] = d.value;
      }
    });

    if (!!_id) {
      updateFile({
        variables: {
          id: _id,
          fileDetail: {
            file: data.file instanceof File ? data.file : data.path,
            fileName: data.file instanceof File ? data.file.name : data.name,
            metadata: JSON.stringify(metadata),
          },
        },
        onCompleted: res => cb(res.updateStaticFile),
      });
    } else {
      uploadFiles({
        variables: {
          input: {
            folder: subFolderId,
            fileDetails: [
              {
                fileName: data.file.name,
                file: data.file,
                metadata: JSON.stringify(metadata),
              },
            ],
          },
        },
        onCompleted: res => cb(res.uploadStaticFiles[0]),
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={12}>
          <UploadFile
            values={formState.file ?? ''}
            label="Add File *"
            onChange={val => handleChange('file', val)}
            helperText={!!fileError ? fileError : undefined}
            accept="*"
            required={!!!formState.file}
          />
          {fileNameError && (
            <Typography variant="caption" color={'crimson'} letterSpacing={0.4}>
              {fileNameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <MetadataForm
            metadata={formState.metadata ?? [{ key: ``, value: `` }]}
            setMetadata={md =>
              setFormState(prev => ({
                ...prev,
                metadata: md,
              }))
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <LoadingButton
            loading={uploadingFiles || updatingFile}
            variant="contained"
            sx={{ ml: 'auto' }}
            type="submit"
            disabled={!!fileNameError}
          >
            {!!initialData?._id ? 'Update File' : 'Upload File'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default StaticFileForm;
