import { useMutation } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CardActions, Grid } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentFile, FolderTypeEnum } from 'types';

import {
  DELETE_FILE_MUTATION,
  DeleteFileMutationVariables,
  deleteCachedFileItemFromList,
} from 'components/DocumentMaster/DocumentFilesList';
import theme from 'components/Peoples/Card/theme.module.scss';
import { ConfirmationPopup } from 'components/Popup';

const DocumentActions: React.FC<{ file: DocumentFile }> = ({ file }) => {
  const [deleteConfirmationPopup, toggleDeleteConfirmationPopup] = useState({
    state: false,
    id: '',
  });

  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  const [deleteFile, { loading: deletingFile }] = useMutation<{}, DeleteFileMutationVariables>(
    DELETE_FILE_MUTATION
  );

  const handleDeleteClick = () => {
    toggleDeleteConfirmationPopup({
      id: file._id,
      state: true,
    });
  };

  const handleDownloadClick = () => {
    window.open(file.path, '_blank');
  };

  return (
    <CardActions
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 0,
        marginTop: 1,
        mb: -2.8,
      }}
      disableSpacing
    >
      <Grid container justifyContent="space-evenly" alignItems="center">
        <div className={theme.actionButton} onClick={handleDeleteClick}>
          <DeleteForeverIcon color="error" />
        </div>

        <div
          className={classNames(theme.actionButton, theme.lastActionButton)}
          onClick={handleDownloadClick}
        >
          <FileDownloadIcon color="primary" />
        </div>
      </Grid>
      <ConfirmationPopup
        onClose={() => toggleDeleteConfirmationPopup({ id: '', state: false })}
        onConfirmation={() => {
          deleteFile({
            variables: {
              id: deleteConfirmationPopup.id,
            },
            onCompleted: _ => {
              toast.success('File Deleted Successfully');
              deleteCachedFileItemFromList(
                {
                  folder: subFolderId,
                  lead: isLead ? documentId : undefined,
                  project: isLead ? undefined : documentId,
                },
                deleteConfirmationPopup.id
              );
              toggleDeleteConfirmationPopup({ id: '', state: false });
            },
          });
        }}
        open={deleteConfirmationPopup.state}
        title="Do you want to delete this document?"
        confirmationLabel="Yes"
        loading={deletingFile}
      />
    </CardActions>
  );
};

export default DocumentActions;
