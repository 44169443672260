import { IconProps } from 'types';

const SiteEhsicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      fill={color}
      {...attributes}
      viewBox="0 0 80 79"
    >
      <path
        d="M18.776 0c-.45 0-.816.366-.816.816v3.495C9.206 7.14 3.262 15.292 3.272 24.501H.816c-.45 0-.816.365-.816.815v4.899c0 .45.366.816.816.816h47.349c.45 0 .816-.366.816-.816v-4.899c0-.45-.365-.816-.816-.816h-2.459c.012-9.199-5.923-17.36-14.685-20.192V.816c0-.45-.365-.816-.816-.816H18.776zm.816 1.633h4.082v8.163h1.633V1.633h4.082v10.612c-.005.295.15.569.404.717.255.148.57.148.824 0s.408-.422.404-.717V6.033c7.82 2.76 13.066 10.147 13.056 18.467h-1.219c-.294-.004-.568.15-.716.405-.149.254-.149.569 0 .823.148.254.422.409.716.405h4.49v3.265H1.633v-3.265h4.49c.294.004.568-.15.716-.405.149-.254.149-.569 0-.823-.148-.254-.422-.41-.716-.405H4.9C4.89 16.17 10.145 8.787 17.96 6.03v6.215c-.004.295.15.569.405.717.254.148.568.148.823 0 .254-.148.409-.422.404-.717V1.633zm4.899 13.061c-2.245 0-4.082 1.837-4.082 4.082s1.837 4.082 4.082 4.082c2.244 0 4.081-1.837 4.081-4.082s-1.837-4.082-4.081-4.082zm0 1.633c1.362 0 2.449 1.087 2.449 2.45 0 1.361-1.087 2.448-2.45 2.448-1.362 0-2.449-1.087-2.449-2.449 0-1.362 1.087-2.449 2.45-2.449zm-11.512 8.164c-.451.022-.798.406-.775.857.023.451.407.798.858.775h22.857c.295.004.569-.15.717-.405.149-.254.149-.568 0-.823-.148-.254-.422-.409-.717-.404H13.062c-.028-.002-.056-.002-.083 0z"
        transform="translate(-748 -153) translate(693 110) translate(55.825 43.093) translate(16.323 19.588) translate(0 6.53)"
      />

      <path
        d="M40.049 13.058h4.782V8.92h4.138V4.138h-4.138V0h-4.782v4.138H35.91V8.92h4.138v4.138zM36.83 8.001V5.058h4.138V.92h2.943v4.138h4.138V8h-4.138v4.138h-2.943V8H36.83z"
        transform="translate(-748 -153) translate(693 110) translate(55.825 43.093) translate(16.323 19.588)"
      />
    </svg>
  );
};
export default SiteEhsicon;
