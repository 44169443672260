import { ApolloError } from '@apollo/client';
import { Alert, Button } from '@mui/material';
import { FC, useState } from 'react';

import theme from './theme.module.scss';

type ErrorMessageProps = {
  error: ApolloError | boolean | string | undefined;
  refetch?: () => void;
  type?: 'standard' | 'alert';
};

// TODO: Need to populate this JSON with more error codes and description
const errorMessageCodes = {
  UNAUTHENTICATED:
    'You need to be authenticated to perform this action. Please log in and try again',
  FORBIDDEN: "You don't have the necessary permissions to perform this action",
  GRAPHQL_PARSE_FAILED:
    'You seem to have encountered a bug. Please report this issue and our team will fix this for you right away',
  GRAPHQL_VALIDATION_FAILED:
    'You seem to have encountered a bug. Please report this issue and our team will fix this for you right away',
  ORDER_STATUS_INVALID: 'The action can not be preformed on this order',
  COUPON_INVALID: 'This coupon cannot be applied to this cart',
  BAD_USER_INPUT: 'An incorrect value was provided in this action',
};

const ErrorMessage: FC<ErrorMessageProps> = ({ refetch, error, type = 'standard' }) => {
  const [refetchAttempt, setRefetchAttempt] = useState(0);

  function getErrorMessage() {
    const errorMessage = 'There was an issue processing this request. Please try again later';

    if (!error || typeof error !== 'object' || !error.hasOwnProperty('graphQLErrors')) {
      return errorMessage;
    }

    const { graphQLErrors } = error;
    const graphQLError = graphQLErrors[0];
    const errorCode = graphQLError?.extensions?.code || 'INTERNAL_SERVER_ERROR';

    // @ts-ignore
    return errorMessageCodes[errorCode] || graphQLError?.message || errorMessage;
  }

  if (type === 'alert') {
    return (
      <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="error">
        {getErrorMessage()}
      </Alert>
    );
  }

  return (
    <div className={theme.errorContainer}>
      <div className={theme.title}>Error</div>
      <div className={theme.errorMessage}>{getErrorMessage()}</div>
      {refetch && refetchAttempt < 2 ? (
        <Button
          className={theme.retryButton}
          variant="contained"
          title="Refresh"
          onClick={() => {
            setRefetchAttempt(refetchAttempt + 1);
            refetch();
          }}
        >
          Retry
        </Button>
      ) : null}
      {refetchAttempt > 1 ? (
        <Button
          className={theme.retryButton}
          variant="contained"
          title="Report Issue"
          onClick={() => {
            setRefetchAttempt(refetchAttempt + 1);
          }}
        >
          Report Issue
        </Button>
      ) : null}
    </div>
  );
};

export default ErrorMessage;
