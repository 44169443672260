import {
  Assignment,
  AssignmentLateOutlined,
  AssignmentTurnedIn,
  Cancel,
  CheckCircle,
  CropFree,
  DeliveryDining,
  Done,
  FileDownload,
  FileUpload,
  FlipCameraAndroid,
  LocalShipping,
  NoteAdd,
  PublishedWithChanges,
  SettingsBackupRestore,
  SmsFailedOutlined,
  ThumbUpOutlined,
  Work,
} from '@mui/icons-material';
import { Badge, BadgeProps, Chip, CircularProgress, styled } from '@mui/material';

const statusDetails = {
  CREATED: {
    icon: <CircularProgress color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'CREATED',
    message: "Please wait while we confirm your payment. It shouldn't take too long.",
    color: '#FF8F00',
  },
  PAID: {
    icon: <CheckCircle color="success" style={{ color: '#00796B' }} />,
    defaultIcon: <CheckCircle />,
    label: 'ORDER RECEIVED',
    message: 'We received your payment. We will start processing your order soon.',
    color: '#00796B',
  },
  CONVERTED: {
    icon: <CheckCircle color="success" style={{ color: '#00796B' }} />,
    defaultIcon: <CheckCircle />,
    label: 'CONVERTED',
    message: 'This quotation is converted.',
    color: '#00796B',
  },
  AWAITING_CONFIRMATION: {
    icon: <CheckCircle color="success" style={{ color: '#727272' }} />,
    defaultIcon: <CheckCircle />,
    label: 'AWAITING SELLER CONFIRMATION',
    message: "We're waiting for confirmation from the seller.",
    color: '#727272',
  },
  CONFIRMED: {
    icon: <CheckCircle color="success" style={{ color: '#00ACC1' }} />,
    defaultIcon: <CheckCircle />,
    label: 'CONFIRMED BY SELLER',
    message: 'The seller has confirmed your order.',
    color: '#00ACC1',
  },
  PACKED: {
    icon: <CropFree color="primary" style={{ color: '#6D4C41' }} />,
    defaultIcon: <CropFree />,
    label: 'PACKED',
    message: 'Your order has been packed.',
    color: '#6D4C41',
  },
  READY_FOR_PICKUP: {
    icon: <Work color="primary" style={{ color: '#00ACC1' }} />,
    defaultIcon: <Work />,
    label: 'READY FOR DISPATCH',
    message: 'Your order has been packed and is now ready for dispatch.',
    color: '#00ACC1',
  },
  OUT_FOR_DELIVERY: {
    icon: <DeliveryDining color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <DeliveryDining />,
    label: 'OUT FOR DELIVERY',
    message: 'Your order is now out for delivery.',
    color: '#FF8F00',
  },
  DELIVERED: {
    icon: <ThumbUpOutlined color="primary" style={{ color: '#388E3C' }} />,
    defaultIcon: <ThumbUpOutlined />,
    label: 'DELIVERED',
    message: 'Your order was delivered successfully.',
    color: '#388E3C',
  },
  CANCELLED: {
    icon: <Cancel color="primary" style={{ color: '#E53935' }} />,
    defaultIcon: <Cancel />,
    label: 'CANCELLED',
    message: 'This order has been cancelled.',
    color: '#E53935',
  },
  DISPUTED: {
    icon: <SmsFailedOutlined color="primary" style={{ color: '#D81B60' }} />,
    defaultIcon: <SmsFailedOutlined />,
    label: 'UNDER DISPUTE',
    message: 'This order has been disputed. Please contact Youkraft for help regarding this order.',
    color: '#D81B60',
  },
  FAILED: {
    icon: <SmsFailedOutlined color="primary" style={{ color: '#E53935' }} />,
    defaultIcon: <SmsFailedOutlined />,
    label: 'FAILED',
    message: "This order has failed. Please contact Youkraft if you'd like to report this.",
    color: '#E53935',
  },
  SHIPPED: {
    icon: <LocalShipping color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <LocalShipping />,
    label: 'SHIPPED',
    message: 'Your order has now been shipped and is under way to your location.',
    color: '#FF8F00',
  },
  PARTIALLY_CANCELLED: {
    icon: <FlipCameraAndroid color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <FlipCameraAndroid />,
    label: 'PARTIALLY CANCELLED',
    message: 'Your order has now been partially cancelled. Please contact us for more information',
    color: '#FF8F00',
  },
  DELIVERY_PARTNER_ASSIGNED: {
    icon: <AssignmentTurnedIn color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <AssignmentTurnedIn />,
    label: 'DELIVERY PARTNER ASSIGNED',
    message: 'Your order has been assigned to the delivery partner',
    color: '#FF8F00',
  },
  IN_TRANSIT: {
    icon: <LocalShipping color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <LocalShipping />,
    label: 'IN TRANSIT',
    message: 'Your order is now in Transit',
    color: '#FF8F00',
  },
  APPROVED: {
    icon: <Done color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <Done />,
    label: 'APPROVED',
    message: 'Approved',
    color: '#008F00',
  },
  DONE: {
    icon: <CheckCircle color="primary" style={{ color: '#009900' }} />,
    defaultIcon: <CheckCircle />,
    label: 'DONE',
    message: 'Done',
    color: '#009900',
  },
  REUPLOAD: {
    icon: <FileUpload color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <FileUpload />,
    label: 'REUPLOAD',
    message: 'Reupload',
    color: '#008F00',
  },
  UPLOAD: {
    icon: <FileUpload color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <FileUpload />,
    label: 'UPLOAD',
    message: 'Upload',
    color: '#008F00',
  },
  DOWNLOAD: {
    icon: <FileDownload color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <FileDownload />,
    label: 'DOWNLOAD',
    message: 'Download',
    color: '#008F00',
  },
  COMPLETED: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'COMPLETED',
    message: 'Completed',
    color: '#008F00',
  },
  ACTIVE: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'ACTIVE',
    message: 'Active',
    color: '#008F00',
  },
  INACTIVE: {
    icon: <CircularProgress color="primary" style={{ color: '#FF0000' }} />,
    defaultIcon: <CircularProgress />,
    label: 'INACTIVE',
    message: 'Inactive',
    color: '#FF0000',
  },
  ASSIGNED: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'ASSIGNED',
    message: 'Assigned',
    color: '#008F00',
  },
  UNASSIGNED: {
    icon: <CircularProgress color="primary" style={{ color: '#FF0000' }} />,
    defaultIcon: <CircularProgress />,
    label: 'UNASSIGNED',
    message: 'Unassigned',
    color: '#FF0000',
  },
  SYSTEM_REJECTED: {
    icon: <Cancel color="primary" style={{ color: '#FF0000' }} />,
    defaultIcon: <Cancel />,
    label: 'SYSTEM REJECTED',
    message: 'System Rejected',
    color: '#FF0000',
  },
  REJECTED: {
    icon: <Cancel color="primary" style={{ color: '#FF0000' }} />,
    defaultIcon: <Cancel />,
    label: 'REJECTED',
    message: 'Rejected',
    color: '#FF0000',
  },
  RETURNED: {
    icon: <SettingsBackupRestore color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <SettingsBackupRestore />,
    label: 'RETURNED',
    message: 'Your order is Returned',
    color: '#FF8F00',
  },
  PENDING: {
    icon: <SettingsBackupRestore color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <SettingsBackupRestore />,
    label: 'PENDING',
    message: 'Pending',
    color: '#FF8F00',
  },
  PRICE_PENDING: {
    icon: <CircularProgress color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'PRICE PENDING',
    message: 'Price Pending',
    color: '#FF8F00',
  },
  UNDER_REVIEW: {
    icon: <CircularProgress color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'UNDER REVIEW',
    message: 'Under Review',
    color: '#FF8F00',
  },
  IN_PROGRESS: {
    icon: <CircularProgress color="primary" style={{ color: '#009FFD' }} />,
    defaultIcon: <CircularProgress />,
    label: 'IN PROGRESS',
    message: 'In Progress',
    color: '#009FFD',
  },
  UPCOMING: {
    icon: <CircularProgress color="primary" style={{ color: '#009FFD' }} />,
    defaultIcon: <CircularProgress />,
    label: 'UPCOMING',
    message: 'Upcoming',
    color: '#009FFD',
  },
  PARTIALLY_RETURNED: {
    icon: <LocalShipping color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <LocalShipping />,
    label: 'PARTIALLY RETURNED',
    message: 'Your order is now Partially Returned',
    color: '#FF8F00',
  },
  REPLACEMENT: {
    icon: <PublishedWithChanges color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <PublishedWithChanges />,
    label: 'REPLACEMENT',
    message: 'Your order is now in Replacement',
    color: '#FF8F00',
  },
  REPLACED: {
    icon: <PublishedWithChanges color="primary" style={{ color: '#FF8F00' }} />,
    defaultIcon: <PublishedWithChanges />,
    label: 'REPLACED',
    message: 'Your order is Replaced successfully',
    color: '#FF8F00',
  },
  SUBMIT: {
    icon: <Assignment color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <Assignment />,
    label: 'SUBMIT',
    message: 'Your request is Submitted successfully',
    color: '#008F00',
  },
  NEW: {
    icon: <NoteAdd color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <NoteAdd />,
    label: 'NEW',
    message: 'New',
    color: '#008F00',
  },
  SHADOW_SELLER: {
    icon: <NoteAdd color="primary" style={{ color: '#8F0000' }} />,
    chip: <Chip label="Shadow Seller" variant="filled" style={{ color: '#8F0000' }} size="small" />,
    defaultIcon: <NoteAdd />,
    label: 'SHADOW SELLER',
    message: 'Shadow Seller',
    color: '#8F0000',
  },
  SELLER: {
    icon: <NoteAdd color="primary" style={{ color: '#008F00' }} />,
    chip: <Chip label="Seller" variant="filled" style={{ color: '#008F00' }} size="small" />,
    defaultIcon: <NoteAdd />,
    label: 'SELLER',
    message: 'Seller',
    color: '#008F00',
  },
  NO_DUE: {
    icon: <NoteAdd color="primary" style={{ color: '#008F00' }} />,
    chip: <Chip label="Seller" variant="filled" style={{ color: '#008F00' }} size="small" />,
    defaultIcon: <NoteAdd />,
    label: 'NO DUE',
    message: 'No Due',
    color: '#008F00',
  },
  COLLECTED: {
    icon: <CropFree color="success" style={{ color: '#008F00' }} />,
    chip: <Chip label="Seller" variant="filled" style={{ color: '#008F00' }} size="small" />,
    defaultIcon: <CropFree />,
    label: 'COLLECTED',
    message: 'COLLECTED',
    color: '#008F00',
  },
  HOT: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'HOT',
    message: 'Hot',
    color: 'crimson',
  },
  WARM: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'WARM',
    message: 'Warm',
    color: 'yellow',
  },
  COLD: {
    icon: <CircularProgress color="primary" style={{ color: '#008F00' }} />,
    defaultIcon: <CircularProgress />,
    label: 'COLD',
    message: 'Cold',
    color: 'skyblueF',
  },
  default: {
    icon: <AssignmentLateOutlined color="primary" style={{ color: '#727272' }} />,
    defaultIcon: <AssignmentLateOutlined />,
    label: 'UNKNOWN',
    message:
      "We either can't find this order or you've encountered an issue. Please contact us for more information.",
    color: '#727272',
  },
};

const getBadgeWithStatus = statusData => {
  const { color: statusColor, label: statusLabel } = statusData;
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: -1,
      backgroundColor: statusColor,
    },
  }));

  return (
    <>
      <Badge style={{ paddingLeft: 10 }}>
        <StyledBadge variant="dot"></StyledBadge>
      </Badge>
      {statusLabel}
    </>
  );
};

const getStatusDetail = (status: string) => {
  const statusData = statusDetails[status] || { ...statusDetails['default'], label: status };
  const labelWithBadge = getBadgeWithStatus(statusData);

  return { ...statusData, labelWithBadge };
};

export default getStatusDetail;
