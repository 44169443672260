import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { Lead, PQSubmitTypeEnum, RFPSubmitTypeEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

import { formatDate } from 'utils/transformFn';

const SUBMIT_PQ_MUTATION = gql`
  mutation SubmitPQ($input: SubmitPQInput!) {
    submitPQ(input: $input) {
      _id
      name
      pqReceivedDate
      pqSubmittedDate
      leadStatus
    }
  }
`;

type SubmitPQMutationResponse = {
  submitPQ: Lead;
};

type SubmitPQMutationVariables = {
  input: {
    lead: string;
    pqSubmitType: PQSubmitTypeEnum;
    pqSubmittedDate: string;
  };
};

const SubmitPQForm: React.FC<{ lead: Lead; cb: (lead: Lead) => void }> = ({ cb, lead }) => {
  const [submitPQ, { loading: submittingPQ }] = useMutation<
    SubmitPQMutationResponse,
    SubmitPQMutationVariables
  >(SUBMIT_PQ_MUTATION);

  const handleSubmit = (data: Record<string, any>) => {
    const pqSubmittedDate = formatDate(data.pqSubmittedDate) as string;

    submitPQ({
      variables: {
        input: {
          lead: lead._id,
          pqSubmitType: data.pqSubmitType,
          pqSubmittedDate,
        },
      },
      onCompleted: updatedLead => {
        cb(updatedLead.submitPQ);
      },
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container direction="column" rowGap={2} px={2} py={2}>
        <FormPanel
          error={null}
          loading={submittingPQ}
          onSubmit={handleSubmit}
          submitButtonLabel="Submit PQ"
        >
          <FormInput
            fieldName="pqSubmitType"
            label="PQ Submit Type"
            type="select"
            defaultValue={RFPSubmitTypeEnum.OFFLINE}
            options={Object.keys(RFPSubmitTypeEnum).map(o => ({
              label: o,
              value: RFPSubmitTypeEnum[o],
            }))}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="pqSubmittedDate"
            label="PQ Submit Date"
            type="date"
            defaultValue={!!lead.pqSubmittedDate ? dayjs(lead.pqSubmittedDate) : null}
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      </Grid>
    </LocalizationProvider>
  );
};

export default SubmitPQForm;
