import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    funnelState: {
      hot: string;
      warm: string;
      cold?: string;
      lost?: string;
    };
    boxshadow: {
      orange: string;
      gray: string;
    };
    navbar: {
      background: string;
    };
  }

  interface PaletteOptions {
    funnelState: {
      hot: string;
      warm: string;
      cold: string;
      lost: string;
    };
    boxshadow: {
      orange: string;
      gray: string;
    };
    navbar: {
      background: string;
    };
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    extraLight?: string;
  }
}

const extendedTheme = createTheme({
  palette: {
    primary: {
      main: '#006AB4',
    },
    secondary: {
      main: '#FF4D00',
      light: '#FF7A40',
      dark: '#BF3A00',
      extraLight: '#FFA67F',
    },
    funnelState: {
      hot: '#e2fbe2',
      warm: '#f9e29b',
      cold: '#ffffff',
      lost: '#dadddf',
    },
    boxshadow: {
      orange: '0px 3px 5px rgba(255, 122, 64, 0.5)',
      gray: '0px 3px 5px rgba(128, 128, 128, 0.5)',
    },
    navbar: {
      background: '#fbcfbc',
    },
  },
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
    body1: {
      fontWeight: 400,
      fontSize: '0.8rem',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      marginTop: '2rem',
      color: '#212121',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.125rem',
      marginTop: '2.5rem',
      marginBottom: '2.5rem',
      color: '#212121',
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: 600,
      lineHeight: '1.425rem',
    },
    overline: {
      lineHeight: '1.2rem',
    },
  },
});

export default extendedTheme;
