import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';

import Transition from 'components/Transition';

type ConfirmationPopupProps = {
  title: string;
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  confirmationLabel?: string;
  loading?: boolean;
} & DialogProps;

export const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  title,
  children,
  onConfirmation,
  confirmationLabel = 'Accept',
  loading = false,
  open,
  onClose,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} {...props}>
      <DialogTitle sx={{ fontWeight: 600, fontSize: '1.1rem', pb: 1.1 }}>{title}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <LoadingButton
          fullWidth
          onClick={onConfirmation}
          variant="contained"
          loading={loading}
          disabled={loading}
        >
          {confirmationLabel}
        </LoadingButton>
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type SimplePopupProps = {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  enableBackdropClickClosure?: boolean;
} & DialogProps;

export const SimplePopup: React.FC<SimplePopupProps> = ({
  children,
  onClose,
  open,
  title,
  enableBackdropClickClosure = false,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (
          reason &&
          !enableBackdropClickClosure &&
          (reason === 'backdropClick' || reason === 'escapeKeyDown')
        )
          return;
        onClose();
      }}
      TransitionComponent={Transition}
      {...props}
    >
      {!!title && (
        <DialogTitle
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ fontWeight: 600, fontSize: '1.1rem', pb: 1.1 }}
        >
          {title}
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </DialogTitle>
      )}
      {children && (
        <DialogContent
          sx={{
            m: 0,
            p: 0,
          }}
        >
          {children}
        </DialogContent>
      )}
    </Dialog>
  );
};
