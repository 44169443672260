import { NetworkStatus, gql, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  GET_STATIC_FILES_QUERY,
  GetStaticFilesQueryResponse,
  GetStaticFilesQueryVariables,
} from 'graphql/query/files';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, FolderTypeEnum } from 'types';

import CopyStandardFiles from 'components/DocumentMaster/CopyStaticFiles';
import Filters from 'components/Filters';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { transformFilters } from 'utils/transformFn';

const GET_FOLDER_QUERY = gql`
  query GetFolderById($id: ID!) {
    getFolderById(_id: $id) {
      _id
      name
    }
  }
`;

type GetFolderQueryResponse = {
  getFolderById: Folder;
};

type GetFolderQueryVariables = {
  id: string;
};

const COPY_FILES_MUTATION = gql`
  mutation CopyFiles($input: CopyFileInput!) {
    copyFiles(input: $input)
  }
`;

type CopyFilesMutationVariables = {
  input: {
    project?: string;
    lead?: string;
    fileDetails: {
      srcFileId: string;
      destFolderId: string;
      destFileName?: string;
    }[];
  };
};

const CopyStaticFilesPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rowOptions, setRowOptions] = useState<{ [key: string]: string }>({});

  const navigate = useNavigate();

  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  // const { data: folders, loading: loadingFolders } = useQuery<
  //   GetFoldersQueryResponse,
  //   GetFoldersQueryVariables
  // >(GET_FOLDERS_QUERY_FOR_AC, {
  //   variables: {
  //     type: folderType as FolderTypeEnum,
  //     projectOrLeadId: documentId,
  //     parentFolder: subFolderId
  //   }
  // });

  const [copyFiles, { loading: copyingFiles }] = useMutation<{}, CopyFilesMutationVariables>(
    COPY_FILES_MUTATION
  );

  const {
    data: files,
    networkStatus: standardFilesNetworkStatus,
    refetch: refetchStandardFiles,
  } = useQuery<GetStaticFilesQueryResponse, GetStaticFilesQueryVariables>(GET_STATIC_FILES_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const { data: currFolder } = useQuery<GetFolderQueryResponse, GetFolderQueryVariables>(
    GET_FOLDER_QUERY,
    {
      variables: {
        id: subFolderId,
      },
    }
  );

  const applyFilters = (newFilters: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));

    refetchStandardFiles({
      ...filters,
      ...newFilters,
    });
  };

  const handleSubmit = (data: { srcFileId: string; destFolderId: string }[]) => {
    copyFiles({
      variables: {
        input: {
          lead: isLead ? documentId : undefined,
          project: isLead ? undefined : documentId,
          fileDetails: data,
        },
      },
      onCompleted: _ => navigate(-1),
    });
  };

  const loadingStandardFiles =
    standardFilesNetworkStatus === NetworkStatus.loading ||
    standardFilesNetworkStatus === NetworkStatus.setVariables;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Add Files',
      }}
      searchInputConfig={{
        enable: true,
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
        name: 'Search Files',
        placeholder: 'Search Standard Files',
      }}
      actionChildren={
        <Filters
          type="static-files"
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data, ['folder']),
            });
          }}
        />
      }
    >
      {loadingStandardFiles && <LoadingIndicator size="1.6rem" />}
      <Box bgcolor="white" py={2} display={loadingStandardFiles ? 'none' : 'block'}>
        <CopyStandardFiles
          options={
            !!currFolder?.getFolderById
              ? [{ label: currFolder.getFolderById.name, value: currFolder.getFolderById._id }]
              : [{ label: '', value: '' }]
          }
          rowOptions={rowOptions}
          selectedRows={selectedRows}
          loading={copyingFiles}
          setRowOptions={setRowOptions}
          setSelectedRows={newRows => {
            setSelectedRows(newRows);
          }}
          rows={files?.getStaticFiles.files ?? []}
          cb={handleSubmit}
        />
      </Box>
    </Navbar>
  );
};

export default CopyStaticFilesPage;
