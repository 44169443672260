import { gql } from '@apollo/client';
import { Lead } from 'types';

export const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      _id
      referenceId
      name
      areaOrPower
      areaOrPowerUOM
      scope
      isBuildAndDesign
      segment
      createdBy {
        _id
        firstName
        lastName
      }
      lostReason
      leadStatus
      pqSubmitType
      rfpSubmitType
      approxValue
      poAmount
      poIssuedBy
      poNumber
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      followups {
        _id
        lead {
          _id
          name
        }
        referenceId
        leadStatus
        followupType
        followupDate
        comments
        lostReason
      }
      address {
        _id
        referenceId
        addressLine1
        addressLine2
        addressLocation
        locationUrl
        city
        country
        pincode
        state
      }
      company {
        _id
        name
        type
        referenceId
      }
      branch {
        name
        type
        address {
          addressLine1
          addressLocation
          addressLine2
          state
          city
        }
      }
      architect {
        _id
        name
        referenceId
        type
      }
      architectBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
          _id
        }
      }
      pmc {
        _id
        name
        referenceId
        type
      }
      pmcBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      pmo {
        _id
        name
        referenceId
        type
      }
      pmoBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      costConsultant {
        _id
        name
        referenceId
        type
      }
      costConsultantBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      others {
        _id
        name
        referenceId
        type
        branches {
          _id
          name
          address {
            addressLine1
            addressLocation
            addressLine2
            city
            state
          }
        }
      }
      competitors {
        _id
        name
        referenceId
        type
        branches {
          _id
          name
          address {
            addressLine1
            addressLocation
            addressLine2
            city
            state
          }
        }
      }
      branch {
        _id
        name
        referenceId
      }
      salesPerson {
        _id
        firstName
        lastName
      }
    }
  }
`;

export type UpdateLeadMutationResponse = {
  updateLead: Lead;
};
export type UpdateLeadMutationVariables = {
  input: Partial<Lead>;
};
