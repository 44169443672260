import { ApolloQueryResult } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Dialog,
  DialogContent,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { PQQueryResponse, PQQueryVariables } from 'routes/PQs/PQDetails';
import { Lead, LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum } from 'types';

import SharePQForm from 'components/PQs/Forms/SharePQForm';
import SubmitPQForm from 'components/PQs/Forms/SubmitPQForm';
import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';
import Transition from 'components/Transition';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

import UpdatePQForm from '../../Forms/UpdatePQForm';

const PQSection: React.FC<{
  lead: Lead;
  refetchPQ: (variables?: Partial<PQQueryVariables>) => Promise<ApolloQueryResult<PQQueryResponse>>;
}> = ({ lead, refetchPQ }) => {
  const [enableEditForm, toggleEditForm] = useState(false);
  const [enableSubmitForm, toggleSubmitForm] = useState(false);
  const [enableSharePQForm, toggleSharePQForm] = useState(false);

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const pqActions: any = [];

  if (lead.leadStatus === LeadStatusEnum['PQ SUBMITTED']) {
    pqActions.push({
      title: 'Share',
      onClick: () => toggleSharePQForm(true),
      disabled: false,
    });
  } else if (lead.leadStatus === LeadStatusEnum['PQ RECEIVED']) {
    pqActions.push(
      { title: 'Edit', onClick: () => toggleEditForm(true), disabled: false },
      {
        title: 'Submit',
        onClick: () => toggleSubmitForm(true),
        disabled: false,
      }
    );
  }

  const pqDetails: SectionDataProps[] = [
    {
      label: 'Lead',
      value: lead.name,
      type: 'LINK',
      navigateTo: `/leads/${lead._id}`,
    },
    {
      label: 'Company',
      value: lead.company.name,
      type: 'LINK',
      navigateTo: `/companies/${lead.company._id}`,
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus),
      type: 'STRING',
    },
    { label: 'Segment', value: getEnumKeyFromValue(LeadSegmentEnum, lead.segment), type: 'STRING' },
    { label: 'Scope', value: getEnumKeyFromValue(LeadScopeEnum, lead.scope), type: 'STRING' },
    { label: 'Design & Build', value: lead.isBuildAndDesign ? 'Yes' : 'No', type: 'BOOLEAN' },
  ];

  if (lead.approxValue) {
    pqDetails.push({
      label: 'Approx Value',
      value: `₹ ${lead.approxValue} Cr`,
      type: 'STRING',
    });
  }
  if (lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] && lead.pqReceivedDate) {
    pqDetails.push({
      label: 'Submission Date',
      value: getDate(lead.pqReceivedDate),
      type: 'STRING',
    });
  }

  if (lead.leadStatus === LeadStatusEnum['PQ SUBMITTED'] && lead.pqSubmittedDate) {
    pqDetails.push(
      {
        label: 'Submitted Date',
        value: getDate(lead.pqSubmittedDate),
      },
      { label: 'Submit Type', value: lead.pqSubmitType }
    );
  }

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section title="PQ Details" collapsible data={pqDetails} action={pqActions} />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update PQ"
        fullWidth
      >
        <UpdatePQForm
          cb={() => {
            refetchPQ({
              id: lead._id,
            });
            toggleEditForm(false);
          }}
          lead={lead}
        />
      </SimplePopup>
      <SimplePopup
        onClose={() => toggleSubmitForm(false)}
        open={enableSubmitForm}
        title="Submit PQ"
        fullWidth
      >
        <SubmitPQForm
          lead={lead}
          cb={() => {
            refetchPQ({
              id: lead._id,
            });
            toggleSubmitForm(false);
            toggleSharePQForm(true);
          }}
        />
      </SimplePopup>
      <Dialog
        open={enableSharePQForm}
        TransitionComponent={Transition}
        fullWidth
        fullScreen={isMobileScreen}
        onClose={(_, reason) => {
          if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
          toggleSharePQForm(false);
        }}
      >
        <AppBar sx={{ position: 'relative', bgcolor: theme => theme.palette.primary.main }}>
          <Toolbar variant={'dense'}>
            <Typography sx={{ flex: 1, fontWeight: 600 }} variant="subtitle1" component="div">
              Mail PQ Folders
            </Typography>
            <CloseIcon onClick={() => toggleSharePQForm(false)} sx={{ cursor: 'pointer' }} />
          </Toolbar>
        </AppBar>

        <DialogContent>
          <SharePQForm lead={lead} cb={() => toggleSharePQForm(false)} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default PQSection;
