import { IconProps } from 'types';

const Qualityicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      fill={color}
      {...attributes}
      viewBox="0 0 80 79"
    >
      <path
        d="M50.449 25.47c2.63-2.62 1.818-7.918-2.74-7.918l-11.99.008c.454-2.54 1.114-6.749 1.088-7.146-.252-3.764-2.651-8.345-2.755-8.533-.438-.815-2.647-1.92-4.87-1.446-2.876.615-3.17 2.449-3.158 2.954 0 0 .124 5.017.136 6.356-1.374 3.019-6.113 10.952-7.55 11.564-.343-.208-.733-.32-1.139-.32H2.321C1.036 20.989 0 22.029 0 23.31l.002 20.938c.09 1.129 1.049 2.014 2.182 2.014H15.79c1.207 0 2.188-.98 2.188-2.187v-.696s.506-.037.735.11c.875.556 1.956 1.255 3.367 1.255h20.306c7.592 0 6.777-6.739 6.084-7.658 1.283-1.397 2.076-3.86.993-5.806.831-.881 2.294-3.303.985-5.81zm-35.125 18.6H2.18V23.174h13.144V44.07zm31.583-18.78l-.124.517c3.498.995 1.636 5.027-.874 5.289l-.124.517c3.355.853 1.755 5.005-.873 5.288l-.124.516c2.74.45 2.076 5.124-2.074 5.124l-20.654.008c-1.459 0-2.787-1.662-3.86-1.662h-.936V24.803c1.167-.724 2.601-1.662 3.398-2.34 1.507-1.285 7.672-11.248 7.672-12.495 0-1.247-.136-6.64-.136-6.64s1.21-1.56 3.908-.422c0 0 2.272 4.26 2.503 7.654 0 0-1.022 6.992-1.572 9.186h14.32c3.517.004 2.846 5.027-.45 5.544z"
        transform="translate(-538 -363) translate(483 320) translate(55.825 43.093) translate(13.058 16.323)"
      />
    </svg>
  );
};
export default Qualityicon;
