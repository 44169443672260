import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  CREATE_PEOPLE_MUTATION,
  CreatePeopleMutationResponse,
  CreatePeopleMutationVariables,
  UPDATE_PEOPLE_MUTAION,
  UpdatePeopleMutationResponse,
  UpdatePeopleMutationVariables,
} from 'graphql/mutation/people';
import { GET_PEOPLES_QUERY } from 'graphql/query/people';
import React from 'react';
import { People, PeopleStatusEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

import { removeEmptyFields } from 'utils/common';

const PeopleForm: React.FC<{
  cb: (arg: People) => void;
  people?: People;
  companyId: string;
  branchId: string;
}> = ({ cb, people, branchId, companyId }) => {
  const [createPeople, { loading: creatingPeople }] = useMutation<
    CreatePeopleMutationResponse,
    CreatePeopleMutationVariables
  >(CREATE_PEOPLE_MUTATION);

  const [updatePeople, { loading: updatingPeople }] = useMutation<
    UpdatePeopleMutationResponse,
    UpdatePeopleMutationVariables
  >(UPDATE_PEOPLE_MUTAION);

  const handleSubmit = (data: Record<string, any>) => {
    const refinedFormData = removeEmptyFields(data);

    if (!!people) {
      updatePeople({
        variables: {
          input: {
            _id: people._id,
            ...refinedFormData,
            mobile: String(data.mobile),
          },
        },
        onCompleted: res => cb(res.updatePeople),
      });
    } else {
      createPeople({
        variables: {
          input: {
            ...refinedFormData,
            mobile: String(refinedFormData.mobile),
            company: companyId,
            branch: branchId,
          },
        },
        onCompleted: p => {
          updateCachedList(GET_PEOPLES_QUERY, 'getPeople', p.createPeople, {
            filter: { branch: branchId },
          });
          cb(p.createPeople);
        },
      });
    }
  };

  return (
    <Grid px={2} py={2}>
      <FormPanel
        error={null}
        loading={creatingPeople || updatingPeople}
        onSubmit={handleSubmit}
        submitButtonLabel={!!people ? 'Update' : 'Add'}
      >
        <FormInput
          fieldName="name"
          label="Name"
          type="string"
          defaultValue={people?.name ?? ''}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fieldName="designation"
          label="Designation"
          defaultValue={people?.designation ?? ''}
          type="string"
          validators={{
            required: true,
          }}
        />
        <FormInput
          fieldName="email"
          label="Email"
          type="string"
          fullWidth
          defaultValue={people?.email ?? ''}
          validators={{
            required: true,
            isEmail: true,
          }}
        />
        <FormInput
          fieldName="mobile"
          label="Mobile"
          defaultValue={people?.mobile ?? ''}
          type="number"
          validators={{
            required: true,
            isPhoneNumber: true,
          }}
        />
        <FormInput
          fieldName="status"
          label="Status"
          type="select"
          options={Object.values(PeopleStatusEnum).map(o => ({ label: o, value: o }))}
          defaultValue={people?.status ?? PeopleStatusEnum.ACTIVE}
          validators={{
            required: true,
          }}
        />
      </FormPanel>
    </Grid>
  );
};

export default PeopleForm;
