import { Box, Grid } from '@mui/material';
import { FC } from 'react';

const FocusLayout: FC<{ title?: string; children: any }> = props => {
  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item xs={11} md={6} lg={4} xl={3}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        >
          <div>{props.children}</div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FocusLayout;
