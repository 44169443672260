import { gql, useMutation } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddressType, Lead } from 'types';

import CreateLeadAndCompanyForm from 'components/Leads/Forms/CreateLeadAndCompanyForm';
import Navbar from 'components/Navbar';
import AddressForm from 'components/Section/AddressSection/AddressForm';

import { removeEmptyFields } from 'utils/common';
import { formatDate } from 'utils/transformFn';

const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      _id
      referenceId
    }
  }
`;

type CreateLeadMutationResponse = {
  createLead: Lead;
};

type CreateLeadMutationVariables = {
  input: Record<string, any>;
};

const CreateLead = () => {
  const [currPage, setCurrPage] = useState(0);
  const navigate = useNavigate();

  const [createLead, { loading: creatingLead }] = useMutation<
    CreateLeadMutationResponse,
    CreateLeadMutationVariables
  >(CREATE_LEAD_MUTATION);

  const [formState, setFormState] = useState<Record<string, any>>({
    isBuildAndDesign: false,
  });

  const handleNext = () => {
    setCurrPage(1);
  };

  const handleSubmit = (newAddress: AddressType) => {
    const expectedPODate = formState.expectedPODate;
    const refinedLead = removeEmptyFields({ ...formState });
    refinedLead.expectedPODate = formatDate(expectedPODate);

    createLead({
      variables: {
        input: {
          ...refinedLead,
          approxValue: Number(refinedLead.approxValue),
          areaOrPower: Number(refinedLead.areaOrPower.replace(/,/g, '')),
          address: newAddress._id,
        },
      },
      onCompleted: res => navigate(`/leads/${res.createLead._id}`, { replace: true }),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: currPage === 0 ? 'Create Lead' : 'Site Address',
        onClick: currPage === 1 ? () => setCurrPage(0) : undefined,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={0.5}>
        <Box display={currPage !== 0 ? 'none' : 'block'}>
          <CreateLeadAndCompanyForm
            formState={formState}
            setFormState={setFormState}
            handleNext={handleNext}
          />
        </Box>

        <Box display={currPage !== 1 ? 'none' : 'block'}>
          <AddressForm cb={handleSubmit} loading={creatingLead} />
        </Box>
      </Grid>
    </Navbar>
  );
};

export default CreateLead;
