import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SettingsIcon from '@mui/icons-material/Settings';
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import extendedTheme from 'styles/theme';
import { LeadSortOptionEnum } from 'types';

import { SimplePopup } from 'components/Popup';

import theme from './theme.module.scss';

type SortType = LeadSortOptionEnum | undefined;

export const Sort: React.FC<{ sort: SortType; setSort: (newSortValue: SortType) => void }> = ({
  sort,
  setSort,
}) => {
  const [sortPopUp, toggleSortPopUp] = useState(false);

  return (
    <>
      <Button
        startIcon={<SortIcon />}
        variant="outlined"
        sx={{ backgroundColor: extendedTheme.palette.common.white }}
        onClick={() => toggleSortPopUp(true)}
        color="secondary"
        fullWidth
        size="small"
      >
        Sort
      </Button>
      <SimplePopup
        title="Sort By"
        open={sortPopUp}
        onClose={() => toggleSortPopUp(false)}
        fullWidth
      >
        <Grid container display={'flex'} direction={'column'} px={2} pb={3}>
          <SortItem
            icon={<SortByAlphaIcon fontSize="small" />}
            label="Alpabetically"
            states={[LeadSortOptionEnum.NameAToZ, LeadSortOptionEnum.NameZToA]}
            sort={sort}
            setSort={setSort}
          />
          <SortItem
            icon={<SettingsIcon fontSize="small" />}
            label="Status"
            states={[
              LeadSortOptionEnum.probabilityLowToHigh,
              LeadSortOptionEnum.probabilityHighToLow,
            ]}
            sort={sort}
            setSort={setSort}
          />
          <SortItem
            icon={<CurrencyRupeeIcon fontSize="small" />}
            label="Value"
            isLastItem={true}
            states={[LeadSortOptionEnum.ValueLowToHigh, LeadSortOptionEnum.ValueHighToLow]}
            sort={sort}
            setSort={setSort}
          />
        </Grid>
      </SimplePopup>
    </>
  );
};

type SortItemProps = {
  label: string;
  icon: any;
  states: [LeadSortOptionEnum, LeadSortOptionEnum];
  sort: SortType;
  setSort: (newValue: SortType) => void;
  isLastItem?: boolean;
};

const SortItem: React.FC<SortItemProps> = ({
  label,
  icon,
  isLastItem = false,
  setSort,
  sort,
  states,
}) => {
  const handleChange = () => {
    if (sort === states[0]) setSort(states[1]);
    else if (sort === states[1]) return setSort(undefined);
    else return setSort(states[0]);
  };

  const assignIcon = () => {
    switch (sort) {
      case states[0]:
        return <ArrowDownwardIcon fontSize="small" />;
      case states[1]:
        return <ArrowUpwardIcon fontSize="small" />;
      default:
        return icon;
    }
  };

  return (
    <>
      <div
        className={theme.item}
        onClick={handleChange}
        style={{ borderBottom: isLastItem ? 'none' : '1px solid #ccc' }}
      >
        {assignIcon()}
        <Typography variant="body2">{label}</Typography>
      </div>
    </>
  );
};
