import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { LeadDashboardFiltersType, useDashboard } from 'contexts/dashboardContext';
import { Lead, LeadSegmentEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import LeadsTemplate from 'components/Leads';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      scope
      segment
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      leadStatus
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

type GetLeadsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
  };
};

const DashboardLeads = () => {
  const { leadFilters, setLeadFilters } = useDashboard();

  const {
    data: leads,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetLeadsQueryResponse, GetLeadsQueryVariables>(GET_LEADS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        segment: leadFilters.segment,
        monthType: leadFilters.monthType,
        leadStatus: leadFilters.leadStatus,
      },
    },
  });

  const applyFilters = (newFilters: LeadDashboardFiltersType) => {
    setLeadFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!leads;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Leads',
      }}
      searchInputConfig={{
        enable: true,
        name: 'company search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...leadFilters,
            searchTerm: !!searchFilter ? searchFilter : undefined,
          }),
      }}
    >
      {loadingLeads ? <LoadingIndicator size="1.6rem" /> : <LeadsTemplate leads={leads.getLeads} />}
    </Navbar>
  );
};

export default DashboardLeads;
