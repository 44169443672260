import { Box, Grid } from '@mui/material';
import {
  DOCUMENT_PAGINATION_LIMIT,
  GET_DOCUMENTS_QUERY,
  useDocumentMaster,
} from 'contexts/documentsContext';
import { updateCachedListWithPagination } from 'graphql/cacheUpdate';
import { useNavigate } from 'react-router-dom';
import { DocumentMaster } from 'types';

import CreateDocumentFormTemplate from 'components/DocumentMaster/CreateDocumentTemplate';
import Navbar from 'components/Navbar';

const CreateDocumentPage = () => {
  const navigate = useNavigate();
  const {
    filters,
    pagination: { currPage },
  } = useDocumentMaster();

  const handleSubmit = (docs: DocumentMaster[]) => {
    updateCachedListWithPagination(GET_DOCUMENTS_QUERY, 'getDocumentMasters', docs, {
      filter: filters,
      limit: DOCUMENT_PAGINATION_LIMIT,
      page: currPage,
    });
    navigate('/documents');
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Document',
      }}
    >
      <Box width="100%" mt={2}>
        <Grid container direction="column" item xs={12} md={10} lg={9} xl={6}>
          <CreateDocumentFormTemplate cb={handleSubmit} />
        </Grid>
      </Box>
    </Navbar>
  );
};

export default CreateDocumentPage;
