import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

type ForgotPasswordMutationVariables = {
  email: string;
};

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState('');

  const [shouldDisableSubmit, setDisableSubmit] = useState(true);

  const [forgotPassword, { loading }] = useMutation<any, ForgotPasswordMutationVariables>(
    FORGOT_PASSWORD_MUTATION
  );

  const handleChange = (val: string) => {
    setEmail(val);
    if (shouldDisableSubmit) setDisableSubmit(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSuccessMsg('');

    forgotPassword({
      variables: {
        email,
      },
      onCompleted: _ => {
        setDisableSubmit(true);
        setSuccessMsg('Please check your email.');
      },
    });
  };

  return (
    <FocusLayout>
      <form onSubmit={onSubmit}>
        <Grid container flexDirection="column" gap={1} pt={5}>
          <Grid item xs={12} mb={6}>
            <Typography
              textAlign="center"
              variant="h5"
              fontWeight={700}
              color={theme => theme.palette.primary.main}
            >
              FORGOT PASSWORD
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={email}
                onChange={e => handleChange(e.target.value)}
                label="Email"
                type="email"
                required
              />
            </FormControl>
          </Grid>
          <Grid item container flexDirection="column" rowGap={0.5}>
            <Grid item xs={12} container>
              {successMsg && (
                <Typography variant="caption" color={theme => theme.palette.success.main}>
                  {successMsg}
                </Typography>
              )}
              <Typography ml={'auto'} pl={1}>
                <Link to={'/login'}>Login?</Link>
              </Typography>
            </Grid>
            <LoadingButton
              loading={loading}
              disabled={!!!email || shouldDisableSubmit}
              variant="contained"
              type="submit"
              fullWidth
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FocusLayout>
  );
};

export default ForgotPassword;
