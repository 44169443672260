import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Company, CompanyType } from 'types';

import Section from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

import UpdateCompanyForm from './UpdateCompanyForm';

const CompanySection: React.FC<{ company: Company }> = ({ company }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);

  return (
    <Grid container direction="column" rowGap={1.5}>
      <Section
        title="Company Details"
        data={[
          {
            label: 'ID',
            value: company.referenceId,
            type: 'STRING',
          },
          {
            label: 'Company Name',
            value: company.name,
            type: 'STRING',
          },
          {
            label: 'Type',
            value: getEnumKeyFromValue(CompanyType, company.type),
            type: 'STRING',
          },
          {
            label: 'Status',
            value: company.status,
            type: 'STRING',
          },
          {
            label: 'Created By',
            value: `${company.createdBy.firstName} ${company.createdBy.lastName ?? ''}`,
            type: 'STRING',
          },
        ]}
        action={<EditNoteIcon onClick={() => toggleFormEdit(true)} sx={{ cursor: 'pointer' }} />}
      />
      <UpdateCompanyForm openForm={enableFormEdit} toggleForm={toggleFormEdit} company={company} />
    </Grid>
  );
};

export default CompanySection;
