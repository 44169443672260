import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { Box, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import React, { useMemo } from 'react';
import { AutoCompleteOption } from 'types';

type AutocompleteWithFetchProps = {
  fetch: LazyQueryExecFunction<any, OperationVariables> | (() => void);
  label: string;
  handleChange: (arg: AutoCompleteOption | null) => void;
  disabled?: boolean;
  loading: boolean;
  variables?: any;
  required?: boolean;
  labelWithId?: boolean;
  size?: 'medium' | 'small';
  error?: boolean;
  noOptionText?: string;
  onBlur?: () => void;
  variant?: TextFieldVariants;
  addNewConfig?: {
    enableAddNew: boolean;
    onClick: () => void;
    addNewLabel?: string;
  };
} & (
  | {
      value: AutoCompleteOption;
      options: AutoCompleteOption[];
    }
  | {
      value: string;
      options: string[];
    }
);

const AutocompleteWithFetch: React.FC<AutocompleteWithFetchProps> = ({
  value,
  label,
  handleChange,
  disabled = false,
  required = false,
  variables = {},
  labelWithId = false,
  error = false,
  onBlur,
  addNewConfig = {
    enableAddNew: false,
    onClick: () => {},
  },
  size = 'medium',
  variant = 'outlined',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const transformedOptions = useMemo(() => {
    let transformed: AutoCompleteOption[] = [];

    if (Array.isArray(props.options) && props.options.length > 0) {
      if (typeof props.options[0] === 'string') {
        transformed = props.options.map(option => ({
          name: option,
          _id: option,
        }));
      } else {
        transformed = props.options as AutoCompleteOption[];
      }
    }

    if (addNewConfig.enableAddNew && !transformed.some(option => option._id === 'add-new')) {
      const addNewLabel = addNewConfig.addNewLabel ?? '+ Add New';
      transformed.push({ name: addNewLabel, _id: 'add-new' });
    }

    return transformed;
  }, [props.options, addNewConfig.enableAddNew, addNewConfig.addNewLabel]);

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          props.fetch({
            variables,
          });
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        disabled={disabled}
        value={typeof value === 'string' ? { _id: value, name: value, referenceId: value } : value}
        getOptionLabel={option => {
          if (!!option && !!!option._id) return '';

          if (labelWithId && !!option.referenceId) {
            return `${option.name} (${option.referenceId})`;
          }
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        includeInputInList
        onChange={(e, val) => {
          if (val && val._id === 'add-new') {
            addNewConfig.onClick();
          } else {
            handleChange(val ? val : { name: '', _id: '', referenceId: '' });
          }
        }}
        options={transformedOptions}
        loading={props.loading}
        noOptionsText={props.noOptionText}
        renderInput={params => (
          <TextField
            required={required}
            variant={variant}
            {...params}
            error={error}
            size={size}
            onBlur={onBlur ? () => onBlur() : undefined}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

type MultiSelectAutocompleteProps = {
  values: AutoCompleteOption[];
  handleChange: (arg: AutoCompleteOption[]) => void;
  fetch: LazyQueryExecFunction<any, OperationVariables> | (() => void);
  variables: Record<string, any>;
  loading: boolean;
  options: AutoCompleteOption[];
  variant?: TextFieldVariants;
  label: string;
  disabled?: boolean;
  required?: boolean;
};

export const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  handleChange,
  values,
  fetch,
  variables,
  loading,
  options,
  label,
  variant = 'outlined',
  disabled = false,
  required = false,
}) => {
  const handleRemoveOption = (optionToRemove: AutoCompleteOption) => {
    const updatedOptions = values.filter(option => option._id !== optionToRemove._id);
    handleChange(updatedOptions);
  };

  const appendOption = (val: AutoCompleteOption | null) => {
    if (!!val?._id) {
      handleChange([...values, val]);
    }
  };

  const filteredOptions = useMemo(() => {
    return options.filter(
      option => !values.some(selectedOption => selectedOption._id === option._id)
    );
  }, [values]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mb: 0.5,
        }}
      >
        {values.map(option => (
          <Chip
            key={option._id}
            label={option.name}
            onDelete={() => handleRemoveOption(option)}
            size="small"
          />
        ))}
      </Box>

      <AutocompleteWithFetch
        fetch={fetch}
        variables={variables}
        handleChange={appendOption}
        label={label}
        loading={loading}
        options={filteredOptions}
        variant={variant}
        disabled={disabled}
        required={required}
        value={{ _id: '', name: '', referenceId: '' }}
      />
    </Box>
  );
};

export default AutocompleteWithFetch;
