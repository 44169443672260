import { gql } from '@apollo/client';
import { City } from 'types'; 

export const GET_LEAD_CITIES_QUERY = gql`
  query Query {
  getLeadCities
}
`;

export type LeadCitiesQueryResponse = {
  getLeadCities: City[]; 
};

export type LeadCitiesQueryVariables = {
  filter: {
    country?: string; 
    state?: string; 
  };
};
