import { useQuery } from '@apollo/client';
import { GET_ME_QUERY, GetMeQueryResponse } from 'graphql/query/get-me';
import { createContext, useContext } from 'react';
import { User } from 'types';

interface UserContextProps {
  user: User | undefined;
  isAuthenticated: boolean;
  retrievingUser: boolean;
  error?: any;
}

const UserContext = createContext<UserContextProps>({
  isAuthenticated: false,
  user: undefined,
  retrievingUser: false,
});

const UserContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { data: user, loading: loadingUser, error } = useQuery<GetMeQueryResponse>(GET_ME_QUERY);

  const isAuthenticated = !!user?.getMe && !!!loadingUser;

  return (
    <UserContext.Provider
      value={{
        user: user?.getMe,
        isAuthenticated,
        error,
        retrievingUser: loadingUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserContextProvider, UserContext, useUser };
