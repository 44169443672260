import { IconProps } from 'types';

const DesignArtifactsicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      fill={color}
      {...attributes}
      viewBox="0 0 79 79"
    >
      <g>
        <path
          d="M36.02 23.795c.397-.396.397-1.038 0-1.433-.394-.396-1.035-.396-1.431 0-.396.395-.396 1.037 0 1.433.396.396 1.037.396 1.432 0zM1.013 51.908h49.832c.41 0 .779-.247.935-.625.157-.379.07-.815-.22-1.104L1.73.347c-.29-.29-.725-.376-1.104-.22C.247.285 0 .655 0 1.064v49.832c0 .56.453 1.013 1.013 1.013zm1.013-48.4l8.147 8.148-2.15 2.148c-.395.395-.395 1.037 0 1.432s1.037.396 1.433 0l2.149-2.148 4.297 4.297-2.149 2.148c-.395.396-.395 1.037 0 1.433.395.395 1.037.396 1.432 0l2.15-2.149 4.296 4.298-2.148 2.148c-.396.396-.396 1.037 0 1.432.396.396 1.037.396 1.432 0l2.149-2.148 4.297 4.297-2.148 2.149c-.396.395-.396 1.037 0 1.432.395.396 1.036.396 1.432 0l2.149-2.148 4.297 4.296-2.149 2.15c-.395.395-.395 1.037 0 1.432.396.395 1.037.395 1.433 0l2.148-2.149 4.297 4.297-2.148 2.15c-.396.395-.396 1.036 0 1.432.395.395 1.037.395 1.433 0l2.148-2.15 8.146 8.147H2.026V3.509z"
          transform="translate(13.52 13.856)"
        />
        <path
          d="M10.44 21.11c-.29-.29-.726-.377-1.104-.22-.379.157-.626.526-.626.936v20.358c0 .56.454 1.013 1.013 1.013h20.36c.41 0 .778-.246.935-.625.157-.378.07-.814-.22-1.104L10.44 21.11zm.296 20.062V24.271l16.901 16.901h-16.9zM31.724 20.93c.396.396 1.037.396 1.433 0 .395-.395.395-1.036 0-1.432l-4.298-4.297c-.395-.396-1.037-.396-1.432 0-.396.395-.396 1.036 0 1.432l4.297 4.297z"
          transform="translate(13.52 13.856)"
        />
        <path
          d="M15.375 10.373l28.43 28.43c1.187 1.188 3.11 1.188 4.298 0l2.864-2.863c1.184-1.185 1.184-3.112 0-4.298l-28.43-28.43c-.111-.112-.247-.196-.396-.245L13.547.102c-.364-.12-.765-.026-1.036.245-.272.271-.366.673-.245 1.037l2.864 8.594c.05.149.133.284.245.395zm34.16 22.701c.395.395.395 1.039 0 1.433l-2.864 2.864c-.396.396-1.037.397-1.433 0l-2.195-2.194 4.297-4.298 2.194 2.195zm-3.627-3.627l-4.298 4.298-1.432-1.433 4.298-4.297 1.432 1.432zM22.49 6.03l20.552 20.552-4.297 4.298-20.552-20.553L22.49 6.03zm-1.54-1.325l-4.082 4.082-2.041-6.122 6.123 2.04z"
          transform="translate(13.52 13.856)"
        />
      </g>
    </svg>
  );
};
export default DesignArtifactsicon;
