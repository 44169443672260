export const rankedFieldsChangeHandler = (
  fieldName: string,
  value: any,
  setFormState: (arg: any) => void,
  ranks: string[]
) => {
  const index = ranks.findIndex(val => val === fieldName);

  if (index > -1) {
    const fieldsToUpdate = {
      [fieldName]: value,
    };

    for (let i = index + 1; i < ranks.length; i++) {
      const currField = ranks[i];
      fieldsToUpdate[currField] = null;
    }

    setFormState(prev => ({
      ...prev,
      ...fieldsToUpdate,
    }));
  }
};
