import React from 'react';
import { IconProps } from 'types';

const MaterialStatusicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      fill={color}
      {...attributes}
      viewBox="0 0 80 79"
    >
      <g>
        <path
          d="M21.242 42.866c-2.142.461-3.795 2.168-4.188 4.323-.393 2.155.55 4.335 2.391 5.522 1.841 1.188 4.216 1.15 6.018-.096 1.802-1.246 2.675-3.455 2.214-5.596-.634-2.922-3.512-4.78-6.435-4.153zm1.92 8.907c-1.46.316-2.968-.28-3.82-1.51-.85-1.23-.876-2.85-.066-4.107.81-1.257 2.298-1.901 3.77-1.633 1.47.268 2.635 1.395 2.95 2.857.428 1.995-.84 3.96-2.833 4.393z"
          transform="translate(10.273 10.608)"
        />
        <path
          d="M58.404 40.391l-.733-3.4c-.048-.224-.183-.418-.374-.542-.192-.124-.425-.166-.648-.118l-3.238.699-5.792-26.862c-.1-.464-.557-.758-1.021-.659l-17.224 3.714-9.638 2.079L16.588.706c-.086-.395-.436-.678-.84-.678H.888C.66.028.44.118.28.28.118.44.028.66.028.888v3.479c0 .475.385.86.86.86h11.357l7.143 33.124c-5.208 1.587-8.27 6.966-6.975 12.255 1.295 5.289 6.496 8.645 11.848 7.646 5.353-1 8.992-6.006 8.292-11.406l25.192-5.433c.464-.1.76-.558.66-1.022zm-33.737-2.224l-3.054-14.154 8.799-1.897 1.291 5.981c.072.337.338.597.676.663.054.01.109.015.164.015.285 0 .552-.141.712-.378l2.312-3.419 3.238 2.193c.29.197.672.197.963 0 .29-.195.433-.548.36-.891l-1.29-5.982 8.798-1.897 4.096 18.991-21.184 4.568c-1.458-1.91-3.538-3.253-5.881-3.793zM35.64 13.63l1.335 6.19 1.02 4.725-2.178-1.474c-.393-.266-.928-.163-1.194.23l-1.664 2.464-2.383-11.043 5.064-1.092zm11.633 3.09l-8.799 1.897-1.154-5.35 8.799-1.897 1.154 5.35zm-18.377-1.636l1.154 5.35-8.799 1.899-1.154-5.351 8.799-1.898zM12.938 3.507H1.748v-1.76h13.306l3.182 14.758 4.617 21.412c-.596-.029-1.193-.005-1.786.07L13.78 4.186c-.086-.396-.436-.679-.84-.679zm11.249 53.006c-3.38.73-6.866-.647-8.834-3.49-1.968-2.842-2.03-6.59-.155-9.497 1.874-2.905 5.314-4.396 8.715-3.775 3.402.62 6.095 3.228 6.823 6.608.988 4.612-1.942 9.154-6.551 10.154h.002zm8.007-11.347c-.176-.57-.4-1.125-.673-1.656l24.653-5.317.372 1.72-24.352 5.253z"
          transform="translate(10.273 10.608)"
        />
      </g>
    </svg>
  );
};
export default MaterialStatusicon;
