import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Branch, Company, CompanyType, Lead } from 'types';

import Section from 'components/Section';

type AssociateCardProps = {
  type: string;
  company: Company;
  branch: Branch;
};

type AssociateCardsSectionProps = {
  lead: Lead;
};

const AssociateCardsSection: React.FC<AssociateCardsSectionProps> = ({ lead }) => {
  const associates = [
    { type: CompanyType.CLIENT, data: lead.company, branch: lead.branch },
    { type: CompanyType.ARCHITECT, data: lead.architect, branch: lead.architectBranch },
    { type: CompanyType.PMC, data: lead.pmc, branch: lead.pmcBranch },
    {
      type: CompanyType['COST CONSULTANT'],
      data: lead.costConsultant,
      branch: lead.costConsultantBranch,
    },
    {
      type: CompanyType['WK CONSULTANT'],
      data: lead.wkConsultant,
      branch: lead.wkConsultantBranch,
    },
    {
      type: CompanyType.PMO,
      data: lead.pmo,
      branch: lead.pmoBranch,
    },
  ];

  lead.others.forEach(o => {
    associates.push({ type: CompanyType.OTHERS, branch: o.branches[0], data: o });
  });

  lead.competitors.forEach(c => {
    associates.push({ type: CompanyType.COMPETITOR, branch: c.branches[0], data: c });
  });

  return (
    <Section title="Associates" collapsible>
      <Grid
        container
        columns={2}
        rowSpacing={2}
        columnSpacing={3}
        alignItems="center"
        minHeight="10vh"
        pb={2}
        pr={1}
        mt={-1}
      >
        {associates.map(
          associate =>
            associate.data && (
              <Grid item key={associate.type} xs={2} md={1} lg={1}>
                <AssociateCard
                  type={associate.type}
                  company={associate.data}
                  branch={associate.branch}
                />
              </Grid>
            )
        )}
        {associates.every(associate => !associate.data) && (
          <Grid item my="auto" mt={2} container justifyContent="center">
            <Typography variant="subtitle2">No Associates have been added yet.</Typography>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};

const AssociateCard: React.FC<AssociateCardProps> = ({ type, company, branch }) => {
  const navigate = useNavigate();

  const isMultipleType = type === CompanyType.OTHERS || type === CompanyType.COMPETITOR;

  return (
    <Section
      title={type}
      variant="outlined"
      onClick={() => (isMultipleType ? navigate(`${type}/${company._id}`) : navigate(type))}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Grid container direction={'column'} rowGap={1.5}>
        <Grid container columns={5} alignItems="center">
          <Grid item xs={2} lg={1}>
            <Typography variant="body1" fontWeight={400}>
              Company
            </Typography>
          </Grid>
          <Grid item xs={3} lg={4}>
            <Typography variant="body2" fontWeight={500}>
              {company.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columns={5} alignItems="center">
          <Grid item xs={2} lg={1}>
            <Typography variant="body1" fontWeight={400}>
              Branch
            </Typography>
          </Grid>
          <Grid item xs={3} lg={4}>
            <Typography variant="body2" fontWeight={500}>
              {branch.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columns={5} alignItems="center">
          <Grid item xs={2} lg={1}>
            <Typography variant="body1" fontWeight={400}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={3} lg={4}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {`${branch.address.addressLine1}, ${branch.address.addressLocation}, ${branch.address.city}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

export default AssociateCardsSection;
