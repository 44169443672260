import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { GET_ME_QUERY, GetMeQueryResponse } from 'graphql/query/get-me';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { User } from 'types';

import FocusLayout from 'layouts/FocusLayout';

import TextField from 'components/Inputs/TextField';

import { storeLoginCredentials } from 'utils/auth';
import { getHomeRoute } from 'utils/common';
import { emailRegex } from 'utils/regexes';

type LoginMutationResponse = {
  login: {
    sessionToken: string;
  };
};

type LoginMutationVariables = {
  email: string;
  password: string;
};

const LOGIN_USER = gql`
  mutation Mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      sessionToken
      message
    }
  }
`;

const LOGIN_WITH_PASSCODE_MUTATION = gql`
  mutation LoginWithPasscode($email: String!, $passcode: String!) {
    loginWithPasscode(email: $email, passcode: $passcode) {
      sessionToken
      message
      error
    }
  }
`;

type LoginWithPasscodeMutationResponse = {
  loginWithPasscode: {
    user: User;
    sessionToken: string;
    message: string;
    error: string;
  };
};

type LoginWithPasscodeMutationVariables = {
  email: string;
  passcode: string;
};

const GENERATE_PASSCODE = gql`
  mutation GeneratePasscode($email: String!) {
    generatePasscode(email: $email) {
      passcodeGeneratedAt
    }
  }
`;

type GeneratePasscodeMutationResponse = {
  generatePasscode: {
    passcodeGeneratedAt;
  };
};

type GeneratePasscodeMutationVariables = {
  email: string;
};

const LoginPage: FC = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [formState, setFormState] = useState({
    email: localStorage.getItem('loginEmail') ?? '',
    password: '',
    passcode: '',
  });

  const [errorMsg, setErrorMsg] = useState<Record<string, string>>({
    password: '',
    passcode: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const [passcodeTimer, setPasscodeTimer] = useState(0);

  const [loginUser, { loading: loggingIn }] = useMutation<
    LoginMutationResponse,
    LoginMutationVariables
  >(LOGIN_USER);

  const [loginWithPasscode, { loading: logginWithPasscode }] = useMutation<
    LoginWithPasscodeMutationResponse,
    LoginWithPasscodeMutationVariables
  >(LOGIN_WITH_PASSCODE_MUTATION);

  const [generatePasscode, { loading: generatingPasscode }] = useMutation<
    GeneratePasscodeMutationResponse,
    GeneratePasscodeMutationVariables
  >(GENERATE_PASSCODE);

  const [getMe, { loading: loadingUser }] = useLazyQuery<GetMeQueryResponse>(GET_ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  const startTimer = () => {
    setPasscodeTimer(30);
    timerRef.current = setInterval(() => {
      setPasscodeTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current as NodeJS.Timeout);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleChange = (fieldName: string, value: any) => {
    setErrorMsg({});
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToken = (email: string, token: string) => {
    localStorage.setItem('loginEmail', email);
    storeLoginCredentials(token);
    getMe({
      onCompleted: usr => {
        const redirectTo = usr.getMe.passwordResetRequired
          ? '/settings'
          : getHomeRoute(usr.getMe.role.name);
        navigate(redirectTo);
      },
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { email, passcode, password } = formState;

    setErrorMsg({});

    if (isPasswordLogin) {
      loginUser({ variables: { email, password } })
        .then(data => {
          const token = data.data?.login?.sessionToken;
          if (token) {
            handleToken(email, token);
          } else setErrorMsg({ password: 'Enter a valid email or password' });
        })
        .catch(error => console.log(error));
    } else {
      loginWithPasscode({
        variables: {
          email,
          passcode,
        },
        onCompleted: res => {
          const token = res.loginWithPasscode.sessionToken;
          if (token) handleToken(email, token);
          else setErrorMsg({ passcode: 'Invalid Passcode' });
        },
      });
    }
  };

  return (
    <FocusLayout>
      <form onSubmit={onSubmit}>
        <Grid container flexDirection="column" gap={2}>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h2" color={theme => theme.palette.primary.main}>
              LOGIN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formState.email}
              onChange={e => handleChange('email', e.target.value)}
              label="Email"
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12}>
            {isPasswordLogin ? (
              <TextField
                value={formState.password}
                onChange={e => handleChange('password', e.target.value)}
                label="Password"
                required
                type={showPassword ? 'text' : 'password'}
                autoComplete=""
                error={!!errorMsg.password}
                helperText={errorMsg.password}
                FormHelperTextProps={{
                  sx: {
                    ml: 0.5,
                    fontSize: 12,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                label="Passcode"
                placeholder="Enter 6-digit passcode"
                value={formState.passcode}
                onChange={e => {
                  const value = e.target.value;
                  if (/^\d{0,6}$/.test(value)) {
                    handleChange('passcode', value);
                    setErrorMsg({
                      passcode: value.length !== 6 ? 'Please enter a 6-digit passcode' : '',
                    });
                  }
                }}
                required
                type="text"
                error={!!errorMsg.passcode}
                helperText={errorMsg.passcode}
                FormHelperTextProps={{
                  sx: {
                    ml: 0.5,
                    fontSize: 12,
                  },
                }}
                inputProps={{
                  maxLength: 6,
                  inputMode: 'numeric',
                  pattern: '\\d{6}',
                }}
              />
            )}
          </Grid>
          <Grid item container flexDirection="column" rowGap={0.5} mt={-1}>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPasswordLogin}
                    onChange={e => setIsPasswordLogin(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Login With Password"
              />
              {isPasswordLogin ? (
                <Typography>
                  <Link to={'/forgot-password'}>Forgot password?</Link>
                </Typography>
              ) : (
                <LoadingButton
                  variant="text"
                  size="small"
                  disabled={
                    !emailRegex.test(formState.email) || generatingPasscode || passcodeTimer > 0
                  }
                  onClick={() =>
                    generatePasscode({
                      variables: { email: formState.email },
                      onCompleted: _ => {
                        if (timerRef.current) clearInterval(timerRef.current);
                        startTimer();
                      },
                    })
                  }
                >
                  Request Passcode
                </LoadingButton>
              )}
              {passcodeTimer > 0 && !isPasswordLogin && (
                <Typography variant="caption" color={'crimson'}>
                  Please wait for {passcodeTimer} seconds to resend passcode
                </Typography>
              )}
            </Grid>
            <LoadingButton
              loading={loggingIn || loadingUser || logginWithPasscode}
              disabled={!!!formState.email || !!errorMsg.passcode || !!errorMsg.password}
              variant="contained"
              type="submit"
              fullWidth
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FocusLayout>
  );
};
export default LoginPage;
