import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Lead, LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PQsTemplate from 'components/PQs';

const GET_PQS_QUERY = gql`
  query GetPQs($filter: LeadFilter) {
    getPQs(filter: $filter) {
      _id
      referenceId
      name
      scope
      segment
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      leadStatus
    }
  }
`;

type GetPQsQueryResponse = {
  getPQs: Lead[];
};

type GetPQsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
    sortOption?: LeadSortOptionEnum;
  };
};

const PQsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const {
    data: leads,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetPQsQueryResponse, GetPQsQueryVariables>(GET_PQS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        leadStatus: [LeadStatusEnum['PQ RECEIVED'], LeadStatusEnum['PQ SUBMITTED']],
      },
    },
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!leads;

  return (
    <Navbar
      title="PQs"
      searchInputConfig={{
        enable: true,
        name: 'PQs search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      {loadingLeads ? <LoadingIndicator size="1.6rem" /> : <PQsTemplate leads={leads.getPQs} />}
    </Navbar>
  );
};

export default PQsPage;
