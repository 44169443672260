import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import {
  LeadAssociateMuationResponse,
  LeadAssociateMuationVariables,
  UPDATE_LEAD_ASSOCIATE_MUTATION,
} from 'graphql/mutation/associate';
import {
  CREATE_LEAD_PEOPLE_MUTATION,
  CreateLeadPeopleResponse,
  CreateLeadPeopleVariables,
} from 'graphql/mutation/leadPeople';
import {
  BranchesQueryResponse,
  BranchesQueryVariables,
  CompaniesQueryResponse,
  CompaniesQueryVariables,
  GET_BRANCHES_QUERY,
  GET_COMPANIES_QUERY,
} from 'graphql/query/company';
import { GET_PEOPLES_QUERY, PeopleQueryResponse, PeopleQueryVariables } from 'graphql/query/people';
import React, { useState } from 'react';
import { Company, CompanyStatus, CompanyType, Lead, People } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { MultiSelect } from 'components/Inputs/Select';
import { SimplePopup } from 'components/Popup';

import { removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';

type Props = {
  type: CompanyType;
  lead: Lead;
  cb?: (arg: any) => void;
};

const AssociatedCompanyButton: React.FC<Props> = ({ lead, type }) => {
  const [openForm, toggleForm] = useState(false);
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [getCompanies, { loading: loadingCompanies, data: companies }] = useLazyQuery<
    CompaniesQueryResponse,
    CompaniesQueryVariables
  >(GET_COMPANIES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getBranches, { loading: loadingBranches, data: branches }] = useLazyQuery<
    BranchesQueryResponse,
    BranchesQueryVariables
  >(GET_BRANCHES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getPeople, { loading: loadingPeople, data: people }] = useLazyQuery<
    PeopleQueryResponse,
    PeopleQueryVariables
  >(GET_PEOPLES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [updateLead, { loading: updatingLead }] = useMutation<
    LeadAssociateMuationResponse,
    LeadAssociateMuationVariables
  >(UPDATE_LEAD_ASSOCIATE_MUTATION);

  const [createLeadPeople, { loading: creatingLeadPeople }] = useMutation<
    CreateLeadPeopleResponse,
    CreateLeadPeopleVariables
  >(CREATE_LEAD_PEOPLE_MUTATION);

  const rankedFields = ['company', 'branch', 'people'];

  const isMultipleType = type === CompanyType.OTHERS || type === CompanyType.COMPETITOR;

  const { branchFieldName, companyFieldName, companyLabel } = getCompanyAndBranchFieldName(type);

  const transformPeopleToOptions = (people: People[]) => {
    return people.map(p => ({ label: `${p.name} (${p.mobile})`, value: p._id }));
  };

  const getIdsFromCompanyArr = (companies: Company[]) => {
    return companies.map(c => c._id);
  };

  const handleSubmit = async () => {
    const refinedFields = removeEmptyFields(formState);
    const companyId = refinedFields.company;
    const branchId = refinedFields.branch;

    updateLead({
      variables: {
        input: !isMultipleType
          ? {
              _id: lead._id,
              [companyFieldName]: companyId,
              [branchFieldName]: branchId,
            }
          : {
              _id: lead._id,
              [companyFieldName]: !!lead[companyFieldName]
                ? [...getIdsFromCompanyArr(lead[companyFieldName]), companyId]
                : [companyId],
            },
      },
      onCompleted: _ => {
        if (isMultipleType) {
          toggleForm(false);
        } else {
          createLeadPeople({
            variables: {
              input: {
                type,
                lead: lead._id,
                people: refinedFields.people,
              },
            },
            onCompleted: _ => toggleForm(false),
          });
        }
      },
    });
  };

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={() => toggleForm(true)}>
        Add {companyLabel}
      </Button>
      <SimplePopup
        onClose={() => {
          toggleForm(false);
          setFormState({});
        }}
        open={openForm}
        fullWidth
        title={`Add ${companyLabel}`}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container columnSpacing={2} rowGap={2} px={2} py={1.5}>
            <Grid item xs={!isMultipleType ? 6 : 12}>
              <AutocompleteWithFetch
                value={formState.company ?? { _id: '', name: '', referenceId: '' }}
                handleChange={val =>
                  rankedFieldsChangeHandler('company', val, setFormState, rankedFields)
                }
                label={companyLabel}
                fetch={getCompanies}
                loading={loadingCompanies}
                options={companies?.getCompanies ?? []}
                required
                variables={{
                  filter: {
                    status: [CompanyStatus.ACTIVE],
                    type: [type],
                  },
                }}
              />
            </Grid>
            {!isMultipleType && (
              <Grid item xs={6}>
                <AutocompleteWithFetch
                  options={branches?.getBranches ?? []}
                  value={formState.branch ?? { _id: '', name: '', referenceId: '' }}
                  handleChange={val =>
                    rankedFieldsChangeHandler('branch', val, setFormState, rankedFields)
                  }
                  noOptionText={`No ${companyLabel} Available`}
                  loading={loadingBranches}
                  fetch={getBranches}
                  label="Branch"
                  disabled={!!!formState?.company?._id}
                  required={!!formState?.company?._id}
                  variables={{
                    filter: {
                      company: formState.company?._id ?? '',
                      status: [CompanyStatus.ACTIVE],
                    },
                  }}
                />
              </Grid>
            )}
            {!isMultipleType && (
              <Grid item xs={12}>
                <MultiSelect
                  label="People"
                  value={formState.people ?? []}
                  required
                  handleChange={val =>
                    rankedFieldsChangeHandler('people', val, setFormState, rankedFields)
                  }
                  disabled={!!!formState.company?._id || !!!formState.branch?._id}
                  options={!!people?.getPeople ? transformPeopleToOptions(people.getPeople) : []}
                  fetchConfig={{
                    fetchFn: getPeople,
                    loading: loadingPeople,
                    variables: {
                      filter: {
                        branch: formState.branch?._id,
                      },
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} container justifyContent="end">
              <LoadingButton
                variant="contained"
                type="submit"
                loading={creatingLeadPeople || updatingLead}
              >
                Add
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </SimplePopup>
    </>
  );
};

export const getCompanyAndBranchFieldName = (type: CompanyType) => {
  let companyLabel: string;

  let companyFieldName: string;
  let branchFieldName: string;

  switch (type) {
    case CompanyType.CLIENT:
      companyFieldName = 'company';
      branchFieldName = 'branch';
      companyLabel = 'Company';
      break;
    case CompanyType.ARCHITECT:
      companyFieldName = 'architect';
      branchFieldName = 'architectBranch';
      companyLabel = 'Architect';
      break;
    case CompanyType.PMC:
      companyFieldName = 'pmc';
      branchFieldName = 'pmcBranch';
      companyLabel = 'PMC';
      break;
    case CompanyType.OTHERS:
      companyFieldName = 'others';
      branchFieldName = '';
      companyLabel = 'Others';
      break;
    case CompanyType['COST CONSULTANT']:
      companyFieldName = 'costConsultant';
      branchFieldName = 'costConsultantBranch';
      companyLabel = 'Cost Consultant';
      break;
    case CompanyType['WK CONSULTANT']:
      companyFieldName = 'wkConsultant';
      branchFieldName = 'wkConsultantBranch';
      companyLabel = 'Wk Consultant';
      break;
    case CompanyType.PMO:
      companyFieldName = 'pmo';
      branchFieldName = 'pmoBranch';
      companyLabel = 'PMO';
      break;
    case CompanyType.COMPETITOR:
      companyFieldName = 'competitors';
      branchFieldName = '';
      companyLabel = 'Competitor';
      break;
    default:
      companyLabel = '';
      branchFieldName = '';
      companyFieldName = '';
      break;
  }

  return { companyFieldName, branchFieldName, companyLabel };
};

export default AssociatedCompanyButton;
