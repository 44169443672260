import { NetworkStatus, gql, useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import React, { useDeferredValue, useState } from 'react';
import { DocumentMaster, DocumentMasterVersionEnum, DocumentTypeEnum, FinancialYears } from 'types';

import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

import debounce from 'utils/debounce';

import { DocumentMasterFormProps } from '../CreateDocumentTemplate';

const debouncedOnNameChange = debounce((newInput: any, callback: (arg: any) => any) => {
  callback(newInput);
}, 500);

const GET_DOCUMENTS_QUERY = gql`
  query GetDocumentMasters($filter: DocumentMasterFilter) {
    getDocumentMasters(filter: $filter) {
      documents {
        _id
        name
      }
    }
  }
`;

type GetDocumentMastersResponse = {
  getDocumentMasters: {
    documents: DocumentMaster[];
  };
};

type GetDocumentMastersVariables = {
  filter: {
    type?: DocumentTypeEnum[];
    financialYear?: string[];
    version?: string;
    searchTerm?: string;
  };
};

type DocumentFormProps = {
  data: DocumentMasterFormProps;
  cb: (arg: DocumentMasterFormProps) => void;
  loading?: boolean;
  disabled?: boolean;
};

// tempered with it after adding DocumentFile type
const DocumentForm: React.FC<DocumentFormProps> = ({
  data,
  loading = false,
  disabled = false,
  cb,
}) => {
  const [formState, setFormState] = useState(data);

  const [fetchExistingDocs, { data: docs, networkStatus: documentNetworkStatus }] = useLazyQuery<
    GetDocumentMastersResponse,
    GetDocumentMastersVariables
  >(GET_DOCUMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const existingDocs = useDeferredValue(docs);

  const loadingExistingDocuments =
    documentNetworkStatus === NetworkStatus.setVariables ||
    documentNetworkStatus === NetworkStatus.loading;

  const handleChange = (fieldName: string, val: string) => {
    const newObj: Record<string, any> = {};

    if (fieldName === 'type' || fieldName === 'financialYear') {
      newObj[fieldName] = [val];
    } else if (fieldName === 'version') {
      newObj[fieldName] = val;
    } else if (fieldName === 'name') {
      newObj.searchTerm = val;
    }

    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));

    if (
      fieldName === 'name' ||
      fieldName === 'version' ||
      fieldName === 'type' ||
      fieldName === 'financialYear'
    ) {
      debouncedOnNameChange(
        {
          filter: {
            searchTerm: formState.name,
            version: formState.version,
            financialYear: !!formState.financialYear ? [formState.financialYear] : undefined,
            type: !!formState.type ? [formState.type] : undefined,
            ...newObj,
          },
        },
        arg =>
          fetchExistingDocs({
            variables: arg,
          })
      );
    }
  };

  const shouldDisableName = !!!formState.type;
  const documentNameAlreadyExists = !!existingDocs?.getDocumentMasters?.documents?.length
    ? existingDocs.getDocumentMasters.documents.some(doc => doc.name === formState.name)
    : false;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        cb(formState);
      }}
    >
      <Grid container item columnSpacing={1.5} rowGap={2} mt={1}>
        <Grid item xs={12}>
          <Select
            label="Type"
            options={Object.keys(DocumentTypeEnum).map(k => ({
              label: k,
              value: DocumentTypeEnum[k],
            }))}
            required
            disabled={disabled}
            value={formState.type}
            onChange={val => handleChange('type', val)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Financial Year"
            options={Object.keys(FinancialYears).map(k => ({
              label: k,
              value: FinancialYears[k],
            }))}
            disabled={disabled}
            value={formState.financialYear ?? ''}
            onChange={val => handleChange('financialYear', val)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Version"
            options={Object.keys(DocumentMasterVersionEnum).map(k => ({
              label: k,
              value: DocumentMasterVersionEnum[k],
            }))}
            disabled={disabled}
            value={formState.version ?? ''}
            onChange={val => handleChange('version', val)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formState.name ?? ''}
            onChange={e => !shouldDisableName && handleChange('name', e.target.value)}
            label="Name"
            disabled={disabled}
            loading={loadingExistingDocuments}
            error={!!formState.name && documentNameAlreadyExists}
            helperText={
              !!formState.name && documentNameAlreadyExists
                ? 'File with this name already exists'
                : undefined
            }
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
            sx={{
              pointerEvents: shouldDisableName ? 'none' : undefined,
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={2}
            size="small"
            value={formState.comments ?? ''}
            onChange={e => handleChange('comments', e.target.value)}
            label="Comments"
          />
        </Grid>
        <Grid item xs={12}>
          {disabled ? (
            <Typography
              variant="body1"
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: 4,
              }}
              onClick={() =>
                window.open(
                  formState.file instanceof File
                    ? URL.createObjectURL(formState.file)
                    : formState.file.path,
                  '_blank'
                )
              }
            >
              {formState.file.name}
            </Typography>
          ) : (
            // <UploadFile
            //   values={formState.file ?? ''}
            //   onChange={val => handleChange('file', val)}
            //   label="Upload File *"
            //   required={!!!formState.file}
            //   accept="*"
            // />
            <></>
          )}
        </Grid>
        {!disabled && (
          <Grid item xs={12} container alignItems="center" columnGap={2}>
            <Button sx={{ ml: 'auto' }} variant="outlined" onClick={() => setFormState(data)}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={documentNameAlreadyExists || loadingExistingDocuments}
            >
              Save
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default DocumentForm;
