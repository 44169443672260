import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CardActions, Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { People } from 'types';

import theme from './theme.module.scss';

const PeopleActions: React.FC<{ people: People }> = ({ people }) => {
  return (
    <CardActions
      sx={{
        p: 0,
        mx: -3,
        mb: -3,
        pt: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      disableSpacing
    >
      <Grid container justifyContent="space-between" alignItems="center" px={1}>
        <div
          className={theme.actionButton}
          onClick={() => {
            if (people.mobile) {
              window.location.href = `tel:${people.mobile}`;
            }
          }}
        >
          <PhoneIcon color={people.mobile ? 'info' : 'disabled'} />
        </div>

        <div
          className={theme.actionButton}
          onClick={() => {
            if (people.email) {
              window.location.href = `mailto:${people.email}`;
            }
          }}
        >
          <MailOutlineIcon color={people.email ? 'error' : 'disabled'} />
        </div>

        <div
          className={classNames(theme.actionButton, theme.lastActionButton)}
          onClick={() => {
            if (people.mobile) {
              window.open(`https://wa.me/${people.mobile}`, '_blank');
            }
          }}
        >
          <WhatsAppIcon color={people.mobile ? 'success' : 'disabled'} />
        </div>
      </Grid>
    </CardActions>
  );
};

export default PeopleActions;
