import { Chip, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadStatusEnum } from 'types';

import LeadCard from 'components/Card/LeadCard';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

enum LeadScopeEnum {
  'GC' = 'GC',
  'C&I' = 'C_AND_I',
  STRUCTURE = 'STRUCTURE',
  'MEP' = 'MEP',
}
const PQsTemplate: React.FC<{ leads: Lead[] }> = ({ leads }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      columns={3}
      rowSpacing={3}
      columnSpacing={5}
      alignItems="center"
      mb={5}
      pt={1.5}
    >
      {leads.map(lead => (
        <Grid item key={lead.referenceId} width={'100%'} md={1.5} lg={1}>
          <LeadCard
            onClick={() => navigate(`${lead._id}`)}
            segment={lead.segment}
            title={lead.name}
          >
            <Grid mt={1} container columns={3} alignItems="end">
              <Grid item color="gray" maxWidth="70%" container direction="column" rowSpacing={0.5}>
                <Typography variant="caption" fontWeight={700}>
                  {getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus)}
                </Typography>
                {lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] && lead.pqReceivedDate ? (
                  <Typography variant="caption" fontWeight={600}>
                    {getDate(lead.pqReceivedDate)}
                  </Typography>
                ) : (
                  ''
                )}

                {lead.leadStatus === LeadStatusEnum['PQ SUBMITTED'] && lead.pqSubmittedDate ? (
                  <Typography variant="caption" fontWeight={600}>
                    {getDate(lead.pqSubmittedDate)}
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid
              sx={{
                position: 'absolute',
                right: 10,
                bottom: 20,
              }}
            >
              <Grid container justifyContent="center" alignContent="center" rowGap={0.5}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{
                      color: theme => theme.palette.primary.light,
                      WebkitTextStroke: '0.5px black',
                    }}
                  >
                    ₹{lead.approxValue} Cr
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignContent="center" justifyContent="center" spacing={1}>
                    <Chip
                      label={getEnumKeyFromValue(LeadScopeEnum, lead.scope)}
                      variant="outlined"
                      color="secondary"
                      sx={{
                        fontSize: '10px',
                        height: '20px',
                        borderWidth: '1.5px',
                        '.MuiChip-label': {
                          fontWeight: 600,
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </LeadCard>
        </Grid>
      ))}
      {leads.length === 0 && (
        <Grid item minHeight="20vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="subtitle1" fontWeight={500}>
            No PQs to show
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PQsTemplate;
