import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DocumentFile } from 'types';

import FileDetailsSection from 'components/DocumentMaster/Sections/FileDetails';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_FILE_QUERY = gql`
  query GetFileById($id: ID!) {
    getFileById(_id: $id) {
      _id
      referenceId
      name
      path
      metadata
      project {
        _id
        name
      }
      lead {
        _id
        name
      }
      createdBy {
        _id
        empId
        firstName
        lastName
      }
    }
  }
`;

type GetFileQueryResponse = {
  getFileById: DocumentFile;
};

type GetFileQueryVariables = {
  id: string;
};

const DocumentsFileDetailsPage = () => {
  const { fileId = '' } = useParams<{ fileId: string }>();

  const { data: file, loading: loadingFile } = useQuery<
    GetFileQueryResponse,
    GetFileQueryVariables
  >(GET_FILE_QUERY, {
    variables: {
      id: fileId,
    },
  });

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'File Details',
      }}
    >
      {loadingFile || !!!file ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid mb={4} mt={2} container lg={9} xl={7}>
          <FileDetailsSection file={file.getFileById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default DocumentsFileDetailsPage;
