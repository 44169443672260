import EditNoteIcon from '@mui/icons-material/EditNote';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentFile, FolderTypeEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import StaticFileForm from '../Forms/StaticFileForm';

const StaticFileDetails: React.FC<{ file: DocumentFile }> = ({ file }) => {
  const [enableEditForm, toggleEditForm] = useState(false);

  const data: SectionDataProps[] = [
    {
      label: 'Name',
      value: file.name,
      type: 'EXTERNAL_LINK',
      navigateTo: file.path,
    },
  ];

  Object.keys(file.metadata ?? {}).forEach(k =>
    data.push({
      label: k,
      value: file.metadata[k],
      type: 'STRING',
    })
  );

  data.push({
    label: 'Created By',
    value: file.createdBy.firstName + ' ' + file.createdBy.lastName ?? '',
  });

  return (
    <>
      <Section
        title={file.name}
        data={data}
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update File"
        fullWidth
      >
        <StaticFileForm
          initialData={{
            _id: file._id,
            file: file,
            metadata: Object.keys(file.metadata ?? {}).map(k => ({
              key: k,
              value: file.metadata[k],
            })),
            fileName: file.name,
            path: file.path,
          }}
          cb={() => toggleEditForm(false)}
        />
      </SimplePopup>
    </>
  );
};

export default StaticFileDetails;
