import { gql } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { DocumentMaster, DocumentTypeEnum } from 'types';

export const DOCUMENT_PAGINATION_LIMIT = 25;

export const GET_DOCUMENTS_QUERY = gql`
  query GetDocumentMasters($filter: DocumentMasterFilter, $limit: Int, $page: Int) {
    getDocumentMasters(filter: $filter, limit: $limit, page: $page) {
      documents {
        _id
        referenceId
        type
        name
        version
        comments
        financialYear
        path
        status
        createdBy {
          _id
          empId
          firstName
          lastName
        }
      }
      totalCount
      totalPages
      currentPage
    }
  }
`;

export type DocumentMasterFiltersProps = {
  type?: DocumentTypeEnum[];
  financialYear?: string[];
  version?: string;
  searchTerm?: string;
};

export type GetDocumentMastersQueryVariables = {
  filter?: DocumentMasterFiltersProps;
  limit?: number;
  page?: number;
};

export type GetDocumentMastersQueryResponse = {
  getDocumentMasters: {
    documents: DocumentMaster[];
    totalPages: number;
    totalCount: number;
    currentPage: number;
  };
};

type DocumentsMasterProps = {
  filters: DocumentMasterFiltersProps;
  setFilters: (arg: DocumentMasterFiltersProps) => void;
  resetFilters: () => void;
  pagination: {
    currPage: number;
    setCurrPage: (arg: number) => void;
  };
};

const DocumentsMasterContext = React.createContext<DocumentsMasterProps>({
  filters: {},
  setFilters: () => {},
  resetFilters: () => {},
  pagination: {
    currPage: 1,
    setCurrPage: () => {},
  },
});

export const DocumentsMasterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState<DocumentMasterFiltersProps>({});
  const [currPage, setCurrPage] = useState<number>(1);

  const resetFilters = () => {
    setFilters({});
    setCurrPage(1);
  };

  return (
    <DocumentsMasterContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        pagination: { currPage, setCurrPage },
      }}
    >
      {children}
    </DocumentsMasterContext.Provider>
  );
};

export const useDocumentMaster = () => {
  const context = useContext(DocumentsMasterContext);

  if (context === null) {
    throw new Error('useDocumentMaster must be used within a DocumentMasterProvider');
  }
  return context;
};
