import { gql } from '@apollo/client';
import { Folder } from 'types';

export const CREATE_STATIC_SUB_FOLDER_MUTATION = gql`
  mutation CreateStaticFolder($input: CreateStaticFolderInput!) {
    createStaticFolder(input: $input) {
      _id
      referenceId
      name
      type
      isStandardFolder
      fileCount
      parentFolder {
        _id
        name
      }
      project {
        _id
        name
        referenceId
        lead {
          _id
          referenceId
          name
        }
      }
    }
  }
`;

export type CreateStaticSubFolderMutationResponse = {
  createStaticFolder: Folder;
};

export type CreateStaticSubFolderMutationVariables = {
  input: {
    name: string;
    parentFolder?: string;
  };
};
