import { Box, Divider, Grid } from '@mui/material';
import { Folder } from 'types';

import FolderCard from './Card/FolderCard';

export const DocumentFoldersGrid = ({ folders }: { folders: Folder[] }) => {
  const nonEmptyFolders = folders.filter(folder => (folder.fileCount ?? 0) > 0);
  const emptyFolders = folders.filter(folder => (folder.fileCount ?? 0) === 0);

  return (
    <Box py={1}>
      <Grid container columnSpacing={2.5} rowSpacing={3} mb={2}>
        {nonEmptyFolders.map(folder => (
          <Grid item key={folder._id} xs={6} md={4} lg={2} xl={2} px={1}>
            <FolderCard folder={folder} />
          </Grid>
        ))}
      </Grid>

      {nonEmptyFolders.length > 0 && emptyFolders.length > 0 && <Divider sx={{ my: 3 }} />}

      <Grid container columnSpacing={2.5} rowSpacing={3}>
        {emptyFolders.map(folder => (
          <Grid item key={folder._id} xs={6} md={4} lg={2} xl={2} px={1}>
            <FolderCard folder={folder} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DocumentFoldersGrid;
