import { Chip, Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadStatusEnum } from 'types';

import LeadCard from 'components/Card/LeadCard';

import { fixToTwoLocalPrice } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

type LeadCardProps = {
  leads: Lead[];
};

enum LeadScopeEnum {
  'GC' = 'GC',
  'C&I' = 'C_AND_I',
  STRUCTURE = 'STRUCTURE',
  'MEP' = 'MEP',
}

const LeadsTemplate: React.FC<LeadCardProps> = ({ leads }) => {
  const navigate = useNavigate();
  const leadCustomCSS = (lead: Lead) =>
    ({
      backgroundColor: (theme: Theme) => {
        if (lead.probability < 30) {
          if (lead.leadStatus === LeadStatusEnum.LOST) return theme.palette.funnelState.lost;
          return theme.palette.funnelState.cold;
        } else if (lead.probability < 60) {
          return theme.palette.funnelState.warm;
        } else {
          return theme.palette.funnelState.hot;
        }
      },
    } as SxProps<Theme>);

  return (
    <Grid container rowSpacing={3} columnSpacing={4} alignItems="center" mb={5} pr={2} pt={1.5}>
      {leads.map(lead => (
        <Grid item xs={12} md={6} lg={4} key={lead._id}>
          <LeadCard
            title={lead.name}
            segment={lead.segment}
            sx={leadCustomCSS(lead)}
            onClick={() => navigate(`/leads/${lead._id}`)}
          >
            <Grid mt={1} container columns={3} alignItems="end">
              <Grid item color="gray" maxWidth="70%" container direction="column" rowSpacing={0.5}>
                <Typography variant="caption" fontWeight={700}>
                  {getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus)}
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  {fixToTwoLocalPrice(lead.areaOrPower)} {lead.areaOrPowerUOM}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  position: 'absolute',
                  right: 10,
                  bottom: 20,
                }}
              >
                <Grid container justifyContent="center" alignContent="center" rowGap={0.5}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{
                        color: theme => theme.palette.primary.light,
                        WebkitTextStroke: '0.5px black',
                      }}
                    >
                      ₹{lead.approxValue} Cr
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignContent="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Chip
                        label={getEnumKeyFromValue(LeadScopeEnum, lead.scope)}
                        variant="outlined"
                        color="secondary"
                        sx={{
                          fontSize: '10px',
                          height: '20px',
                          borderWidth: '1.5px',
                          '.MuiChip-label': {
                            fontWeight: 600,
                          },
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LeadCard>
        </Grid>
      ))}
      {leads.length === 0 && (
        <Grid item minHeight="20vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="subtitle1" fontWeight={500}>
            No leads to show
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LeadsTemplate;
