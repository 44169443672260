import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CompanyType, Lead } from 'types';

import AssociateSection from 'components/Leads/Associates/AssociateSection';
import LeadPeopleSection from 'components/Leads/Associates/LeadPeopleSection';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_LEAD_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      referenceId
      name
      branch {
        _id
        referenceId
        name
      }
      company {
        _id
        referenceId
        name
      }
      architect {
        _id
        referenceId
        name
      }
      architectBranch {
        _id
        referenceId
        name
      }
      pmc {
        _id
        referenceId
        name
      }
      pmcBranch {
        _id
        referenceId
        name
      }
      pmo {
        _id
        referenceId
        name
      }
      pmoBranch {
        _id
        referenceId
        name
      }
      costConsultant {
        _id
        referenceId
        name
      }
      costConsultantBranch {
        _id
        referenceId
        name
      }
      wkConsultant {
        _id
        referenceId
        name
      }
      wkConsultantBranch {
        _id
        referenceId
        name
      }
      others {
        _id
        referenceId
        branches {
          _id
          name
        }
        name
      }
      competitors {
        _id
        referenceId
        branches {
          _id
          name
        }
        name
      }
    }
  }
`;

type LeadQueryResponse = {
  getLeadById: Lead;
};

type LeadQueryVariables = {
  id: string;
};

const LeadAssociateDetails = () => {
  const { leadId = '', companyType } = useParams<{
    companyType: CompanyType;
    leadId: string;
  }>();

  const { data: lead, loading: loadingLead } = useQuery<LeadQueryResponse, LeadQueryVariables>(
    GET_LEAD_QUERY,
    {
      variables: {
        id: leadId,
      },
    }
  );

  const isMultipleCompanyType =
    companyType === CompanyType.OTHERS || companyType === CompanyType.COMPETITOR;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Associate Details',
      }}
    >
      {loadingLead || !!!lead ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" md={11} lg={9} mb={7} rowGap={2.5}>
          <AssociateSection lead={lead.getLeadById} />
          {!!lead.getLeadById && !isMultipleCompanyType && (
            <LeadPeopleSection lead={lead.getLeadById} />
          )}
        </Grid>
      )}
    </Navbar>
  );
};

export default LeadAssociateDetails;
