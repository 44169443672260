import EditNoteIcon from '@mui/icons-material/EditNote';
import { client } from 'graphql/client';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyType, Lead } from 'types';

import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import { getCompanyAndBranchFieldName } from '../AssociateButton';
import { LEAD_PEOPLE_QUERY } from '../LeadPeopleSection';
import UpdateAssociateForm from './UpdateAssociateForm';

const AssociateSection: React.FC<{ lead: Lead }> = ({ lead }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);

  const {
    leadId = '',
    companyType = CompanyType.CLIENT,
    companyId = '',
  } = useParams<{
    companyType: CompanyType;
    companyId?: string;
    leadId: string;
  }>();

  const company = getCompanyAndBranchFieldName(companyType);

  const getCompanyDetails = () => {
    if (company.companyFieldName === 'others' || company.companyFieldName === 'competitors') {
      return lead[company.companyFieldName].find(val => val._id === companyId);
    }
    return lead[company.companyFieldName];
  };

  return (
    <>
      <Section
        title={company.companyLabel + ' Details'}
        data={[
          {
            label: 'Company Name',
            value: getCompanyDetails().name,
            type: 'STRING',
          },
          {
            label: 'Branch Name',
            value: !!!companyId
              ? lead[company.branchFieldName].name
              : getCompanyDetails().branches[0].name,
            type: 'STRING',
          },
        ]}
        action={<EditNoteIcon onClick={() => toggleFormEdit(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleFormEdit(false)}
        open={enableFormEdit}
        title="Update Associate"
        fullWidth
      >
        <UpdateAssociateForm
          initialData={lead}
          cb={() => {
            deleteLeadPeopleCache(leadId, companyType);
            toggleFormEdit(false);
          }}
        />
      </SimplePopup>
    </>
  );
};

const deleteLeadPeopleCache = (leadId: string, companyType: CompanyType) => {
  client.writeQuery({
    query: LEAD_PEOPLE_QUERY,
    variables: {
      filter: {
        lead: leadId,
        type: companyType,
      },
    },
    data: {
      getLeadPeople: [],
    },
  });
};

export default AssociateSection;
