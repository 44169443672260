import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { IconButton, MenuItem, Select, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { DocumentFile } from 'types';

const CopyStaticFiles: React.FC<{
  options: { label: string; value: any }[];
  rows: DocumentFile[];
  loading: boolean;
  selectedRows: string[];
  setSelectedRows: (arg: string[]) => void;
  rowOptions: Record<string, any>;
  setRowOptions: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  cb: (args: { srcFileId: string; destFolderId: string }[]) => void;
}> = ({ options, rows, cb, loading, selectedRows, setSelectedRows, rowOptions, setRowOptions }) => {
  const handleDownload = (record: DocumentFile) => {
    window.open(record.path, '_blank');
  };

  const handleSelectOption = (id: string, folderId: string) => {
    setRowOptions(prev => ({ ...prev, [id]: folderId }));
  };

  const handleSubmit = () => {
    const selectedData = selectedRows.map(id => ({
      srcFileId: id,
      destFolderId: rowOptions[id] || options[0].value,
    }));
    cb(selectedData);
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },
    {
      field: 'shortPath',
      headerName: 'Path',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="caption" color={'gray'} fontWeight={600}>
          {(params.row.shortPath as string).slice(1).split('/').slice(0, -1).join('/')}
        </Typography>
      ),
    },
    {
      field: 'folderId',
      headerName: 'Sub Folders',
      width: 150,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Select
          value={rowOptions[params.row._id] || options[0].value}
          onChange={e => handleSelectOption(params.row._id as string, e.target.value)}
          displayEmpty
          disabled={options.length === 1}
          sx={{ width: '100%' }}
          size="small"
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'download',
      headerName: 'Download',
      width: 100,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={() => handleDownload(params.row as any)}>
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        hideFooterSelectedRowCount
        sx={{ border: 0, minHeight: '30vh' }}
        pageSizeOptions={[50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 50, page: 0 } } }}
        getRowId={row => row._id}
        onRowSelectionModelChange={newSelection => {
          setSelectedRows(newSelection as string[]);
        }}
        rowSelectionModel={selectedRows.map(val => val)}
      />
      <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>
        Add Files
      </LoadingButton>
    </>
  );
};

export default CopyStaticFiles;
