import { useLazyQuery, useMutation } from '@apollo/client';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import {
  CREATE_ADDRESS_MUTATION,
  CREATE_BRANCH_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
  CreateBranchMutationResponse,
  CreateBranchMutationVariables,
} from 'graphql/mutation/branch';
import {
  PINCODES_QUERY,
  PincodesQueryResponse,
  PincodesQueryVariables,
} from 'graphql/query/pincode';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Branch, BranchStatusEnum, BranchTypeEnum } from 'types';

import { Select } from 'components/Inputs/Select';
import TextField, { ContactTextField } from 'components/Inputs/TextField';
import PincodeInput from 'components/Inputs/TextField/PincodeInput';

import { removeEmptyFields } from 'utils/common';

type BranchFormProps = {
  toggleForm: (arg: boolean) => void;
  cb: (arg: Branch) => void;
};

const BranchForm: React.FC<BranchFormProps> = ({ toggleForm, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [createBranch, { loading: creatingBranch }] = useMutation<
    CreateBranchMutationResponse,
    CreateBranchMutationVariables
  >(CREATE_BRANCH_MUTATION);

  const [getPincodes, { loading: loadingPincodes }] = useLazyQuery<
    PincodesQueryResponse,
    PincodesQueryVariables
  >(PINCODES_QUERY);

  const { companyId = '' } = useParams<{ companyId: string }>();

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleAddressChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      address: {
        ...(prev?.address ?? {}),
        [fieldName]: val,
      },
    }));
  };

  const handleSubmit = () => {
    const formData = { ...formState };
    const refinedAddressData = removeEmptyFields({ ...formData.address });

    delete formData.address;
    const refinedBranchData = removeEmptyFields({ ...formState });

    createAddress({
      variables: {
        input: refinedAddressData,
      },
      onCompleted: addressRes => {
        createBranch({
          variables: {
            input: {
              ...refinedBranchData,
              address: addressRes.createAddress._id,
              company: companyId,
              type: BranchTypeEnum.BRH,
            },
          },
          onCompleted: newBranch => {
            cb(newBranch.createBranch);
            toggleForm(false);
          },
        });
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={6}>
          <TextField
            label="Name"
            value={formState.name ?? ''}
            onChange={e => handleChange('name', e.target.value)}
            required
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            options={Object.values(BranchStatusEnum).map(o => ({ label: o, value: o }))}
            value={formState.status ?? ''}
            onChange={val => handleChange('status', val)}
            label="Status"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            value={formState.email ?? ''}
            onChange={e => handleChange('email', e.target.value)}
            required
            type="email"
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Mobile 1"
            onChange={e => handleChange('mobile1', e.target.value)}
            value={formState?.mobile1 ?? ''}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Mobile 2"
            onChange={e => handleChange('mobile2', e.target.value)}
            value={formState?.mobile2 ?? ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 1"
            value={formState?.landline1 ?? ''}
            onChange={e => handleChange('landline1', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 2"
            value={formState?.landline2 ?? ''}
            onChange={e => handleChange('landline2', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 3"
            value={formState?.landline3 ?? ''}
            onChange={e => handleChange('landline3', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <Typography variant="overline" fontWeight={600}>
              Address Details
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            value={formState.address?.addressLine1 ?? ''}
            onChange={e => handleAddressChange('addressLine1', e.target.value)}
            type="text"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Address Line 2"
            value={formState.address?.addressLine2 ?? ''}
            onChange={e => handleAddressChange('addressLine2', e.target.value)}
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Location"
            value={formState.address?.addressLocation ?? ''}
            onChange={e => handleAddressChange('addressLocation', e.target.value)}
            required
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <PincodeInput
            formState={formState?.address ?? {}}
            getPincodes={getPincodes}
            loadingPincodes={loadingPincodes}
            required
            handleChange={vals => {
              setFormState(prev => ({
                ...prev,
                address: {
                  ...(prev.address ?? {}),
                  ...vals,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="City"
            value={formState.address?.city ?? ''}
            loading={loadingPincodes}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="State"
            loading={loadingPincodes}
            value={formState?.address?.state ?? ''}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="Country"
            loading={loadingPincodes}
            value={formState?.address?.country ?? ''}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            type="text"
            minRows={2}
            label="Google Map Link"
            value={formState.address?.locationUrl ?? ''}
            onChange={e => handleAddressChange('locationUrl', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => window.open('https://www.google.com/maps', '_blank')}
                    edge="end"
                    aria-label="open google maps"
                  >
                    <GpsFixedIcon color="secondary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} alignSelf="center" textAlign="right">
          <LoadingButton
            variant="contained"
            loading={creatingBranch || creatingAddress}
            type="submit"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BranchForm;
