import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Branch, BranchStatusEnum } from 'types';

import BranchSection from 'components/Branches/BranchSection';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PeopleSection from 'components/Peoples/PeoplesSection';
import AddressSection from 'components/Section/AddressSection/AddressSection';

const GET_BRANCH_QUERY = gql`
  query GetBranchById($id: ID!) {
    getBranchById(_id: $id) {
      _id
      email
      landline1
      landline2
      landline3
      mobile1
      mobile2
      name
      referenceId
      status
      type
      company {
        _id
        name
        referenceId
      }
      address {
        _id
        referenceId
        addressLine1
        addressLine2
        addressLocation
        city
        locationUrl
        country
        pincode
        state
      }
    }
  }
`;

type BranchQueryResponse = {
  getBranchById: Branch;
};

type BranchQueryVariables = {
  id: string;
};

const BranchDetailsPage = () => {
  const { branchId = '' } = useParams<{ branchId: string }>();

  const { data: branch, loading: loadingBranch } = useQuery<
    BranchQueryResponse,
    BranchQueryVariables
  >(GET_BRANCH_QUERY, {
    variables: {
      id: branchId,
    },
  });

  const loading = loadingBranch || !!!branch;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Branch Details',
      }}
    >
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} rowGap={2.5}>
          <BranchSection branch={branch.getBranchById} />
          <AddressSection
            address={branch.getBranchById.address}
            disabled={branch.getBranchById.status === BranchStatusEnum.INACTIVE}
          />
          <PeopleSection branch={branch.getBranchById} />
        </Grid>
      )}
    </Navbar>
  );
};

export default BranchDetailsPage;
