import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from 'react';
import { People } from 'types';

import Section from 'components/Section';

import PeopleActions from './Actions';

const ClientEmployeeCard: React.FC<{
  people: People;

  action: React.ReactNode | { title: string; onClick: () => void; disabled: boolean }[];
  isDecisionMaker?: boolean;
}> = ({ people, action, isDecisionMaker = false }) => {
  return (
    <Section
      title={people.name}
      action={action}
      variant="outlined"
      endAdornment={isDecisionMaker ? <AccountCircleIcon /> : undefined}
      data={[
        {
          label: 'ID',
          value: people.referenceId,
          type: 'STRING',
        },
        {
          label: 'Designation',
          value: people.designation,
          type: 'STRING',
        },
        {
          label: 'Status',
          value: people.status,
          type: 'STRING',
        },
      ]}
    >
      <PeopleActions people={people} />
    </Section>
  );
};

export default ClientEmployeeCard;
