import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useUser } from 'contexts/userContext';
import { FC, useMemo, useState } from 'react';

import Navbar from 'components/Navbar';

import LeadDashboard from './LeadDashboard';
import PerformanceDashboard from './PerformanceDashboard';

enum DashboardScreenEnum {
  lead = 'lead',
  performance = 'performance',
}

const Home: FC = () => {
  const [currScreen, setCurrScreen] = useState<DashboardScreenEnum>(DashboardScreenEnum.lead);

  return (
    <Navbar title={<DashboardNavTitle currScreen={currScreen} setCurrScreen={setCurrScreen} />}>
      {currScreen === DashboardScreenEnum.lead && <LeadDashboard />}
      {currScreen === DashboardScreenEnum.performance && <PerformanceDashboard />}
    </Navbar>
  );
};

const DashboardNavTitle: React.FC<{
  currScreen: DashboardScreenEnum;
  setCurrScreen: React.Dispatch<React.SetStateAction<DashboardScreenEnum>>;
}> = ({ currScreen, setCurrScreen }) => {
  const { user } = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dashboardTitle = useMemo(() => {
    switch (currScreen) {
      case DashboardScreenEnum.lead:
        return 'Leads Dashboard';
      case DashboardScreenEnum.performance:
        return 'Performance Dashboard';
      default:
        return '';
    }
  }, [currScreen]);

  const availableDashboards: { label: string; value: DashboardScreenEnum; onClick: () => void }[] =
    useMemo(() => {
      switch (user?.role.name) {
        case 'ADMIN':
        case 'USER':
          return [
            {
              label: 'Leads Dashboard',
              value: DashboardScreenEnum.lead,
              onClick: () => setCurrScreen(DashboardScreenEnum.lead),
            },
            {
              label: 'Performance Dashboard',
              value: DashboardScreenEnum.performance,
              onClick: () => setCurrScreen(DashboardScreenEnum.performance),
            },
          ];
        default:
          return [];
      }
    }, [user?.role]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid container columnGap={0.5} alignItems="center" color="black">
      <Typography variant="h6" fontWeight={700} color="black">
        {dashboardTitle}
      </Typography>
      <IconButton onClick={handleClick} size="small" color="inherit">
        <ArrowDropDownIcon color="inherit" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            {availableDashboards.map(item => (
              <ListItemButton
                key={item.value}
                sx={{
                  py: 0.4,
                  bgcolor: item.value === currScreen ? 'lightgrey' : undefined,
                  ':hover': {
                    bgcolor: item.value === currScreen ? 'lightgrey' : undefined,
                  },
                }}
                onClick={() => {
                  item.onClick();
                  handleClose();
                }}
              >
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    sx: {
                      cursor: 'pointer',
                    },
                    fontSize: 12,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </ClickAwayListener>
      </Popover>
    </Grid>
  );
};

export default Home;
