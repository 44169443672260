import { gql, useLazyQuery, useMutation } from '@apollo/client';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { LoadingButton } from '@mui/lab';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import {
  CREATE_ADDRESS_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
} from 'graphql/mutation/branch';
import {
  PINCODES_QUERY,
  PincodesQueryResponse,
  PincodesQueryVariables,
} from 'graphql/query/pincode';
import React, { useState } from 'react';
import { AddressType } from 'types';

import { getUpdatedFields } from 'components/FormPanel';
import TextField from 'components/Inputs/TextField';
import PincodeInput from 'components/Inputs/TextField/PincodeInput';

import { removeEmptyFields } from 'utils/common';

const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      _id
      addressLine1
      addressLine2
      addressLocation
      city
      locationUrl
      country
      pincode
      state
    }
  }
`;

type UpdateAddressMutationResponse = {
  updateAddress: AddressType;
};

type UpdateAddressMutationVariables = {
  input: {
    _id: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLocation?: string;
    city?: string;
    country?: string;
    pincode?: string;
    state?: string;
  };
};

type AddressFormProps = {
  address?: AddressType;
  cb: (arg: AddressType) => void;
  loading?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({
  address = { _id: '' },
  cb,
  loading = false,
}) => {
  const [formState, setFormState] = useState<AddressType | Record<string, any>>(address);

  const [getPincodes, { loading: loadingPincodes }] = useLazyQuery<
    PincodesQueryResponse,
    PincodesQueryVariables
  >(PINCODES_QUERY);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [updateAddress, { loading: updatingAddress }] = useMutation<
    UpdateAddressMutationResponse,
    UpdateAddressMutationVariables
  >(UPDATE_ADDRESS_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    if (!!address._id) {
      const refinedFormData = getUpdatedFields(address, formState);
      updateAddress({
        variables: {
          input: {
            _id: address._id,
            ...refinedFormData,
          },
        },
        onCompleted: addRes => cb(addRes.updateAddress),
      });
    } else {
      const refinedFormData = removeEmptyFields({ ...formState });
      createAddress({
        variables: {
          input: refinedFormData,
        },
        onCompleted: addRes => cb(addRes.createAddress),
      });
    }
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            value={formState.addressLine1 ?? ''}
            onChange={e => handleChange('addressLine1', e.target.value)}
            type="text"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Address Line 2"
            value={formState.addressLine2 ?? ''}
            onChange={e => handleChange('addressLine2', e.target.value)}
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Location"
            value={formState.addressLocation ?? ''}
            onChange={e => handleChange('addressLocation', e.target.value)}
            required
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <PincodeInput
            formState={formState}
            getPincodes={getPincodes}
            loadingPincodes={loadingPincodes}
            required
            handleChange={vals => {
              setFormState(prev => ({
                ...prev,
                ...vals,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="City"
            value={formState.city ?? ''}
            loading={loadingPincodes}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="State"
            loading={loadingPincodes}
            value={formState.state ?? ''}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="Country"
            loading={loadingPincodes}
            value={formState.country ?? ''}
            required
            sx={{
              pointerEvents: 'none',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            type="text"
            minRows={2}
            label="Google Map Link"
            value={formState.locationUrl ?? ''}
            onChange={e => handleChange('locationUrl', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => window.open('https://www.google.com/maps', '_blank')}
                    edge="end"
                    aria-label="open google maps"
                  >
                    <GpsFixedIcon color="secondary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} alignSelf="center" textAlign="right">
          <LoadingButton
            variant="contained"
            loading={updatingAddress || creatingAddress || loading}
            type="submit"
            disabled={!!!Object.values(getUpdatedFields(address, formState)).length}
          >
            {!!address._id ? 'Update' : 'Create'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddressForm;
