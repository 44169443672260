import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';

import { SectionProps, getSectionValue } from '.';
import theme from './theme.module.scss';

type NOS = number | string;

const GroupedSection: React.FC<
  SectionProps & { activeKey: NOS; currKey: NOS; setActiveKey: (arg: NOS) => void }
> = ({
  action,
  data = [],
  title,
  children,
  variant = 'contained',
  collapsible = false,
  activeKey,
  currKey,
  setActiveKey,
  ...props
}) => {
  const isActive = activeKey === currKey;

  return (
    <Card elevation={3} {...props}>
      <CardContent
        sx={{ padding: 0 }}
        className={classNames(
          theme.sectionContainer,
          collapsible ? (isActive ? theme.sectionExpand : theme.sectionCollapse) : ''
        )}
      >
        <Grid
          bgcolor={theme =>
            variant === 'contained' ? theme.palette.primary.main : theme.palette.common.white
          }
          color={theme =>
            variant === 'contained' ? theme.palette.common.white : theme.palette.primary.main
          }
          container
          justifyContent="space-between"
          px={1}
          pt={0.5}
          alignItems="center"
          pb={0.3}
          sx={{ boxShadow: 2 }}
          position="relative"
        >
          <Grid
            item
            xs={11}
            sx={{ cursor: collapsible ? 'pointer' : 'auto' }}
            onClick={() => (isActive ? setActiveKey(-1) : setActiveKey(currKey))}
          >
            <Typography variant="subtitle1" fontWeight={600}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} position="absolute" right="0.5rem">
            {Array.isArray(action) ? <Actions actions={action} /> : action}
          </Grid>
        </Grid>
        <Grid container direction="column" rowGap={1} mt={1.5} px={2}>
          {data.map(d => (
            <Grid container columns={2} alignItems="start" key={d.label}>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={400}>
                  {d.label}
                </Typography>
              </Grid>
              <Grid item xs={1} overflow="scroll">
                {d.type !== 'NODE' || !!!d.value ? (
                  <Typography variant="body2" fontWeight={500}>
                    {getSectionValue(d.value, d.type, d.navigateTo)}
                  </Typography>
                ) : (
                  d.value
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box px={2}> {children}</Box>
      </CardContent>
    </Card>
  );
};

const Actions: React.FC<{
  actions: { title: string; onClick: () => void; disabled: boolean }[];
}> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div style={{ color: '#ffffff' }}>
        <IconButton onClick={handleClick} color="inherit">
          <MoreHorizIcon />
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            {actions.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={
                  !item.disabled
                    ? () => {
                        item.onClick();
                        handleClose();
                      }
                    : undefined
                }
              >
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      cursor: item.disabled ? 'not-allowed' : 'pointer',
                    },
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

export default GroupedSection;
