import { gql, useMutation } from '@apollo/client';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useState } from 'react';

import Navbar from 'components/Navbar';

import { logout } from 'utils/auth';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($currentPassword: String!, $newPassword: String!) {
    resetPassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`;

const ResetPassword: FC = () => {
  const [currPassword, setCurrPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [showCurrPassword, setDisplayCurrPassword] = useState<boolean>(false);
  const [showNewPassword, setDisplayNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setDisplayConfirmPassword] = useState<boolean>(false);

  const [errMsg, setErrMsg] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrMsg('');
    resetPassword({
      variables: {
        currentPassword: currPassword,
        newPassword: newPassword,
      },
      onCompleted: _ => {
        logout();
      },
      onError: err => setErrMsg(err.message),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Go Back',
      }}
    >
      <Grid container flexDirection="column" pt={isMobileScreen ? '4rem' : '3rem'}>
        <form onSubmit={onSubmit}>
          <Grid item container xs={12} md={8} mx={'auto'} flexDirection="column" gap={2}>
            <Grid item xs={12}>
              <Typography
                textAlign="center"
                variant="h5"
                fontWeight={600}
                color={theme => theme.palette.primary.main}
              >
                RESET PASSWORD
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormControl fullWidth>
                <TextField
                  value={currPassword}
                  onChange={e => setCurrPassword(e.target.value)}
                  label="Current Password"
                  placeholder="Enter your current password"
                  type={showCurrPassword ? 'text' : 'password'}
                  autoComplete=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setDisplayCurrPassword(prev => !prev)}>
                          {showCurrPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  label="New Password"
                  placeholder="Enter a new password"
                  type={showNewPassword ? 'text' : 'password'}
                  autoComplete=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setDisplayNewPassword(prev => !prev)}>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    if (newPassword !== e.target.value) {
                      setConfirmPasswordErr('new password and confirm password does not match');
                    } else {
                      setConfirmPasswordErr('');
                    }
                  }}
                  label="Confirm Password"
                  placeholder="Re-enter the new password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  error={!!confirmPasswordErr ? true : undefined}
                  helperText={confirmPasswordErr}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0.5,
                      color: 'crimson',
                    },
                  }}
                  autoComplete=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setDisplayConfirmPassword(prev => !prev)}>
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item container flexDirection="column" rowGap={0.5}>
              {!!errMsg && (
                <Typography variant="caption" color="red">
                  {errMsg}
                </Typography>
              )}
              <LoadingButton
                loading={loading}
                disabled={
                  !!!currPassword || !!!newPassword || !!!confirmPassword || !!confirmPasswordErr
                }
                variant="contained"
                type="submit"
                fullWidth
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};
export default ResetPassword;
