import React from 'react';
import { IconProps } from 'types';

const ImageGalleryicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      viewBox="0 0 80 79"
      fill={color}
      {...attributes}
    >
      <path
        d="M38.603 9.892H4.696c-2.854 0-5.169 2.314-5.169 5.169v22.91c0 2.854 2.315 5.168 5.17 5.168h33.906c2.854 0 5.168-2.314 5.168-5.169V15.06c0-2.854-2.314-5.168-5.168-5.168zM4.696 11.524h33.907c1.953 0 3.536 1.583 3.536 3.537v22.91c0 1.952-1.583 3.536-3.536 3.536H4.696c-1.953 0-3.536-1.584-3.536-3.537V15.06c0-1.953 1.583-3.536 3.536-3.536z"
        transform="translate(-538 -573) translate(483 530) translate(55.825 43.093) translate(13.058 19.588)"
      />
      <path
        d="M15.333 17.994L31.373 34.034 37.155 28.262 42.398 33.505 41.244 34.66 37.151 30.566 31.374 36.343 15.334 20.303 0.921 34.715 -0.233 33.56z"
        transform="translate(-538 -573) translate(483 530) translate(55.825 43.093) translate(13.058 19.588)"
      />
      <path
        d="M11.727-.475l.344.038 39.175 7.491c.57.109 1.075.44 1.401.92.262.385.392.84.378 1.3l-.038.344-5.45 28.335c-.109.571-.44 1.076-.92 1.402-.385.262-.84.392-1.297.378l-.343-.037-2.171-.406.3-1.604 2.174.406c.146.028.296-.004.419-.087.082-.055.146-.13.189-.219l.046-.14 5.45-28.335c.028-.146-.003-.296-.087-.419-.055-.082-.13-.146-.218-.189l-.14-.046-39.175-7.491c-.145-.028-.296.003-.418.087-.082.055-.147.13-.19.218l-.045.14-1.773 9.25-1.603-.307 1.773-9.25c.108-.57.44-1.075.92-1.402.385-.26.84-.391 1.299-.377z"
        transform="translate(-538 -573) translate(483 530) translate(55.825 43.093) translate(13.058 19.588)"
      />
    </svg>
  );
};
export default ImageGalleryicon;
