import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CompanyType, Lead } from 'types';

import PastFollowupsSection from 'components/Followups/Sections/PastFollowupsSection';
import AssociatedCompanyButton from 'components/Leads/Associates/AssociateButton';
import AssociateCardsSection from 'components/Leads/Associates/AssociateCard';
import LeadSection from 'components/Leads/LeadSection';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import AddressSection from 'components/Section/AddressSection/AddressSection';

const LEAD_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      referenceId
      name
      areaOrPower
      areaOrPowerUOM
      scope
      isBuildAndDesign
      segment
      pqSubmitType
      rfpSubmitType
      createdBy {
        _id
        firstName
        lastName
      }
      lostReason
      leadStatus
      approxValue
      poAmount
      poIssuedBy
      poNumber
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      followups {
        _id
        lead {
          _id
          name
        }
        referenceId
        leadStatus
        followupType
        followupDate
        comments
        lostReason
      }
      address {
        _id
        referenceId
        addressLine1
        addressLine2
        addressLocation
        locationUrl
        city
        country
        pincode
        state
      }
      company {
        _id
        name
        type
        referenceId
      }
      branch {
        name
        type
        address {
          addressLine1
          addressLocation
          addressLine2
          state
          city
        }
      }
      architect {
        _id
        name
        referenceId
        type
      }
      architectBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
          _id
        }
      }
      pmc {
        _id
        name
        referenceId
        type
      }
      pmcBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      pmo {
        _id
        name
        referenceId
        type
      }
      pmoBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      costConsultant {
        _id
        name
        referenceId
        type
      }
      costConsultantBranch {
        _id
        name
        referenceId
        address {
          addressLine1
          addressLocation
          addressLine2
          city
          state
        }
      }
      others {
        _id
        name
        referenceId
        type
        branches {
          _id
          name
          address {
            addressLine1
            addressLocation
            addressLine2
            city
            state
          }
        }
      }
      competitors {
        _id
        name
        referenceId
        type
        branches {
          _id
          name
          address {
            addressLine1
            addressLocation
            addressLine2
            city
            state
          }
        }
      }
      branch {
        _id
        name
        referenceId
      }
      salesPerson {
        _id
        firstName
        lastName
      }
    }
  }
`;

type LeadQueryResponse = {
  getLeadById: Lead;
};

type LeadQueryVariables = {
  id: string;
};

const LeadDetailsPage = () => {
  const { leadId = '' } = useParams<{ leadId: string }>();

  const { data: lead, loading: loadingLead } = useQuery<LeadQueryResponse, LeadQueryVariables>(
    LEAD_QUERY,
    {
      variables: {
        id: leadId,
      },
    }
  );

  const loading = loadingLead || !!!lead;

  return (
    <Navbar
      goBackButtonConfig={{
        title: loading ? 'Lead Details' : lead.getLeadById.name,
      }}
    >
      {loading ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <LeadSection lead={lead.getLeadById} />
          <AddressSection
            address={lead.getLeadById.address}
            disabled={false}
            title="Site Address"
          />
          <Grid
            item
            container
            direction="row"
            rowGap={2}
            columnGap={1.5}
            alignItems="center"
            width={'100%'}
          >
            {!!!lead.getLeadById.company?._id && (
              <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.CLIENT} />
            )}
            {!!!lead.getLeadById.pmc?._id && (
              <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.PMC} />
            )}
            {!!!lead.getLeadById.architect?._id && (
              <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.ARCHITECT} />
            )}
            {!!!lead.getLeadById.pmo?._id && (
              <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.PMO} />
            )}
            {!!!lead.getLeadById.wkConsultant?._id && (
              <AssociatedCompanyButton
                lead={lead.getLeadById}
                type={CompanyType['WK CONSULTANT']}
              />
            )}
            {!!!lead.getLeadById.costConsultant?._id && (
              <AssociatedCompanyButton
                lead={lead.getLeadById}
                type={CompanyType['COST CONSULTANT']}
              />
            )}
            <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.OTHERS} />
            <AssociatedCompanyButton lead={lead.getLeadById} type={CompanyType.COMPETITOR} />
          </Grid>

          <AssociateCardsSection lead={lead.getLeadById} />
          <PastFollowupsSection
            followups={lead.getLeadById.followups}
            lead={lead.getLeadById}
            title="Events"
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default LeadDetailsPage;
