import { gql } from '@apollo/client';
import { People, PeopleStatusEnum } from 'types';

export type CreatePeopleMutationResponse = {
  createPeople: People;
};

export type CreatePeopleMutationVariables = {
  input: {
    branch: string;
    company: string;
    designation?: string;
    email: string;
    mobile: string;
    name: string;
    status?: PeopleStatusEnum;
  };
};
export const CREATE_PEOPLE_MUTATION = gql`
  mutation CreatePeople($input: CreatePeopleInput!) {
    createPeople(input: $input) {
      _id
      branch {
        _id
      }
      company {
        _id
      }
      mobile
      designation
      email
      name
      referenceId
    }
  }
`;

export const UPDATE_PEOPLE_MUTAION = gql`
  mutation UpdatePeople($input: UpdatePeopleInput!) {
    updatePeople(input: $input) {
      _id
      designation
      email
      mobile
      name
      referenceId
      status
    }
  }
`;

export type UpdatePeopleMutationResponse = {
  updatePeople: People;
};
export type UpdatePeopleMutationVariables = {
  input: {
    _id: string;
    name?: string;
    status?: PeopleStatusEnum;
    designation?: string;
    email?: string;
    mobile?: string;
  };
};
