import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Lead } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import PQFolderSection from 'components/PQs/Sections/FolderSection';
import PQSection from 'components/PQs/Sections/PQSection';

const GET_PQ_BY_ID_QUERY = gql`
  query GetPQById($id: ID!) {
    getPQById(_id: $id) {
      _id
      name
      pqReceivedDate
      pqSubmittedDate
      leadStatus
      company {
        _id
        name
      }
      segment
      scope
      approxValue
      isBuildAndDesign
      leadStatus
      pqReceivedDate
      pqSubmittedDate
    }
  }
`;

export type PQQueryResponse = {
  getPQById: Lead;
};

export type PQQueryVariables = {
  id: string;
};

const PQDetailsPage = () => {
  const { leadId = '' } = useParams<{ leadId: string }>();

  const {
    data: pq,
    networkStatus: pqNetworkStatus,
    refetch: refetchPQ,
  } = useQuery<PQQueryResponse, PQQueryVariables>(GET_PQ_BY_ID_QUERY, {
    variables: {
      id: leadId,
    },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadingPQ =
    NetworkStatus.loading === pqNetworkStatus ||
    NetworkStatus.setVariables === pqNetworkStatus ||
    !!!pq;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'PQ Details',
      }}
    >
      {loadingPQ ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <PQSection lead={pq.getPQById} refetchPQ={refetchPQ} />
          <PQFolderSection leadId={pq.getPQById._id} leadStatus={pq.getPQById.leadStatus} />
        </Grid>
      )}
    </Navbar>
  );
};

export default PQDetailsPage;
