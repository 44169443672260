// FollowupsTemplate.tsx
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Followup, FollowupTypeEnum, LeadSegmentEnum } from 'types';

import LeadCardTemplate from 'components/Card/LeadCard';

import { truncateString } from 'utils/common';
import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const FollowupsTemplate: React.FC<{ followups: Followup[] }> = ({ followups }) => {
  const navigate = useNavigate();

  const FollowupCustomCSS = (followup: Followup): SxProps<Theme> =>
    ({
      backgroundColor: (theme: Theme) => {
        if (followup.lead.probability < 30) {
          return theme.palette.funnelState.cold;
        } else if (followup.lead.probability < 60) {
          return theme.palette.funnelState.warm;
        } else {
          return theme.palette.funnelState.hot;
        }
      },
    } as SxProps<Theme>);

  return (
    <Grid
      container
      columns={3}
      rowSpacing={3}
      columnSpacing={5}
      alignItems="center"
      mb={5}
      pr={2}
      pt={1}
    >
      {followups.map(followup => (
        <Grid item key={followup.referenceId} width={'100%'} md={1.5} lg={1}>
          <LeadCardTemplate
            title={truncateString(followup.lead.name, 30)}
            segment={followup.lead.segment as LeadSegmentEnum}
            sx={FollowupCustomCSS(followup)}
            onClick={() => navigate(followup._id)}
          >
            <Grid color="gray" mb={-1.5}>
              <Typography variant="caption" fontWeight={700}>
                {getEnumKeyFromValue(FollowupTypeEnum, followup.followupType)} on{' '}
                {getDate(followup.followupDate)}
              </Typography>

              <Typography
                variant="caption"
                fontWeight={600}
                pt={0.2}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}
              >
                {`"${followup.comments || <i>None</i>}"`}
              </Typography>
            </Grid>
          </LeadCardTemplate>
        </Grid>
      ))}
    </Grid>
  );
};

export default FollowupsTemplate;
