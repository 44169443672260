import {
  cityRegex,
  decimalRegex,
  emailRegex,
  gstRegex,
  integerRegex,
  nonSpecialCharacterRegex,
  panRegex,
  phoneNumberRegex,
  pincodeRegex,
  urlRegex,
} from './regexes';

export type Validators = {
  required?: boolean;
  dependsOn?: string[];
  dependsOnFieldAndValue?: { fieldName: string; value: any };
  minLength?: number;
  maxLength?: number;
  isEmail?: boolean;
  isInteger?: boolean;
  isDecimal?: boolean;
  nonZero?: boolean;
  isGSTNumber?: boolean;
  isPAN?: boolean;
  isPincode?: boolean;
  isCity?: boolean;
  isPhoneNumber?: boolean;
  isValidUrl?: boolean;
  maxValue?: number;
  areSpecialCharactersAllowed?: boolean;
};

export default function validate(validators: Validators, formState, value: any) {
  const checkDependencies = (fieldNames: string[]) => {
    for (let i = 0; i < fieldNames.length; i++) {
      const f = fieldNames[i];
      if (!!formState.data[f] && !!!value) {
        return true;
      }
    }
    return false;
  };

  switch (true) {
    case !!validators.nonZero && value === 0:
      return `This value cannot be zero.`;
    case validators.maxValue !== undefined && value > validators.maxValue:
      return `This value cannot be greater than ${validators.maxValue}.`;
    case !!validators.dependsOn && checkDependencies(validators.dependsOn):
      return 'This field is required.';
    case !!validators.dependsOnFieldAndValue &&
      formState.data[validators.dependsOnFieldAndValue.fieldName] ===
        validators.dependsOnFieldAndValue.value &&
      !!!value:
      return 'This field is required.';
    case !!validators.required &&
      (typeof value === 'number' ? false : Array.isArray(value) ? !value.length : !value):
      return 'This field is required.';
    case !!validators.minLength &&
      value &&
      (value.length ?? String(value)?.length) < validators.minLength:
      return `Too short. Must be a minimum of ${validators.minLength} characters.`;
    case !!validators.maxLength &&
      value &&
      (value.length ?? String(value)?.length) > validators.maxLength:
      return `Too long. Must be a maximum of ${validators.maxLength} characters.`;
    case !!validators.isEmail && !!value && !emailRegex.test(value):
      return `Please enter a valid email ID. Example: test@homealankar.com`;
    case !!validators.isInteger && !integerRegex.test(value):
      return 'Please enter a valid whole number. Decimals are not allowed.';
    case !!validators.isDecimal && !decimalRegex.test(value):
      return 'Please enter a valid number or decimal value.';
    case !!validators.isGSTNumber && !gstRegex.test(value):
      return `Must be a valid GST Number. Example 29ABCDT1234F1ZR`;
    case !!validators.isPAN && !panRegex.test(value):
      return `Must be a valid Permanent Account Number (PAN). Example ABCDT1234F`;
    case !!validators.isPincode && !pincodeRegex.test(value):
      return `Must be a valid Pincode. Example 560001`;
    case !!validators.isValidUrl && !urlRegex.test(value):
      return `Must be a valid URL. Example https://homealankar.com/path-to-image/image.png`;
    case !!validators.isCity && !cityRegex.test(value):
      return `Please enter a valid city name. Must not contain special characters.`;
    case !!validators.isPhoneNumber && !phoneNumberRegex.test(value):
      return `Please enter a valid 10 digit Phone Number.`;
    case !!validators.areSpecialCharactersAllowed && !nonSpecialCharacterRegex.test(value):
      return `Please enter valid characters. Must not contain special characters`;
    default:
      return null;
  }
}
