import React from 'react';
import { IconProps } from 'types';

const MicrosoftProjecticon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="100px"
      height="80px"
      {...attributes}
    >
      <path fill="#81C784" d="M41,10H25v28h16c0.6,0,1-0.4,1-1V11C42,10.4,41.6,10,41,10z" />
      <path fill="#FFF" d="M39,23l-4,4l-4-4h3v-6h-7v-2h7h2v2v6H39z M29,26l-4,4l4,4l4-4L29,26z" />
      <path fill="#43A047" d="M27 42L6 38 6 10 27 6z" />
      <path
        fill="#FFF"
        d="M16.8,17H12v14h3v-4.8h1.6c1.7,0,3-0.4,4-1.3c1-0.9,1.5-2,1.5-3.4C22,18.5,20.3,17,16.8,17z M16.3,23.8H15v-4.4h1.3c1.6,0,2.5,0.7,2.5,2.2C18.8,23.1,17.9,23.8,16.3,23.8z"
      />
    </svg>
  );
};
export default MicrosoftProjecticon;
