import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Branch, BranchStatusEnum, BranchTypeEnum } from 'types';

import { getUpdatedFields } from 'components/FormPanel';
import { Select } from 'components/Inputs/Select';
import TextField, { ContactTextField } from 'components/Inputs/TextField';

type BranchFormProps = {
  toggleForm: (arg: boolean) => void;
  branch: Branch;
  cb?: (arg: Branch) => void;
};

const UPDATE_BRANCH_MUTATION = gql`
  mutation UpdateBranch($input: UpdateBranchInput!) {
    updateBranch(input: $input) {
      _id
      email
      landline1
      landline2
      landline3
      mobile1
      mobile2
      name
      status
    }
  }
`;

type BranchMutationResponse = {
  updateBranch: Branch;
};

type BranchMutationVariables = {
  input: {
    _id: string;
    email?: string;
    landline1?: string;
    landline2?: string;
    landline3?: string;
    mobile1?: string;
    mobile2?: string;
    name?: string;
    status?: BranchStatusEnum;
  };
};

const BranchUpdateForm: React.FC<BranchFormProps> = ({ toggleForm, branch }) => {
  const [formState, setFormState] = useState<Record<string, any>>(branch);

  const [updateBranch, { loading: updatingBranch }] = useMutation<
    BranchMutationResponse,
    BranchMutationVariables
  >(UPDATE_BRANCH_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    const refinedFormData = getUpdatedFields(branch, formState);
    updateBranch({
      variables: {
        input: {
          _id: branch._id,
          ...refinedFormData,
        },
      },
      onCompleted: _ => toggleForm(false),
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3} pt={1}>
        <Grid item xs={6}>
          <TextField
            label="Name"
            value={formState.name ?? ''}
            onChange={e => handleChange('name', e.target.value)}
            disabled={branch.type === BranchTypeEnum.HO}
            required
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            options={Object.values(BranchStatusEnum).map(o => ({ label: o, value: o }))}
            value={formState.status ?? ''}
            onChange={val => handleChange('status', val)}
            label="Status"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            value={formState.email ?? ''}
            onChange={e => handleChange('email', e.target.value)}
            required
            type="email"
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Mobile 1"
            onChange={e => handleChange('mobile1', e.target.value)}
            value={formState?.mobile1 ?? ''}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Mobile 2"
            onChange={e => handleChange('mobile2', e.target.value)}
            value={formState?.mobile2 ?? ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 1"
            value={formState?.landline1 ?? ''}
            onChange={e => handleChange('landline1', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 2"
            value={formState?.landline2 ?? ''}
            onChange={e => handleChange('landline2', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landline 3"
            value={formState?.landline3 ?? ''}
            onChange={e => handleChange('landline3', e.target.value)}
            type="number"
          />
        </Grid>
        <Grid item xs={12} alignSelf="center" textAlign="right">
          <LoadingButton
            variant="contained"
            loading={updatingBranch}
            type="submit"
            disabled={!!!Object.keys(getUpdatedFields(branch, formState)).length}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BranchUpdateForm;
