import { NetworkStatus, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { client } from 'graphql/client';
import {
  GET_DOCUMENT_FILES_QUERY,
  GetFilesQueryResponse,
  GetFilesQueryVariables,
} from 'graphql/query/files';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentFile, FolderTypeEnum } from 'types';

import DocumentFileCard from 'components/DocumentMaster/Card/DocumentCard';
import DocumentFilesListTemplate from 'components/DocumentMaster/DocumentFilesList';
import DocumentFileForm from 'components/DocumentMaster/Forms/DocumentFileForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

type DocumentViewType = 'list' | 'grid';

const DocumentsMasterFilesPage = () => {
  const [viewType, setViewType] = useState<DocumentViewType>('grid');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showCreateNewForm, toggleCreateNewForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  const {
    data: docs,
    networkStatus: docsNetworkStatus,
    refetch: reFetchDocs,
  } = useQuery<GetFilesQueryResponse, GetFilesQueryVariables>(GET_DOCUMENT_FILES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      folder: subFolderId,
      lead: isLead ? documentId : undefined,
      project: isLead ? undefined : documentId,
    },
    fetchPolicy: 'network-only',
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const documentActions = [
    <>
      <Button color="secondary" variant="contained" size="small" onClick={handleClick}>
        <AddIcon fontSize="small" />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            <ListItemButton sx={{ py: 0.4 }} onClick={() => navigate('add-files')}>
              <ListItemText
                primary="Add Standard Files"
                primaryTypographyProps={{ fontSize: 12 }}
              />
            </ListItemButton>
            <ListItemButton sx={{ py: 0.4 }} onClick={() => toggleCreateNewForm(true)}>
              <ListItemText primary="Add New File" primaryTypographyProps={{ fontSize: 12 }} />
            </ListItemButton>
          </List>
        </ClickAwayListener>
      </Popover>
    </>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    reFetchDocs(newFilters);
  };

  const loadingDocuments =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!docs;

  const handleViewChange = (view: DocumentViewType) => {
    setViewType(view);
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!docs?.getFiles?.title ? docs.getFiles.title : '',
        hideSeparator: true,
      }}
      searchInputConfig={{
        enable: true,
        name: 'documents search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={documentActions}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          mt: -1.5,
        }}
      >
        <Box>
          <IconButton onClick={() => handleViewChange('grid')} color="secondary">
            {viewType === 'grid' ? (
              <GridViewIcon
                fontSize="small"
                sx={{
                  backgroundColor: orange[200],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <GridViewIcon
                fontSize="small"
                sx={{
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            )}
          </IconButton>
          <IconButton onClick={() => handleViewChange('list')} color="secondary">
            {viewType === 'list' ? (
              <ViewListOutlinedIcon
                fontSize="medium"
                sx={{
                  backgroundColor: orange[200],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <ViewListOutlinedIcon
                fontSize="medium"
                sx={{
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            )}
          </IconButton>
        </Box>
      </Box>

      {loadingDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid minHeight="80vh" mt={0.5} mb={4}>
          {viewType === 'list' ? (
            <DocumentFilesListTemplate
              docsNetworkStatus={docsNetworkStatus}
              docs={docs.getFiles}
              fetchDocs={reFetchDocs}
            />
          ) : (
            <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center">
              {docs.getFiles.files.map(file => (
                <DocumentFileCard file={file} key={file._id} />
              ))}

              {docs.getFiles.files.length === 0 && (
                <Grid container justifyContent={'center'} mt={2}>
                  <Typography variant="subtitle1">No reports to show</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}

      <SimplePopup
        title="Upload New File"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={showCreateNewForm}
      >
        <Grid py={1}>
          <DocumentFileForm
            folderId={subFolderId}
            cb={res => {
              toggleCreateNewForm(false);
              updateFileCachedList(res, {
                folder: subFolderId,
                lead: isLead ? documentId : undefined,
                project: isLead ? undefined : documentId,
              });
            }}
          />
        </Grid>
      </SimplePopup>
    </Navbar>
  );
};

export const updateFileCachedList = (newFile: DocumentFile, variables?: Record<string, any>) => {
  try {
    const currFiles = client.readQuery({
      query: GET_DOCUMENT_FILES_QUERY,
      variables,
    }) ?? {
      getFiles: {
        title: '',
        files: [],
      },
    };

    client.writeQuery({
      query: GET_DOCUMENT_FILES_QUERY,
      variables: variables,
      data: {
        getFiles: {
          ...currFiles.getFiles,
          files: [newFile, ...currFiles.getFiles.files],
        },
      },
    });
  } catch (error) {
    console.error('Error updating cache:', error);
  }
};

export default DocumentsMasterFilesPage;
