import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControl, FormControlLabel, Grid, useMediaQuery } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  UPDATE_LEAD_MUTATION,
  UpdateLeadMutationResponse,
  UpdateLeadMutationVariables,
} from 'graphql/mutation/lead';
import React, { useState } from 'react';
import { Lead, LeadScopeEnum, LeadSegmentEnum, LeadUnitOfMeasureEnum } from 'types';

import { getUpdatedFields } from 'components/FormPanel';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

import { stringToDateFormatter } from 'utils/formatHelper';
import { formatDate } from 'utils/transformFn';

type UpdateLeadFormProps = {
  lead: Lead;
  cb: () => void;
};

const UpdateLeadForm: React.FC<UpdateLeadFormProps> = ({ lead, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>(lead);

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [updateLead, { loading: updatingLead }] = useMutation<
    UpdateLeadMutationResponse,
    UpdateLeadMutationVariables
  >(UPDATE_LEAD_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    const expectedPODate = formState.expectedPODate;
    const refinedData = getUpdatedFields(lead, formState);

    refinedData.expectedPODate = formatDate(expectedPODate);

    updateLead({
      variables: {
        input: {
          _id: lead._id,
          ...(refinedData ?? {}),
        },
      },
      onCompleted: _ => cb(),
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2} px={2} py={1.5}>
        <Grid item xs={12}>
          <TextField
            value={formState.name ?? ''}
            onChange={e => handleChange('name', e.target.value)}
            label="Name"
            placeholder="Lead Name"
            required
          />
        </Grid>

        <Grid item xs={6}>
          <Select
            options={Object.keys(LeadSegmentEnum).map(k => ({
              label: k,
              value: LeadSegmentEnum[k],
            }))}
            label="Segment"
            value={formState.segment ?? ''}
            onChange={val => handleChange('segment', val)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            options={Object.keys(LeadScopeEnum).map(k => ({
              label: k,
              value: LeadScopeEnum[k],
            }))}
            label="Scope"
            value={formState.scope ?? ''}
            onChange={val => handleChange('scope', val)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState.areaOrPower ?? ''}
            onChange={e => handleChange('areaOrPower', !!e.target.value ? +e.target.value : '')}
            label="Area / Power / Count"
            required
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            options={Object.keys(LeadUnitOfMeasureEnum).map(o => ({
              label: o,
              value: LeadUnitOfMeasureEnum[o],
            }))}
            label="Unit of Measure"
            placeholder="UOM"
            value={formState.areaOrPowerUOM ?? ''}
            onChange={val => handleChange('areaOrPowerUOM', val)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={stringToDateFormatter(formState.expectedPODate)}
                format="DD/MM/YYYY"
                onChange={val => handleChange('expectedPODate', val)}
                label="Expected PO Date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            value={formState.approxValue ?? ''}
            onChange={e => handleChange('approxValue', !!e.target.value ? +e.target.value : '')}
            label="Approx Value (Cr)"
            required
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.isBuildAndDesign}
                onChange={e => handleChange('isBuildAndDesign', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Design & Build"
          />
        </Grid>

        <Grid item xs={12} container justifyContent="end">
          <LoadingButton
            type="submit"
            fullWidth={isMobileScreen}
            loading={updatingLead}
            disabled={updatingLead || !!!Object.keys(getUpdatedFields(lead, formState)).length}
            size={isMobileScreen ? 'medium' : 'large'}
            variant="contained"
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateLeadForm;
