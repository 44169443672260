import EditNoteIcon from '@mui/icons-material/EditNote';
import React, { useState } from 'react';
import { Branch } from 'types';

import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

import BranchUpdateForm from './Form/BranchUpdateForm';

type BranchSectionProps = {
  branch: Branch;
};

const BranchSection: React.FC<BranchSectionProps> = ({ branch }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);

  return (
    <>
      <Section
        title={branch.name}
        collapsible
        action={<EditNoteIcon onClick={() => toggleFormEdit(true)} sx={{ cursor: 'pointer' }} />}
        data={[
          {
            label: 'ID',
            value: branch.referenceId,
            type: 'STRING',
          },
          {
            label: 'Branch Name',
            value: branch.name,
            type: 'STRING',
          },
          {
            label: 'Head Office',
            value: branch.company.name,
            type: 'STRING',
          },
          {
            label: 'Status',
            value: branch.status,
            type: 'STRING',
          },
          {
            label: 'Email',
            value: branch.email,
            type: 'EXTERNAL_LINK',
            navigateTo: `mailto:${branch.email}`,
          },
          {
            label: 'Mobile 1',
            value: branch.mobile1,
            type: 'EXTERNAL_LINK',
            navigateTo: `tel:${branch.mobile1}`,
          },
          {
            label: 'Mobile 2',
            value: branch.mobile2 ?? '-',
            type: 'NUMBER',
          },
          {
            label: 'Landline 1',
            value: branch.landline1 ?? '-',
            type: 'STRING',
          },
          {
            label: 'Landline 2',
            value: branch.landline2 ?? '-',
            type: 'STRING',
          },
        ]}
      />

      <SimplePopup
        onClose={() => toggleFormEdit(false)}
        open={enableFormEdit}
        title="Update Branch"
        fullWidth
      >
        <BranchUpdateForm toggleForm={toggleFormEdit} branch={branch} />
      </SimplePopup>
    </>
  );
};

export default BranchSection;
