import React from 'react';
import { IconProps } from 'types';

const DailyProjectupdateicon: React.FC<IconProps> = ({ color = 'currentColor', ...attributes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      viewBox="0 0 80 79"
      fill={color}
      {...attributes}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g fillRule="nonzero">
            <path
              d="M46.864 8.96L38.16.256C37.995.092 37.774 0 37.543 0H15.779c-1.922.002-3.48 1.56-3.482 3.482V28.73h1.74V3.482c.002-.96.78-1.74 1.742-1.74h20.893v5.222c.003 1.923 1.56 3.48 3.483 3.483h5.223V48.75c-.001.962-.78 1.74-1.741 1.742H15.779c-.428 0-.842-.158-1.16-.444l-1.162 1.298c.638.571 1.465.887 2.322.887h27.858c1.922-.003 3.48-1.56 3.482-3.483V9.576c0-.23-.092-.452-.255-.615zm-8.45-1.996V2.972l5.733 5.734h-3.992c-.962-.001-1.74-.78-1.741-1.742z"
              transform="translate(-748 -573) translate(693 530) translate(55.825 43.093) translate(16.323 13.058)"
            />
            <path
              d="M21.002 42.658c-.48 0-.87.39-.87.87v5.223c0 .481.39.871.87.871h5.224c.48 0 .87-.39.87-.87v-5.224c0-.48-.39-.87-.87-.87h-5.224zm4.353 5.223h-3.482v-3.482h3.482v3.482zM28.837 48.751c0 .481.39.871.87.871h5.224c.481 0 .87-.39.87-.87v-9.577c0-.48-.389-.87-.87-.87h-5.223c-.48 0-.87.39-.87.87v9.576zm1.741-8.705h3.483v7.835h-3.483v-7.835zM38.414 49.622h5.223c.48 0 .87-.39.87-.87V35.692c0-.48-.39-.87-.87-.87h-5.223c-.481 0-.871.39-.871.87v13.058c0 .481.39.871.87.871zm.87-13.058h3.482V47.88h-3.482V36.564zM1.85 40.046c.005-4.181 3.289-7.624 7.465-7.826l-1.116 1.116 1.231 1.232 2.612-2.612c.34-.34.34-.891 0-1.231L9.43 28.113 8.2 29.344l1.133 1.133c-4.266.158-7.912 3.12-8.941 7.262-1.029 4.143.809 8.467 4.505 10.601l.872-1.507c-2.42-1.404-3.912-3.989-3.918-6.787zM19.261 40.046c-.007-3.42-1.83-6.578-4.787-8.295l-.872 1.507c3.012 1.74 4.517 5.255 3.697 8.635-.82 3.38-3.77 5.814-7.244 5.978l1.116-1.116-1.231-1.23-2.612 2.61c-.34.34-.34.892 0 1.232L9.94 51.98l1.231-1.231-1.133-1.133c5.145-.196 9.217-4.42 9.223-9.57z"
              transform="translate(-748 -573) translate(693 530) translate(55.825 43.093) translate(16.323 13.058)"
            />
            <path
              d="M10.3 40.661c.164-.163.256-.384.256-.615v-4.353H8.814v3.992l-2.356 2.357 1.23 1.231 2.613-2.612z"
              transform="translate(-748 -573) translate(693 530) translate(55.825 43.093) translate(16.323 13.058)"
            />
          </g>
          <path
            d="M25.487 8.873c0 .45.109.816.243.816h6.042c.135 0 .244-.366.244-.816 0-.451-.109-.816-.244-.816H25.73c-.134 0-.243.365-.243.816zM16.323 18.666c0 .451.436.817.975.817h24.167c.539 0 .975-.366.975-.817 0-.45-.436-.816-.975-.816H17.298c-.539 0-.975.366-.975.816zM16.323 26.933c0 .45.436.816.975.816h24.167c.539 0 .975-.365.975-.816 0-.45-.436-.816-.975-.816H17.298c-.539 0-.975.365-.975.816zM39.08 11.111H26.252c-.421 0-.763.342-.763.764s.342.763.763.763h12.83c.422 0 .763-.341.763-.763s-.341-.764-.763-.764zM34.869 20.905H17.365c-.576 0-1.042.365-1.042.816 0 .45.466.816 1.042.816h17.504c.575 0 1.042-.365 1.042-.816 0-.45-.467-.816-1.042-.816zM34.869 29.171H17.365c-.576 0-1.042.366-1.042.817 0 .45.466.816 1.042.816h17.504c.575 0 1.042-.366 1.042-.816 0-.451-.467-.817-1.042-.817zM21.669 6.53c1.264 0 2.289 1.026 2.29 2.29v3.055c-.001 1.264-1.026 2.289-2.29 2.29h-3.055c-1.265 0-2.29-1.025-2.291-2.29V8.82c.001-1.265 1.026-2.29 2.291-2.29zm0 1.527h-3.055c-.422 0-.764.341-.764.763v3.055c0 .202.08.397.224.54.143.143.337.223.54.223h3.055c.421 0 .763-.342.763-.763V8.82c0-.421-.342-.763-.763-.763z"
            transform="translate(-748 -573) translate(693 530) translate(55.825 43.093) translate(16.323 13.058)"
          />
        </g>
      </g>
    </svg>
  );
};
export default DailyProjectupdateicon;
