import React, { useContext, useEffect, useState } from 'react';
import { LeadSegmentEnum, LeadStatusEnum, MonthTypeEnum, PerformanceTimelineEnum } from 'types';

import { useUser } from './userContext';

export type LeadDashboardFiltersType = {
  segment: LeadSegmentEnum[];
  monthType?: MonthTypeEnum;
  leadStatus?: LeadStatusEnum[];
  searchTerm?: string;
};

type LeadDashboardContextType = {
  leadFilters: LeadDashboardFiltersType;
  setLeadFilters: React.Dispatch<React.SetStateAction<LeadDashboardFiltersType>>;
};

export type PerformanceDashboardFiltersType = {
  timeline: PerformanceTimelineEnum;
  segment: LeadSegmentEnum[];
  assignedTo?: string;
  customDateRange?: [string, string];
};

type PerformanceDashboardContextType = {
  performanceFilters: PerformanceDashboardFiltersType;
  setPerformanceFilters: React.Dispatch<React.SetStateAction<PerformanceDashboardFiltersType>>;
};

type DashboardContextType = LeadDashboardContextType & PerformanceDashboardContextType;

const DashboardContext = React.createContext<DashboardContextType>({
  leadFilters: {
    segment: [],
  },
  setLeadFilters: () => {},
  performanceFilters: {
    timeline: PerformanceTimelineEnum['TILL DATE'],
    segment: [],
    assignedTo: '',
  },
  setPerformanceFilters: () => {},
});

export const DashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const [leadFilters, setLeadFilters] = useState<LeadDashboardFiltersType>({
    segment: [],
  });

  const [performanceFilters, setPerformanceFilters] = useState<PerformanceDashboardFiltersType>({
    timeline: PerformanceTimelineEnum['TILL DATE'],
    assignedTo: user?._id as string,
    segment: [],
  });

  useEffect(() => {
    const assignUserIdToPerformanceFilters = () => {
      if (!!user?._id) {
        setPerformanceFilters(prev => ({ ...prev, assignedTo: user._id }));
      }
    };

    assignUserIdToPerformanceFilters();
  }, [user?._id]);

  return (
    <DashboardContext.Provider
      value={{
        leadFilters,
        setLeadFilters,
        performanceFilters,
        setPerformanceFilters,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (context === null) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
