import Resizer from 'react-image-file-resizer';

export const THRESHOLD_IMAGE_SIZE = 1 * 125000;

export const resizeImageFile = (file: File, addPreviewImage: boolean = false): Promise<File> => {
  const reducedFileSize = file.size > THRESHOLD_IMAGE_SIZE ? 55 : 80;

  const createPreview = (resizedFile: File): File => {
    const src = URL.createObjectURL(resizedFile);
    resizedFile['src'] = src;
    return resizedFile;
  };

  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      'JPEG',
      reducedFileSize,
      0,
      uri => {
        resolve(addPreviewImage ? createPreview(uri as File) : (uri as File));
      },
      'file'
    );
  });
};
