import { gql, useLazyQuery } from '@apollo/client';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  BranchesQueryResponse,
  BranchesQueryVariables,
  CompaniesQueryResponse,
  CompaniesQueryVariables,
  GET_BRANCHES_QUERY,
  GET_COMPANIES_QUERY,
} from 'graphql/query/company';
import { GET_PEOPLES_QUERY, PeopleQueryResponse, PeopleQueryVariables } from 'graphql/query/people';
import React, { useState } from 'react';
import {
  CompanyStatus,
  CompanyType,
  DepartmentEnum,
  LeadScopeEnum,
  LeadSegmentEnum,
  LeadUnitOfMeasureEnum,
  People,
  User,
  UserStatusEnum,
} from 'types';

import Fieldset from 'components/Fieldset';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { MultiSelect, Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import PeopleForm from 'components/Peoples/PeopleForm';
import { SimplePopup } from 'components/Popup';

import { rankedFieldsChangeHandler } from 'utils/formHandlers';
import { numberRegex } from 'utils/regexes';
import { transformCustomerNames } from 'utils/transformFn';

const GET_SALES_PERSONS = gql`
  query GetUsers($filter: UserFilter) {
    getUsers(filter: $filter) {
      _id
      empId
      firstName
      lastName
    }
  }
`;

type SalesPersonsQueryResponse = {
  getUsers: User[];
};

const CreateLeadAndCompanyForm: React.FC<{
  formState: Record<string, any>;
  setFormState: (arg: any) => void;
  handleNext: () => void;
}> = ({ formState, handleNext, setFormState }) => {
  const [enableAddNewPeopleForm, toggleAddNewPeople] = useState(false);

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const fieldRanks = ['company', 'branch', 'people'];

  const [getSalesPersons, { loading: loadingSalesPersons, data: salesPersons }] =
    useLazyQuery<SalesPersonsQueryResponse>(GET_SALES_PERSONS);

  const [getCompanies, { loading: loadingCompanies, data: companies }] = useLazyQuery<
    CompaniesQueryResponse,
    CompaniesQueryVariables
  >(GET_COMPANIES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getBranches, { loading: loadingBranches, data: branches }] = useLazyQuery<
    BranchesQueryResponse,
    BranchesQueryVariables
  >(GET_BRANCHES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [getPeople, { loading: loadingPeople, data: people }] = useLazyQuery<
    PeopleQueryResponse,
    PeopleQueryVariables
  >(GET_PEOPLES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const transformPeopleToOptions = (people: People[]) => {
    return people.map(p => ({ label: `${p.name} (${p.mobile})`, value: p._id }));
  };

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleNext();
        }}
      >
        <Grid container columnSpacing={1.5} rowGap={2} px={2} pb={3}>
          <Grid item xs={12}>
            <Divider>
              <Typography variant="subtitle1" fontWeight={600}>
                Company Details
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={6}>
            <AutocompleteWithFetch
              value={formState.company ?? { _id: '', name: '', referenceId: '' }}
              handleChange={val =>
                rankedFieldsChangeHandler('company', val, setFormState, fieldRanks)
              }
              label="Company"
              fetch={getCompanies}
              loading={loadingCompanies}
              options={companies?.getCompanies ?? []}
              required
              variables={{
                filter: {
                  status: [CompanyStatus.ACTIVE],
                  type: [CompanyType.CLIENT],
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <AutocompleteWithFetch
              options={branches?.getBranches ?? []}
              value={formState.branch ?? { _id: '', name: '', referenceId: '' }}
              handleChange={val =>
                rankedFieldsChangeHandler('branch', val, setFormState, fieldRanks)
              }
              noOptionText={`No Client Available`}
              loading={loadingBranches}
              fetch={getBranches}
              label="Branch"
              disabled={!!!formState?.company?._id}
              required={!!formState?.company?._id}
              variables={{
                filter: {
                  company: formState.company?._id ?? '',
                  status: [CompanyStatus.ACTIVE],
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <MultiSelect
              label="People"
              value={formState.people ?? []}
              required
              handleChange={val =>
                rankedFieldsChangeHandler('people', val, setFormState, fieldRanks)
              }
              disabled={!!!formState.company?._id || !!!formState.branch?._id}
              options={!!people?.getPeople ? transformPeopleToOptions(people.getPeople) : []}
              fetchConfig={{
                fetchFn: getPeople,
                loading: loadingPeople,
                variables: {
                  filter: {
                    branch: formState.branch?._id,
                  },
                },
              }}
              addNewConfig={{
                enableAddNew: true,
                onClick: () => toggleAddNewPeople(true),
                addNewLabel: '+ Add New Person',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider>
              <Typography variant="subtitle1" fontWeight={600}>
                Lead Details
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formState.name ?? ''}
              onChange={e => handleChange('name', e.target.value)}
              label="Name"
              placeholder="Lead Name"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={Object.keys(LeadSegmentEnum).map(o => ({
                label: o,
                value: LeadSegmentEnum[o],
              }))}
              label="Segment"
              placeholder="Lead Segment"
              value={formState.segment ?? ''}
              onChange={val => handleChange('segment', val)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={Object.keys(LeadScopeEnum).map(o => ({
                label: o,
                value: LeadScopeEnum[o],
              }))}
              label="Scope"
              placeholder="Lead Scope"
              value={formState.scope ?? ''}
              onChange={val => handleChange('scope', val)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Fieldset label="is Design & Build *" variant="small">
              <FormControl required>
                <RadioGroup
                  row
                  aria-required
                  name="is Design & Build"
                  value={formState.isBuildAndDesign ?? null}
                  onChange={e => handleChange('isBuildAndDesign', e.target.value === 'true')}
                >
                  <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                </RadioGroup>
              </FormControl>
            </Fieldset>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={
                formState.areaOrPower ? new Intl.NumberFormat().format(formState.areaOrPower) : ''
              }
              onChange={e => {
                const input = e.target.value.replace(/,/g, '');
                if (input === '') {
                  handleChange('areaOrPower', '');
                } else {
                  const isNumber = numberRegex.test(input);
                  if (isNumber) {
                    handleChange('areaOrPower', input);
                  }
                }
              }}
              label="Area / Power / Count"
              required
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={Object.keys(LeadUnitOfMeasureEnum).map(o => ({
                label: o,
                value: LeadUnitOfMeasureEnum[o],
              }))}
              label="Unit of Measure"
              placeholder="UOM"
              value={formState.areaOrPowerUOM ?? ''}
              onChange={val => handleChange('areaOrPowerUOM', val)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={formState.expectedPODate ?? null}
                  format="DD/MM/YYYY"
                  onChange={val => handleChange('expectedPODate', val)}
                  label="Expected PO Date"
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true,
                      required: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formState.approxValue ?? ''}
              onChange={e => handleChange('approxValue', e.target.value)}
              label="Approx Value (Cr) "
              required
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteWithFetch
              value={formState.salesPerson ?? ''}
              required
              handleChange={val => handleChange('salesPerson', val)}
              label="Sales Person"
              fetch={getSalesPersons}
              loading={loadingSalesPersons}
              options={transformCustomerNames(salesPersons?.getUsers ?? [])}
              variables={{
                filter: {
                  status: [UserStatusEnum.ACTIVE],
                  department: [DepartmentEnum.SALES],
                },
              }}
            />
          </Grid>

          <Grid item xs={12} container justifyContent="end">
            <Button
              type="submit"
              fullWidth={isMobileScreen}
              size={isMobileScreen ? 'medium' : 'large'}
              variant="outlined"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
      <SimplePopup
        onClose={() => toggleAddNewPeople(false)}
        open={enableAddNewPeopleForm}
        title="Add People"
        fullWidth
      >
        <PeopleForm
          cb={people => {
            toggleAddNewPeople(false);
            setFormState(prev => ({
              ...prev,
              people: [...(prev.people ?? []), people._id],
            }));
          }}
          branchId={formState.branch?._id}
          companyId={formState.company?._id}
        />
      </SimplePopup>
    </>
  );
};

export default CreateLeadAndCompanyForm;
