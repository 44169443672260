import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import Filters from 'components/Filters';
import LeadsTemplate from 'components/Leads';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { transformFilters } from 'utils/transformFn';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      scope
      segment
      areaOrPower
      areaOrPowerUOM
      approxValue
      probability
      actualPODate
      expectedPODate
      pqSubmittedDate
      pqReceivedDate
      rfpReceivedDate
      rfpSubmittedDate
      leadStatus
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[] | null;
};

type GetLeadsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    expectedPODateRange?: string[];
    sortOption?: LeadSortOptionEnum;
    searchTerm?: string | null;
  };
};

const LeadsPage = () => {
  const [filters, setFilters] = useState<GetLeadsQueryVariables['filter']>({
    leadStatus: [],
    monthType: undefined,
    segment: [],
    searchTerm: null,
    expectedPODateRange: [],
  });

  const {
    data: leads,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetLeadsQueryResponse, GetLeadsQueryVariables>(GET_LEADS_QUERY, {
    variables: { filter: filters },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  const applyFilters = (newFilters: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));

    refetchLeads({
      filter: {
        ...filters,
        ...newFilters,
      },
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <Navbar
      title="Leads"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search Leads',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate('create-lead')}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="leads"
            refetch={data => {
              applyFilters(transformFilters(data, ['city', 'company']));
            }}
          />
        </Grid>
        <Grid item xs={6} md={1.5} xl={1}>
          <Sort
            sort={filters.sortOption}
            setSort={newSortVal =>
              applyFilters({
                ...filters,
                sortOption: newSortVal,
              })
            }
          />
        </Grid>
      </Grid>

      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <LeadsTemplate leads={leads?.getLeads ?? []} />
      )}
    </Navbar>
  );
};

export default LeadsPage;
