import { Grid, Typography } from '@mui/material';

import theme from './theme.module.scss';

type SelectionProps = {
  handleChange: (arg: any) => void;
  deSelectable?: boolean;
  label: string;
  activeId: any;
  id: any;
};

const Selection: React.FC<SelectionProps> = ({
  handleChange,
  label,
  activeId,
  id,
  deSelectable = true,
}) => {
  const handleClick = () => {
    if (deSelectable) activeId === id ? handleChange([null]) : handleChange([id]);
    else handleChange([id]);
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={theme.container}
      onClick={() => handleClick()}
      sx={{
        backgroundColor: theme =>
          activeId === id ? theme.palette.primary.main : theme.palette.background.paper,
        color: theme => (activeId === id ? theme.palette.common.white : theme.palette.common.black),
      }}
    >
      <Typography variant="overline">{label}</Typography>
    </Grid>
  );
};

export default Selection;
