import { gql } from '@apollo/client';
import { CompanyType, LeadPeople } from 'types';

export const CREATE_LEAD_PEOPLE_MUTATION = gql`
  mutation CreateLeadPeople($input: CreateLeadPeopleInput!) {
    createLeadPeople(input: $input) {
      _id
      isDecisionMaker
      people {
        _id
        referenceId
        name
        mobile
        designation
        email
        status
      }
      lead {
        name
      }
    }
  }
`;

export type CreateLeadPeopleResponse = {
  createLeadPeople: LeadPeople[];
};
export type CreateLeadPeopleVariables = {
  input: {
    lead: string;
    type: CompanyType;
    people: string[];
  };
};
