import classNames from 'classnames';

import theme from './theme.module.scss';

const Fieldset: React.FC<{
  children: React.ReactNode;
  label: string;
  variant?: 'standard' | 'small';
  error?: boolean;
}> = ({ children, label, variant = 'standard', error = false }) => {
  return (
    <fieldset
      className={classNames(variant === 'standard' ? theme.container : theme.smallContainer)}
      style={{
        borderColor: error ? 'crimson' : undefined,
      }}
    >
      <legend
        style={{
          color: error ? 'crimson' : undefined,
        }}
        className={theme.legendName}
      >
        {label}
      </legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;
