import CircularProgress from '@mui/material/CircularProgress';

import theme from './theme.module.scss';

const LoadingIndicator: React.FC<{ size?: string }> = ({ size = '1.6rem' }) => {
  return (
    <div className={theme.loadingContainer}>
      <CircularProgress size={size} color="secondary" />
    </div>
  );
};
export default LoadingIndicator;
