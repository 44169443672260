import { gql } from '@apollo/client';
import { AddressType, Branch, BranchStatusEnum, BranchTypeEnum } from 'types';

export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      _id
      referenceId
      addressLine1
      addressLine2
      city
      country
      pincode
      state
      addressLocation
    }
  }
`;

export type CreateAddressMutationResponse = {
  createAddress: AddressType;
};
export type CreateAddressMutationVariables = {
  input: {
    addressLine1: string;
    addressLine2?: string;
    addressLocation: string;
    city: string;
    country: string;
    pincode: string;
    state: string;
  };
};

export const CREATE_BRANCH_MUTATION = gql`
  mutation CreateBranch($input: CreateBranchInput!) {
    createBranch(input: $input) {
      _id
      address {
        _id
      }
      company {
        _id
      }
      email
      landline1
      landline2
      landline3
      mobile1
      mobile2
      name
      type
      referenceId
      status
      people {
        _id
        name
        email
        status
        mobile
        referenceId
      }
    }
  }
`;

export type CreateBranchMutationResponse = {
  createBranch: Branch;
};

export type CreateBranchMutationVariables = {
  input: {
    address: string;
    company: string;
    email: string;
    landline1?: string;
    landline2?: string;
    landline3?: string;
    mobile1: string;
    mobile2?: string;
    name: string;
    status: BranchStatusEnum;
    type: BranchTypeEnum;
  };
};
