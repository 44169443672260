import React from 'react';
import { IconProps } from 'types';

const DesignProjectstatusicon: React.FC<IconProps> = ({
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      fill={color}
      fillRule="nonzero"
      {...attributes}
      viewBox="0 0 80 79"
    >
      <g>
        <path
          d="M38.754 0.021L38.754 1.707 41.777 1.707 24.431 19.053 16.009 10.622 5.306 21.325 6.488 22.518 16.009 13.007 24.431 21.428 42.969 2.9 42.969 5.912 44.656 5.912 44.656 0.021z"
          transform="translate(-538 -153) translate(483 110) translate(55.825 43.093) translate(13.058 13.058)"
        />
        <path
          d="M50.547 48.882V11.814h-8.42v37.068H40.44V16.873h-8.421v32.019h-1.686V28.667H21.9v20.215h-1.686V23.608h-8.421v25.274h-1.687V37.088h-8.42v11.794H0v1.686h52.234v-1.686h-1.687zm-42.116 0H3.373V38.774H8.43v10.108zm10.108 0H13.48V25.294h5.059v23.588zm10.107.01h-5.059V30.353h5.06v18.539zm10.108 0h-5.06V18.559h5.06v30.333zm10.117-.01h-5.058V13.5h5.058v35.38z"
          transform="translate(-538 -153) translate(483 110) translate(55.825 43.093) translate(13.058 13.058)"
        />
      </g>
    </svg>
  );
};
export default DesignProjectstatusicon;
