import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import {
  CREATE_FOLDER_MUTATION,
  CreateFolderMutationResponse,
  CreateFolderMutationVariables,
} from 'graphql/mutation/folder';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateFolderCachedList } from 'routes/DocumentsMaster/DocumentFolders';
import extendedTheme from 'styles/theme';
import { FolderTypeEnum, LeadStatusEnum } from 'types';

import FolderCard from 'components/DocumentMaster/Card/FolderCard';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import Section from 'components/Section';

export enum FolderNameEnum {
  PQ = 'PQ',
  RFP = 'RFP',
}

const PQFolderSection: React.FC<{ leadId: string; leadStatus: LeadStatusEnum }> = ({
  leadId,
  leadStatus,
}) => {
  const [enableCreateFolderPopup, toggleCreateFolderPopup] = useState(false);
  const [formState, setFormState] = useState<Record<string, any>>({});

  const { data: folders, networkStatus } = useQuery<
    GetFoldersQueryResponse,
    GetFoldersQueryVariables
  >(GET_FOLDERS_QUERY_FOR_LV, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: FolderTypeEnum.LEAD,
      folderName: FolderNameEnum.PQ,
      projectOrLeadId: leadId,
    },
  });

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateFolderMutationResponse,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION);

  const navigate = useNavigate();

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  const isPQReceived = leadStatus === LeadStatusEnum['PQ RECEIVED'];

  const handleCreateFolder = () => {
    createFolder({
      variables: {
        input: {
          name: formState.name,
          type: FolderTypeEnum.LEAD,
          parentFolder: folders?.getFolders?.folders[0].parentFolder?._id,
          lead: leadId,
          isStandardFolder: false,
        },
      },
      onCompleted: res => {
        updateFolderCachedList(res.createFolder, {
          type: FolderTypeEnum.LEAD,
          folderName: FolderNameEnum.PQ,
          projectOrLeadId: leadId,
        });
        toggleCreateFolderPopup(false);
        navigate(
          `/documents/${FolderTypeEnum.LEAD}/${leadId}/${res.createFolder.parentFolder?._id}/${res.createFolder._id}`
        );
      },
    });
  };

  return (
    <Section
      title="PQ Folders"
      collapsible
      action={
        <CreateNewFolderIcon
          sx={
            isPQReceived
              ? { cursor: 'pointer' }
              : { cursor: 'not-allowed', color: extendedTheme.palette.grey[400] }
          }
          onClick={() => (isPQReceived ? toggleCreateFolderPopup(true) : undefined)}
        />
      }
    >
      <Box px={1} py={1} width="100%">
        {loadingFolders ? (
          <LoadingIndicator size="1.2rem" />
        ) : (
          <Grid container columns={12} rowSpacing={3} columnSpacing={5} alignItems="center">
            {folders.getFolders.folders.map(folder => (
              <Grid item xs={12} sm={6} lg={4} key={folder.referenceId}>
                <FolderCard
                  folder={folder}
                  hideCount
                  onClick={() =>
                    navigate(
                      `/documents/${FolderTypeEnum.LEAD}/${leadId}/${folder.parentFolder?._id}/${folder._id}`
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <SimplePopup
        title="Add New Folder"
        onClose={() => toggleCreateFolderPopup(false)}
        open={enableCreateFolderPopup}
        fullWidth
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleCreateFolder();
          }}
        >
          <Grid container direction="column" gap={2} px={2} py={1}>
            <TextField
              label="Folder Name"
              value={formState.name ?? ''}
              onChange={e => setFormState(prev => ({ ...prev, name: e.target.value }))}
              required
            />
            <LoadingButton variant="contained" type="submit" loading={creatingFolder}>
              Create
            </LoadingButton>
          </Grid>
        </form>
      </SimplePopup>
    </Section>
  );
};

export default PQFolderSection;
