import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { Lead, LeadStatusEnum } from 'types';

import { FormInput, FormPanel } from 'components/FormPanel';

import { formatDate, getEnumKeyFromValue } from 'utils/transformFn';

const UPDATE_PQ_MUTATION = gql`
  mutation UpdatePQ($input: UpdatePQInput!) {
    updatePQ(input: $input) {
      _id
      name
      pqReceivedDate
      pqSubmittedDate
      leadStatus
    }
  }
`;

type UpdatePQMutationResponse = {
  updatePQ: Lead;
};

type UpdatePQMutationVariables = {
  input: {
    lead: string;
    pqReceivedDate: string;
  };
};

const UpdatePQForm: React.FC<{ lead: Lead; cb: (updatedLead: Lead) => void }> = ({ cb, lead }) => {
  const [updatePQ, { loading: updatingPQ }] = useMutation<
    UpdatePQMutationResponse,
    UpdatePQMutationVariables
  >(UPDATE_PQ_MUTATION);

  const handleSubmit = (data: Record<string, any>) => {
    const pqReceivedDate = formatDate(data.pqReceivedDate) as string;

    updatePQ({
      variables: {
        input: {
          lead: lead._id,
          pqReceivedDate,
        },
      },
      onCompleted: updatedLead => {
        cb(updatedLead.updatePQ);
      },
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container direction="column" rowGap={2} px={2} py={2}>
        <FormPanel
          error={null}
          loading={updatingPQ}
          onSubmit={handleSubmit}
          submitButtonLabel="Update PQ"
        >
          <FormInput
            fieldName="leadStatus"
            label="Lead Status"
            type="string"
            disabled
            defaultValue={getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus)}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fieldName="pqReceivedDate"
            label="PQ Submission Date"
            type="date"
            defaultValue={!!lead.pqReceivedDate ? dayjs(lead.pqReceivedDate) : null}
            validators={{
              required: true,
            }}
          />
        </FormPanel>
      </Grid>
    </LocalizationProvider>
  );
};

export default UpdatePQForm;
