import React from 'react';
import { IconProps } from 'types';

const OverallProjectupdateicon: React.FC<IconProps> = ({
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="79"
      fill={color}
      fillRule="nonzero"
      {...attributes}
      viewBox="0 0 80 79"
    >
      <g>
        <path
          d="M39.463 32.794v-1.636c0-1.742-1.243-3.199-2.889-3.53V3.601c0-1.986-1.615-3.601-3.6-3.601H6.49c-1.986 0-3.6 1.615-3.6 3.6v18.267C1.242 22.198 0 23.655 0 25.397v10.218h1.44V25.397c0-1.192.97-2.16 2.16-2.16h14.052c.816 0 1.525.553 1.724 1.344l.494 1.98c.359 1.435 1.642 2.436 3.12 2.436h12.873c1.19 0 2.16.97 2.16 2.16v1.74c-4.69.688-8.302 4.738-8.302 9.616 0 3.497 1.856 6.568 4.634 8.28H3.6c-1.192 0-2.16-.969-2.16-2.16V32.83H0v15.803c0 1.985 1.615 3.6 3.6 3.6h32.263c.467 0 .917-.088 1.341-.26.719.17 1.467.26 2.237.26 5.36 0 9.72-4.36 9.72-9.72 0-5.352-4.348-9.707-9.698-9.72zm-4.33-5.237H22.99c-.816 0-1.525-.553-1.722-1.345l-.495-1.98c-.36-1.434-1.643-2.436-3.121-2.436H4.329V3.601c0-1.192.97-2.16 2.16-2.16h26.484c1.192 0 2.16.968 2.16 2.16v23.956zm4.308 23.236c-4.566 0-8.28-3.714-8.28-8.28 0-4.565 3.714-8.28 8.28-8.28 4.565 0 8.28 3.715 8.28 8.28 0 4.566-3.715 8.28-8.28 8.28z"
          transform="translate(-328 -783) translate(273 740) translate(55.825 43.093) translate(13.058 13.058)"
        />
      </g>
      <g>
        <path
          d="M1.025 7.02c.003-2.46 1.934-4.485 4.39-4.604l-.656.657.725.724L7.02 2.26c.2-.2.2-.525 0-.725L5.484 0l-.725.724.667.667C2.916 1.483.772 3.226.166 5.663c-.605 2.437.476 4.98 2.65 6.236l.513-.887c-1.423-.826-2.301-2.347-2.304-3.993zM11.267 7.02c-.004-2.012-1.077-3.87-2.817-4.88l-.512.887c1.771 1.023 2.657 3.09 2.174 5.079-.482 1.988-2.217 3.42-4.26 3.517l.656-.657-.725-.724-1.536 1.536c-.2.2-.2.524 0 .724l1.536 1.537.725-.725-.667-.666c3.027-.115 5.422-2.6 5.426-5.629z"
          transform="translate(-328 -783) translate(273 740) translate(55.825 43.093) translate(13.058 13.058) translate(33.798 35.642)"
        />
        <path
          d="M6.562 7.839c.096-.096.15-.227.15-.362v-2.56H5.688v2.347L4.302 8.651l.724.724 1.536-1.536z"
          transform="translate(-328 -783) translate(273 740) translate(55.825 43.093) translate(13.058 13.058) translate(33.798 35.642)"
        />
      </g>
      <path
        d="M10.037 16.387c-.452 0-.82.367-.82.82v4.915c0 .453.368.82.82.82h4.916c.453 0 .82-.367.82-.82v-4.916c0-.452-.367-.819-.82-.819h-4.916zm4.097 4.916h-3.278v-3.277h3.278v3.277zM17.411 22.122c0 .453.367.82.82.82.829 0 1.823 1.707 2.98 5.121.23.678.876.814 1.936.41.452 0 .819-.367.819-.82v-12.29c0-.453-.367-.82-.82-.82h-4.915c-.453 0-.82.367-.82.82v6.76zm1.639-6.087h3.277v11.522h-1.125c-.23-2.15-1.293-5.039-2.152-5.164v-6.358zM26.424 28.7h4.916c.453 0 .82-.367.82-.82V10.037c0-.452-.367-.82-.82-.82h-4.916c-.452 0-.82.368-.82.82V27.88c0 .302.274.575.82.82zm.82-17.844h3.277v16.701h-3.278v-16.7zM27.5 3.073L27.5 3.994 29.152 3.994 19.671 11.758 15.068 8.864 9.218 14.711 9.864 15.363 15.068 10.167 19.671 13.056 29.804 4.645 29.804 6.291 30.726 6.291 30.726 3.073z"
        transform="translate(-328 -783) translate(273 740) translate(55.825 43.093) translate(13.058 13.058)"
      />
    </svg>
  );
};
export default OverallProjectupdateicon;
