import Cookies from 'js-cookie';

export const getToken = () => (Cookies.get('token') ? Cookies.get('token') : '');

export const logout = () => {
  Cookies.remove('token');
  Cookies.remove('userId');
  refresh();
};

export const storeLoginCredentials = (tokenId: string) => {
  Cookies.set('token', tokenId, { expires: 1 });
};

const refresh = () => window.location.reload();
