import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import FolderIcon from '@mui/icons-material/Folder';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FolderTypeEnum, Lead } from 'types';

import { MultiSelectAutocomplete } from 'components/FormPanel/AutoCompleteWithFetch';
import TextField from 'components/Inputs/TextField';
import {
  LEAD_PEOPLE_QUERY,
  LeadPeopleQueryResponse,
  LeadPeopleQueryVariables,
} from 'components/Leads/Associates/LeadPeopleSection';
import { FolderNameEnum } from 'components/PQs/Sections/FolderSection';

const SHARE_PQ_MUTATION = gql`
  mutation SharePQ($input: SharePQInput) {
    sharePQ(input: $input)
  }
`;

enum UserTypeEnum {
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL_USER = 'EXTERNAL_USER',
}

type SharePQMutationVariables = {
  input: {
    lead: string;
    peopleUsers: { _id: string; type: UserTypeEnum }[];
    subject: string;
    body: string;
  };
};

const SharePQForm: React.FC<{ lead: Lead; cb: () => void }> = ({ lead, cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [getLeadPeople, { loading: loadingLeadPeople, data: leadPeople }] = useLazyQuery<
    LeadPeopleQueryResponse,
    LeadPeopleQueryVariables
  >(LEAD_PEOPLE_QUERY, {
    variables: {
      filter: {
        lead: lead._id,
      },
    },
  });

  const { data: folders } = useQuery<GetFoldersQueryResponse, GetFoldersQueryVariables>(
    GET_FOLDERS_QUERY_FOR_LV,
    {
      variables: {
        type: FolderTypeEnum.LEAD,
        folderName: FolderNameEnum.PQ,
        projectOrLeadId: lead._id,
      },
    }
  );

  const [sharePQ, { loading: sharingPQ }] = useMutation<{}, SharePQMutationVariables>(
    SHARE_PQ_MUTATION
  );

  const transformLeadPeopleToAutocompleteOptions = () => {
    return (
      leadPeople?.getLeadPeople?.map(o => {
        return {
          _id: o.people._id,
          name: `${o.people.name} (${o.type})`,
          type: UserTypeEnum.EXTERNAL_USER,
        };
      }) ?? []
    );
  };

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const body = formState.body + insertFolderLink(lead);

    sharePQ({
      variables: {
        input: {
          lead: lead._id,
          subject: formState.subject,
          body,
          peopleUsers: formState.people.map(p => ({ _id: p._id, type: p.type })),
        },
      },
      onCompleted: _ => {
        toast.success('PQ Folder Successfully Shared');
        cb();
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" rowGap={2}>
        <Grid item>
          <MultiSelectAutocomplete
            fetch={getLeadPeople}
            handleChange={val => handleChange('people', val)}
            label="People"
            loading={loadingLeadPeople}
            required={!!!formState.people?.length}
            values={formState.people ?? []}
            variant="standard"
            options={transformLeadPeopleToAutocompleteOptions()}
            variables={{
              filter: {
                lead: lead._id,
              },
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            value={formState.subject ?? ''}
            onChange={e => handleChange('subject', e.target.value)}
            label="Subject"
            required
            variant="standard"
            placeholder="Enter mail subject here"
          />
        </Grid>
        <Grid item>
          <TextField
            value={formState.body ?? ''}
            onChange={e => handleChange('body', e.target.value)}
            label="Body"
            required
            placeholder="Mail body"
            multiline
            variant="standard"
            minRows={10}
          />
        </Grid>
        <Grid item container justifyContent="end">
          <LoadingButton loading={sharingPQ} type="submit" variant="contained">
            Share
          </LoadingButton>
        </Grid>
        {!!folders?.getFolders?.folders && (
          <Grid item container direction="column" rowGap={0.5} mt={-1}>
            <Typography letterSpacing={0.5} variant="overline" fontWeight={600}>
              List of folders that are going to get shared
            </Typography>
            {folders.getFolders.folders.map(f => (
              <Grid item container key={f._id} columnGap={1} alignItems="center">
                <FolderIcon fontSize="small" color="secondary" />
                <Typography variant="body1" letterSpacing={1}>
                  {f.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default SharePQForm;

const insertFolderLink = lead => {
  const folderIconUrl = 'https://img.icons8.com/fluency/48/folder-invoices--v2.png';

  return `
         <div style="display: flex; align-items: center; column-gap: 0.2rem; height: 20px;">
          <img src="${folderIconUrl}" alt="Folder Icon" height="100%" width="auto" />
          <a style="letter-spacing: 1px;font-size: 12px;" href="${window.location.origin}/documents/LEAD/${lead._id}">Please find the attached folder
          </a>
        </div>
  `;
};
