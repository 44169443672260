import { gql, useMutation } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import {
  CREATE_BRANCH_MUTATION,
  CreateBranchMutationResponse,
  CreateBranchMutationVariables,
} from 'graphql/mutation/branch';
import {
  CREATE_PEOPLE_MUTATION,
  CreatePeopleMutationResponse,
  CreatePeopleMutationVariables,
} from 'graphql/mutation/people';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddressType, BranchTypeEnum, Company, CompanyStatus, CompanyType } from 'types';

import CreateCompanyForm from 'components/Companies/Forms/CreateCompany';
import Navbar from 'components/Navbar';
import AddressForm from 'components/Section/AddressSection/AddressForm';

import { removeEmptyFields } from 'utils/common';

import { PRIMARY_BRANCH_NAME } from '.';

const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      _id
      referenceId
    }
  }
`;

type CreateCompanyMutationResponse = {
  createCompany: Company;
};
type CreateCompanyMutationVariables = {
  input: {
    name: string;
    type: CompanyType;
    status?: CompanyStatus;
  };
};

const CreateCompanyPage = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [currView, setCurrView] = useState<'company' | 'address'>('company');

  const [createCompany, { loading: creatingCompany }] = useMutation<
    CreateCompanyMutationResponse,
    CreateCompanyMutationVariables
  >(CREATE_COMPANY_MUTATION);

  const [createBranch, { loading: creatingBranch }] = useMutation<
    CreateBranchMutationResponse,
    CreateBranchMutationVariables
  >(CREATE_BRANCH_MUTATION);

  const [createPeople, { loading: creatingPeople }] = useMutation<
    CreatePeopleMutationResponse,
    CreatePeopleMutationVariables
  >(CREATE_PEOPLE_MUTATION);

  const navigate = useNavigate();

  const handleSubmit = async (newAdd: AddressType) => {
    const formData = { ...formState };
    const addressData = removeEmptyFields(formData.address);
    const branchData = removeEmptyFields(formData.branch);
    const peopleData = removeEmptyFields(formData.contact);

    delete formData.address;
    delete formData.branch;

    const companyData = removeEmptyFields(formData);

    let companyId;

    if ((!!addressData && !!branchData) || !!companyData) {
      const promises: any[] = [];
      promises.push(
        createCompany({
          variables: {
            input: {
              name: companyData.name,
              type: companyData.type,
            },
          },
          onCompleted: res => (companyId = res.createCompany._id),
        })
      );

      await Promise.all(promises);

      if (!!companyId) {
        createBranch({
          variables: {
            input: {
              ...branchData,
              address: newAdd._id,
              company: companyId,
              name: PRIMARY_BRANCH_NAME,
              type: BranchTypeEnum.HO,
            },
          },

          onCompleted: newBranch => {
            createPeople({
              variables: {
                input: {
                  branch: newBranch.createBranch._id,
                  company: companyId,
                  name: peopleData.name,
                  email: peopleData.email,
                  designation: peopleData.designation,
                  mobile: peopleData.mobile,
                },
              },
              onCompleted: _ => navigate(`/companies/${companyId}`, { replace: true }),
            });
          },
        });
      }
    }
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Company',
        onClick: currView === 'address' ? () => setCurrView('company') : undefined,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={1}>
        <Box display={currView !== 'company' ? 'none' : 'block'}>
          <CreateCompanyForm
            formState={formState}
            setFormState={setFormState}
            handleNext={() => setCurrView('address')}
          />
        </Box>

        <Box display={currView !== 'address' ? 'none' : 'block'}>
          <AddressForm
            cb={handleSubmit}
            loading={creatingCompany || creatingBranch || creatingPeople}
          />
        </Box>
      </Grid>
    </Navbar>
  );
};

export default CreateCompanyPage;
