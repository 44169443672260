import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Lead, LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import { fixToTwoLocalPrice, getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

import UpdateLeadForm from './UpdateLeadForm';

const LeadSection: React.FC<{ lead: Lead }> = ({ lead }) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);

  const leadDetails: SectionDataProps[] = [
    { label: 'ID', value: lead.referenceId, type: 'STRING' },
    { label: 'Name', value: lead.name, type: 'STRING' },
    {
      label: 'Company',
      value: lead.company.name,
      type: 'LINK',
      navigateTo: `/companies/${lead.company._id}`,
    },
    { label: 'Segment', value: getEnumKeyFromValue(LeadSegmentEnum, lead.segment), type: 'STRING' },
    { label: 'Scope', value: getEnumKeyFromValue(LeadScopeEnum, lead.scope), type: 'STRING' },
    {
      label: `Area (${lead.areaOrPowerUOM})`,
      value: lead.areaOrPower ? fixToTwoLocalPrice(lead.areaOrPower) : undefined,
      type: 'NUMBER',
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus),
      type: 'STRING',
    },
    {
      label: 'Probability',
      value: lead.probability ? `${lead.probability}%` : undefined,
      type: 'STRING',
    },
    {
      label: 'Approx Value',
      value: lead.approxValue ? `₹ ${lead.approxValue} Cr` : undefined,
      type: 'STRING',
    },
    { label: 'Design & Build', value: lead.isBuildAndDesign ? 'Yes' : 'No', type: 'BOOLEAN' },
  ];

  if (lead.leadStatus === LeadStatusEnum.LOST || lead.leadStatus === LeadStatusEnum['ON HOLD']) {
    if (lead.lostReason) {
      leadDetails.push({
        label: 'Lost Reason',
        value: lead.lostReason,
        type: 'STRING',
      });
    }
  }

  if (lead.leadStatus === LeadStatusEnum.REGRET && !!lead.regretReason) {
    leadDetails.push({
      label: 'Regret Reason',
      value: lead.regretReason,
      type: 'STRING',
    });
  }

  if (
    lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] ||
    lead.leadStatus === LeadStatusEnum['ON HOLD']
  ) {
    if (lead.pqReceivedDate) {
      leadDetails.push({
        label: 'PQ Submission Date',
        value: getDate(lead.pqReceivedDate),
        type: 'DATE',
      });
    }
  }
  if (
    lead.leadStatus === LeadStatusEnum['PQ SUBMITTED'] ||
    lead.leadStatus === LeadStatusEnum['ON_HOLD']
  ) {
    if (lead.pqSubmittedDate) {
      leadDetails.push(
        {
          label: 'PQ Submitted Date',
          value: getDate(lead.pqSubmittedDate),
          type: 'DATE',
        },
        {
          label: 'Submit Type',
          value: lead.pqSubmitType,
        }
      );
    }
  }

  if (
    lead.leadStatus === LeadStatusEnum['RFP RECEIVED'] ||
    lead.leadStatus === LeadStatusEnum['ON_HOLD']
  ) {
    if (lead.rfpReceivedDate) {
      leadDetails.push({
        label: 'RFP Submission Date',
        value: getDate(lead.rfpReceivedDate),
        type: 'DATE',
      });
    }
  }
  if (
    lead.leadStatus === LeadStatusEnum['RFP SUBMITTED'] ||
    lead.leadStatus === LeadStatusEnum['ON_HOLD']
  ) {
    if (lead.rfpSubmittedDate) {
      leadDetails.push({
        label: 'RFP Submitted Date',
        value: getDate(lead.rfpSubmittedDate),
        type: 'DATE',
      });
    }
  }

  if (
    (lead.leadStatus === LeadStatusEnum.CREATED ||
      lead.leadStatus === LeadStatusEnum.WIP ||
      lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] ||
      lead.leadStatus === LeadStatusEnum['PQ SUBMITTED'] ||
      lead.leadStatus === LeadStatusEnum['RFP RECEIVED'] ||
      lead.leadStatus === LeadStatusEnum['RFP SUBMITTED'] ||
      lead.leadStatus === LeadStatusEnum['ON_HOLD']) &&
    lead.expectedPODate
  ) {
    leadDetails.push({
      label: 'Expected PO Date',
      value: getDate(lead.expectedPODate),
      type: 'DATE',
    });
  }

  if (lead.leadStatus === LeadStatusEnum.WON || lead.leadStatus === LeadStatusEnum['ON_HOLD']) {
    if (lead.poAmount) {
      leadDetails.push({
        label: 'PO Amount',
        value: `₹ ${lead.poAmount} Cr`,
        type: 'STRING',
      });
    }
    if (lead.actualPODate) {
      leadDetails.push({
        label: 'PO Date',
        value: getDate(lead.actualPODate),
        type: 'DATE',
      });
    }
    if (lead.poIssuedBy) {
      leadDetails.push({
        label: 'PO Issued By',
        value: lead.poIssuedBy,
        type: 'STRING',
      });
    }
    if (lead.poNumber) {
      leadDetails.push({
        label: 'PO Number',
        value: lead.poNumber,
        type: 'STRING',
      });
    }
  }

  if (lead.salesPerson) {
    leadDetails.push({
      label: 'Sales Person',
      value: `${lead.salesPerson.firstName} ${lead.salesPerson.lastName}`,
      type: 'STRING',
    });
  }

  leadDetails.push({
    label: 'Created By',
    value: `${lead.createdBy.firstName} ${lead.createdBy.lastName}`,
    type: 'STRING',
  });

  const filteredLeadDetails = leadDetails.filter(
    detail => detail.value !== undefined && detail.value !== null && detail.value !== ''
  );

  return (
    <Grid container direction="column" rowGap={1.5}>
      <Section
        title="Lead Details"
        collapsible
        data={filteredLeadDetails}
        action={<EditNoteIcon onClick={() => toggleFormEdit(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleFormEdit(false)}
        open={enableFormEdit}
        title="Update Lead"
        fullWidth
      >
        <UpdateLeadForm cb={() => toggleFormEdit(false)} lead={lead} />
      </SimplePopup>
    </Grid>
  );
};

export default LeadSection;
