import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Lead, Project } from 'types';

import DocumentsMasterTemplate from 'components/DocumentMaster';
import Filters from 'components/Filters';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { transformFilters } from 'utils/transformFn';

const GET_DOCUMENT_MASTER = gql`
  query GetLeadsAndProjects($filter: LeadOrProjectFilter) {
    getLeadsAndProjects(filter: $filter) {
      ... on Lead {
        _id
        referenceId
        name
        leadStatus
        segment
        scope
        address {
          _id
          city
        }
        areaOrPower
        areaOrPowerUOM
        approxValue
        isLeadOrProject
      }
      ... on Project {
        _id
        referenceId
        name
        status
        isLeadOrProject
      }
    }
  }
`;

type GetDocumentMasterQueryResponse = {
  getLeadsAndProjects: ((Lead & Project) & { isLeadOrProject: string })[];
};

type GetDocumentMasterQueryVariables = {};

const DocumentMasterPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const {
    data: documentsMaster,
    refetch: refetchDocs,
    networkStatus: docsNetworkStatus,
  } = useQuery<GetDocumentMasterQueryResponse, GetDocumentMasterQueryVariables>(
    GET_DOCUMENT_MASTER,
    {
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'network-only',
    }
  );

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchDocs({
      filter: newFilters,
    });
  };

  const loadingDocumentsMaster =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!documentsMaster;

  return (
    <Navbar
      title="Documents Master"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search a Document',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Filters
          type="document-master"
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data),
            });
          }}
        />
      }
    >
      {loadingDocumentsMaster ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <DocumentsMasterTemplate documentsMaster={documentsMaster.getLeadsAndProjects} />
      )}
    </Navbar>
  );
};

export default DocumentMasterPage;
