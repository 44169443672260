import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import extendedTheme from 'styles/theme';
import { PerformanceDashboardType } from 'types';

import theme from './theme.module.scss';

const CreatedCountsTemplate: React.FC<{ dashboard: PerformanceDashboardType }> = ({
  dashboard,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={1.5}
        columnSpacing={2}
        width="100%"
        maxWidth="700px"
        mx="auto"
      >
        <Grid item xs={12} container spacing={1.5} alignItems="center">
          <Grid item xs={12}>
            <Divider variant="middle">
              <Typography variant="button" fontWeight={700}>
                <Chip label="Items Created" size="small" variant="outlined" color="secondary" />
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={4}>
            <CountItem count={dashboard.clients} type="Clients" />
          </Grid>
          <Grid item xs={4}>
            <CountItem count={dashboard.events} type="Events" />
          </Grid>
          <Grid item xs={4}>
            <CountItem count={dashboard.leads} type="Leads" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const CountItem: React.FC<{ type: 'Clients' | 'Events' | 'Leads'; count: number }> = ({
  count,
  type,
}) => {
  const renderIcon = () => {
    switch (type) {
      case 'Clients':
        return <BusinessIcon fontSize="small" color="inherit" />;
      case 'Events':
        return <GroupsIcon fontSize="small" color="inherit" />;
      case 'Leads':
        return <AccountTreeIcon fontSize="small" color="inherit" />;
      default:
        return null;
    }
  };

  const formattedCount = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
  }).format(count);

  return (
    <div className={theme.createdCountCard}>
      <Grid container columnGap={1} alignItems="center">
        {renderIcon()}
        <Typography className={theme.title}>{type}</Typography>
      </Grid>
      <Typography variant="h6" color={extendedTheme.palette.secondary.main}>
        {formattedCount}
      </Typography>
    </div>
  );
};

export default CreatedCountsTemplate;
