import { Box } from '@mui/material';
import { useUser } from 'contexts/userContext';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';

const ProtectedRoutes: FC = () => {
  const { isAuthenticated, retrievingUser } = useUser();

  return isAuthenticated ? (
    <Outlet />
  ) : retrievingUser ? (
    <Box minHeight="30vh" pt="5vh">
      <LoadingIndicator size="1.6rem" />
    </Box>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
