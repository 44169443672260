import { NetworkStatus, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { client } from 'graphql/client';
import {
  GET_STATIC_FILES_QUERY,
  GetStaticFilesQueryResponse,
  GetStaticFilesQueryVariables,
} from 'graphql/query/files';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentFile } from 'types';

import DocumentFileCard from 'components/DocumentMaster/Card/DocumentCard';
import DocumentFilesListTemplate from 'components/DocumentMaster/DocumentFilesList';
import StaticFileForm from 'components/DocumentMaster/Forms/StaticFileForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

type DocumentViewType = 'list' | 'grid';

const StaticFilesPage = () => {
  const [viewType, setViewType] = useState<DocumentViewType>('grid');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showCreateNewForm, toggleCreateNewForm] = useState(false);

  const { subFolderId = '', folderId = '' } = useParams<{
    subFolderId: string;
    folderId: string;
  }>();

  const {
    data: docs,
    networkStatus: docsNetworkStatus,
    refetch: reFetchDocs,
  } = useQuery<GetStaticFilesQueryResponse, GetStaticFilesQueryVariables>(GET_STATIC_FILES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      folder: folderId,
      subFolder: subFolderId,
    },
  });

  const documentActions = (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      onClick={() => toggleCreateNewForm(true)}
    >
      <AddIcon fontSize="small" />
    </Button>
  );

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    reFetchDocs(newFilters);
  };

  const loadingDocuments =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!docs;

  const handleViewChange = (view: DocumentViewType) => {
    setViewType(view);
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!docs?.getStaticFiles?.title ? docs?.getStaticFiles.title : '',
        hideSeparator: true,
      }}
      searchInputConfig={{
        enable: true,
        name: 'documents search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={documentActions}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          mt: -1.5,
        }}
      >
        <Box>
          <IconButton onClick={() => handleViewChange('grid')} color="secondary">
            {viewType === 'grid' ? (
              <GridViewIcon
                fontSize="small"
                sx={{
                  backgroundColor: orange[200],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <GridViewIcon
                fontSize="small"
                sx={{
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            )}
          </IconButton>
          <IconButton onClick={() => handleViewChange('list')} color="secondary">
            {viewType === 'list' ? (
              <ViewListOutlinedIcon
                fontSize="medium"
                sx={{
                  backgroundColor: orange[200],
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            ) : (
              <ViewListOutlinedIcon
                fontSize="medium"
                sx={{
                  borderRadius: '4px',
                  padding: '4px',
                }}
              />
            )}
          </IconButton>
        </Box>
      </Box>

      {loadingDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid minHeight="80vh" mt={0.5} mb={4}>
          {viewType === 'list' ? (
            <DocumentFilesListTemplate
              docsNetworkStatus={docsNetworkStatus}
              docs={docs.getStaticFiles}
              fetchDocs={reFetchDocs}
            />
          ) : (
            <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center">
              {docs?.getStaticFiles.files.map(file => (
                <DocumentFileCard file={file} key={file._id} />
              ))}

              {docs?.getStaticFiles.files.length === 0 && (
                <Grid container justifyContent={'center'} mt={2}>
                  <Typography variant="subtitle1">No reports to show</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}

      <SimplePopup
        title="Upload New File"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={showCreateNewForm}
      >
        <Grid py={1}>
          <StaticFileForm
            folderId={subFolderId}
            cb={res => {
              toggleCreateNewForm(false);
              updateFileCachedList(res, {
                folder: folderId,
                subFolder: subFolderId,
              });
            }}
          />
        </Grid>
      </SimplePopup>
    </Navbar>
  );
};

export const updateFileCachedList = (newFile: DocumentFile, variables?: Record<string, any>) => {
  try {
    const currFiles = client.readQuery({
      query: GET_STATIC_FILES_QUERY,
      variables,
    }) ?? {
      getStaticFiles: {
        title: '',
        files: [],
      },
    };

    client.writeQuery({
      query: GET_STATIC_FILES_QUERY,
      variables: variables,
      data: {
        getStaticFiles: {
          ...currFiles.getStaticFiles,
          files: [newFile, ...(currFiles.getStaticFiles.files ?? [])],
        },
      },
    });
  } catch (error) {
    console.error('Error updating cache:', error);
  }
};

export default StaticFilesPage;
