import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { PerformanceDashboardType } from 'types';

import KPICard from 'components/Dashboard/Cards/KpiCard';

const LeadStatusPerformanceTemplate: React.FC<{ dashboard: PerformanceDashboardType }> = ({
  dashboard,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={1.5}
        columnSpacing={2}
        width="100%"
        maxWidth="700px"
        mx="auto"
        mt={1}
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Divider variant="middle">
                <Typography variant="button" fontWeight={700}>
                  <Chip label="RFP" size="small" variant="outlined" color="secondary" />
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={6}>
              <KPICard
                amount={dashboard.rfpReceivedLeads.totalApproxValue}
                leadsCount={dashboard.rfpReceivedLeads.leadCount}
                label="RFP Received"
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={6}>
              <KPICard
                amount={dashboard.rfpSubmittedLeads.totalApproxValue}
                leadsCount={dashboard.rfpSubmittedLeads.leadCount}
                label="RFP Submitted"
                onClick={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Divider variant="middle">
                <Typography variant="button" fontWeight={700}>
                  <Chip label="PQ" size="small" variant="outlined" color="secondary" />
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={6}>
              <KPICard
                amount={dashboard.pqReceivedLeads.totalApproxValue}
                leadsCount={dashboard.pqReceivedLeads.leadCount}
                label="PQ Received"
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={6}>
              <KPICard
                amount={dashboard.pqSubmittedLeads.totalApproxValue}
                leadsCount={dashboard.pqSubmittedLeads.leadCount}
                label="PQ Submitted"
                onClick={() => {}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadStatusPerformanceTemplate;
